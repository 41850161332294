<h1 mat-dialog-title>TERMS OF USE / PRIVACY POLICY</h1>
<div mat-dialog-content id="consent" class="logindisclaimer">
  <h2 mat-dialog-title>TERMS OF USE</h2><a href="#"></a>
  <p [innerHTML]=confirmMessage></p><br>
  <ol>

    <li [innerHTML]=confirmMessage2></li><br>
    <li [innerHTML]=confirmMessage3></li><br>
    <li [innerHTML]=confirmMessage4></li><br>
    <li [innerHTML]=confirmMessage5></li><br>
    <li [innerHTML]=confirmMessage6></li><br>
    <li [innerHTML]=confirmMessage7></li><br>
    <li [innerHTML]=confirmMessage8></li><br>
    <li [innerHTML]=confirmMessage9></li><br>
    <li [innerHTML]=confirmMessage10></li><br>
    <li [innerHTML]=confirmMessage11></li><br>
    <li [innerHTML]=confirmMessage12></li><br>
    <li [innerHTML]=confirmMessage13></li><br>
    <li [innerHTML]=confirmMessage14></li><br>
    <li [innerHTML]=confirmMessage15></li><br>
    <li [innerHTML]=confirmMessage16></li><br>
    <li [innerHTML]=confirmMessage17></li><br>
    <li [innerHTML]=confirmMessage18></li><br>
    <li [innerHTML]=confirmMessage19></li><br>
    <li [innerHTML]=confirmMessage20></li><br>
    <li [innerHTML]=confirmMessage21></li><br>
    <li [innerHTML]=confirmMessage22></li><br>
    <li [innerHTML]=confirmMessage23></li><br>
    <li [innerHTML]=confirmMessage24></li><br>
    <li [innerHTML]=confirmMessage25></li><br>
    <li [innerHTML]=confirmMessage26></li><br>
  </ol>

</div>
<div mat-dialog-actions>
  <button mat-button style="color: #fff;background-color: var(--color-primary);" (click)="dialogRef.close(true)">Confirm</button>
  <button mat-button (click)="dialogRef.close(false)">Cancel</button>
</div>
