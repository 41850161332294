import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild
} from "@angular/core";
import { MatExpansionPanel } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { MatAccordion } from "@angular/material";
import { MatMenuTrigger } from '@angular/material';
import { WhatsNewService } from '../services/whats-new.service';
import { AuthorizationService } from '../shared/authorization.service';
import { SpinnerService } from '../spinner.service';
import { ComingSoonService } from '../services/coming-soon.service';
//import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
  providers: [ComingSoonService, AuthorizationService]
})
export class ComingSoonComponent implements OnInit {
  public visibility: boolean = false;
  color = "accent";
  mode = "determinate";
  value = 30;
  queryString: any;
  panelOpenState: boolean = false;
  _allExpandState = false;
  public errorMsg;
  //public baseUrl: string = environment.product_service_baseUrl;
  public set allExpandState(value: boolean) {
    this._allExpandState = value;
    this.togglePanels(value);
  }

  public get allExpandState(): boolean {
    return this._allExpandState;
  }

  @ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;
  @ViewChild("secondAccordion") secondAccordion: MatAccordion;

  public togglePanels(value: boolean) {
    this.viewPanels.forEach(p => (value ? p.open() : p.close()));
  }

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  onContextMenu(event: MouseEvent, item: string) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  onContextMenuAction(item: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = item;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  constructor(
    private _servicesService: ComingSoonService,
    private spinnerService: SpinnerService,
    private authorizationService: AuthorizationService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  //public baseUrl1: string =
    //this.baseUrl + "/download?" + this._servicesService.tokenparam;

  //public domainUrl: string = environment.product_service_baseUrl.split(
  //  "product-service"
  //)[0];

  //public baseTemplateUrl: string =
   // this.domainUrl + "/template?" + this._servicesService.tokenparam;

  getusename = this._servicesService.getUserName();

  public servicesContact = [];

  ngOnInit() {
    this.spinnerService.visibility.subscribe(state => {
      this.visibility = state;
      // console.log('visibility', state);
    });

    this.spinnerService.notVisibility.subscribe(state => {
      this.visibility = state;
      // console.log('notVisibility', state);
    });

    this._servicesService
      .getComingSoon()
      .subscribe(data => this.servicesContact.push(data));
  }

  extension(str) {
    let ext = str.lastIndexOf(".");
    if (ext == -1) {
      return null;
    } else {
      if (str.slice(ext + 1, str.length) === "zip") return " data";
      else return " " + str.slice(++ext, str.length);
    }
  }

  extensionhtml(str) {
    let ext = str.split(".");
    if (ext[1] == null) {
      return str;
    } else {
      return ext[0];
    }
    // if (ext.length < 2)
    //   return str;
  }

  extensionhtmlt(str) {
    let ext = str.split(".");
    if (ext[1] == null) {
      return true;
    } else {
      return false;
    }
    // if (ext.length < 2)
    //   return str;
  }

  htmlfolderlink(str) {
    if (str == null) {
      return false;
    } else {
      return true;
    }
  }

  hasHtml(reportFiles) {
    let hasHtml = false;
    for (let i = 0; i < reportFiles.length; i++) {
      if (this.extensionhtmlt(reportFiles[i].filename)) {
        hasHtml = true;
        break;
      }
    }
    return hasHtml;
  }

  subscribeButton = [{ toggle: false, name: "All Products" }];
  toggleBehav = true;
  toggleSubscribeClicked(button) {
    // button.toggle = !button.toggle;
    // button.name = button.toggle ? "Subscribed Products" : "All Products";
  }

  toggleFlatClicked() {
    this.router.navigateByUrl("/myProducts");
  }

  isAdmin() {
    return this.authorizationService.isAdmin();
  }

  forwardContactUs(service, year, report) {
    this.router.navigateByUrl(
      "/contactus?message=I would like more information about the report: " +
        service +
        " " +
        year +
        " " +
        report
    );
  }

  openAllSecond() {
    this.secondAccordion.openAll();
  }

  closeAllSecond() {
    this.secondAccordion.closeAll();
  }
}
