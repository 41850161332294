import { Component, Input, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl ,FormsModule,NgForm } from '@angular/forms';
import { SaveSearchModel } from '../dashsearch/savesearchmodel';

@Component({
  selector: 'app-savesearch-dialog',
  templateUrl: 'savesearch-dialog.html'
})
export class SavesearchDialog {
  searchName: string;


  constructor(public dialogRef2: MatDialogRef<SavesearchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SaveSearchModel) {}

  onNoClick(): void {
    this.dialogRef2.close();
  }

  public confirmMessage:string;
  public confirmMessage2:string;

}
