import {Component} from "@angular/core";
import {Router} from "@angular/router";
import { environment } from "../../environments/environment";
import {UserRegistrationService} from "../auth-servicecognito/user-registration.service";
import {CognitoCallback, CognitoUtil} from "../auth-servicecognito/cognito.service";
import { FormBuilder, FormGroup, Validators, FormControl ,FormsModule,NgForm } from '@angular/forms';
import { AllgroupsserviceService } from "../auth-servicecognito/allgroupsservice.service"
import * as AWS from "aws-sdk/global";

export class RegistrationUser {
    name: string;
    family_name: string;
    title: string;
    companyName: string;
    workemail: string;
    email: string;
    phone_number: string;
    password: string;
    passwordConfirm: string;
    groupname: Igroupname[]
}


export class Igroupname {
    name:string;
    value: string;
}


/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 * AdminListGroupsForUser
 */
@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './registration.html',
    styleUrls: ['./registration.component.scss']

})

export class RegisterComponent implements CognitoCallback {
    registrationUser: RegistrationUser;
    router: Router;
    errorMessage: string;
    pageTitle = 'Register New User';
    // groupUser: AllGroupsUser;
    //listGroups: string[];

    groups = new FormControl();
    listGroups: string[] = ['admin', 'Attribute-grp', 'Brand-grp']; 
    
    constructor(public userRegistration: UserRegistrationService, router: Router, public allGroup: AllgroupsserviceService) {
        this.router = router;
        this.onInit();
        
    }

    onInit() {
        this.registrationUser = new RegistrationUser();
       // this.groupUser = new AllGroupsUser();
        this.errorMessage = null;
    }

    groupslisted() {
        this.errorMessage = null;
      return this.allGroup.getGroups();

    }

    onRegister() {
        this.errorMessage = null;
        this.userRegistration.register(this.registrationUser, this);
    }


    cognitoCallback(message: string, result: any) {
        if (message != null) { //error
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
        } else { //success
            //move to the next step

        this.userRegistration.groupCallback(this.registrationUser)    
            console.log(this.userRegistration.groupCallback(this.registrationUser) );
            this.router.navigate(['/confirmRegistration', result.user.username]);
        }
    }

    resetForm(regForm: NgForm) {
        this.router.navigate(['/register']);
        regForm.resetForm();
      }


    
    



    // callbackWithGroups(result: any) {

    //     for (let i = 0; i < result.length; i++) {
    //         let parameter = new AllGroups();
    //         parameter.name = result[i].getName();
    //         parameter.value = result[i].getValue();
    //         this.parameters.push(parameter);
    //     }
    //     let param = new AllGroups()
    //     param.name = "Group Name";
    //     param.value = this.cognitoUtil.getGroups();
    //     this.parameters.push(param)
    // }




}
