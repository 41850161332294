<div class="container">
  <mat-card class="hsgservicescard">
    <mat-card-title class="textcolorabout">
      Frequently Asked Questions
    </mat-card-title>
    <mat-card-content class="topentries">
      <span>
        <a class="leftlinks" (click)="allExpandState = true">Expand All </a>
        <a class="leftlinks" (click)="allExpandState = false">Collapse All </a>
      </span>
    </mat-card-content>
  </mat-card>

  <mat-accordian multi>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        1. How do I purchase additional research outside my current subscriptions?
      </mat-expansion-panel-header>
      <div class="answer">
        When you locate a deliverable you can click on the "More Information" button and you will be taken to a Contact
        Us screen.
        Please enter your inquiry into the Message box and Submit.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        2. Who do I contact if I can’t see my current subscriptions?
      </mat-expansion-panel-header>
      <div class="div answer">
        Click on the <a class="leftlinks" href="/#/contactus">"Contact Us"</a> tab and you will be taken to a Contact Us
        screen. Please
        enter your inquiry into the
        Message box and Submit.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        3. How do I download a report in different file types (PDF, PPT, HTML, CSV)
      </mat-expansion-panel-header>
      <div class="answer">
        To the right of the deliverable you will see what formats are available for downloading.
        Click on the format and your report will be available in the left hand corner of your screen.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        4. I am having trouble downloading a report, what else should I do?
      </mat-expansion-panel-header>
      <div class="answer">
        Click on the <a class="leftlinks" href="/#/contactus">"Contact Us"</a> tab and you will be taken to a Contact Us
        screen. Please enter your issue into the
        Message box and Submit.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        5. What if the download stops or is interrupted before it is complete?
      </mat-expansion-panel-header>
      <div class="answer">
        You can attempt to download again. If you are still having issues click on the <a class="leftlinks"
          href="/#/contactus">"Contact Us"</a> tab and you will
        taken to the Contact Us screen.
        Please enter your issue into the Message box and Submit.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        6. Where can I explore non-subscription products?
      </mat-expansion-panel-header>
      <div class="answer">
        When you log in the first screen will provide you with the HSI product catalog.
        Simply scroll through+B7 the Product Lines by navigating over to the right and clicking the down arrow.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        7. What is the workspace and its utilization?
      </mat-expansion-panel-header>
      <div class="answer">
        You can use the Workspace tab to maintain reports that you need to access frequently.
        Click on the corresponding symbol and the report will appear in your workspace.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        8. Can I customize My Workspace?
      </mat-expansion-panel-header>
      <div class="answer">
        Yes. There is a Workspace tab that you can utilize to maintain reports that you need to access frequently.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        9. How do I use the search function?
      </mat-expansion-panel-header>
      <div class="answer">
        Within the Search you can use key words and a listing will appear of all deliverables that contain that key
        word.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        10. Can I save searches?
      </mat-expansion-panel-header>
      <div class="answer">
        Yes. Within the Search box, to the right is a "Save This Search" option, click to save search for future use.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        11. Can I delete items from my Workspace?
      </mat-expansion-panel-header>
      <div class="answer">
        Yes. Click on the Workspace icon to the left of your report name and that will delete it from your workspace.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        12. How can I get an on-site presentation?
      </mat-expansion-panel-header>
      <div class="answer">
        Click on the <a class="leftlinks" href="/#/contactus">"Contact Us"</a> tab and you will be taken to a Contact Us
        page. Please enter your inquiry into the
        Message box and Submit.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        13. How can I provide feedback on the research?
      </mat-expansion-panel-header>
      <div class="answer">
        Click on the <a class="leftlinks" href="/#/contactus">"Contact Us"</a> tab and you will be taken to a Contact Us
        page. Please enter your inquiry into the
        Message box and Submit.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        14. How do I download data from the Workspace?
      </mat-expansion-panel-header>
      <div class="answer">
        To the right of the deliverable you will see what formats are available for downloading.
        Click on "csv" and your report will be available in the left hand corner of your screen.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        15. Can I share my Workspace with my colleagues?
      </mat-expansion-panel-header>
      <div class="answer">
        This feature is currently not available and coming soon.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        16. How do I change my password?
      </mat-expansion-panel-header>
      <div class="answer">
        At the top right you will see a Welcome and your name. Click on the down arrow and you will see a menu that
        includes Change my Password.
        Click on it and you will be able to change your password.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        17. How do I create a username?
      </mat-expansion-panel-header>
      <div class="answer">
        A user name will be provided to you by HSI
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        18. To offer feedback on our website portal
      </mat-expansion-panel-header>
      <div class="answer">
        please utilize our More Information (info@healthstrategies.com ) link.
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        19. How do I request a new password?
      </mat-expansion-panel-header>
      <div class="div answer">
        If you forgot your password, use the Forgot Password link at the bottom of the login page to request a new
        password.<br />
        Upon clicking the link, you will be required to enter your email address and click the next button.<br />
        A verification code to reset your password will be sent to the email address you registered with Health
        Strategies.<br />
        Enter your verification code along with your new password in both password fields to set your new
        password.<br />
        Once authenticated to the website, you always have the option to change your password<br />
        using the Change Password link located in the top right corner of your screen.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="allExpandState">
      <mat-expansion-panel-header class="question">
        20. How do I navigate the site?
      </mat-expansion-panel-header>
      <div class="answer">
        <b>Our Main Menu </b><br />
        Everything you need from the portal is accessible via the orange menu bar at the top of each screen.<br />
        <b>Services </b><br />
        This is where to find your purchased research as well as a list of all research HSI publishes.<br />
        <b>Search </b><br />
        Click here to search your purchased research by keyword and find publications most relevant to your search
        criteria.<br />
        Search results will be sorted by most relevant to least relevant. To view the publication, click on the blue
        text of the content’s title.<br />
        If other content formats are available for download, the format will be listed in blue to the right of each
        search result.<br />
        Other formats typically include PDF, Power Point (PPTX), and a Zip file containing Comma Separated Data Files
        (CSV).<br />
        Click the format description to download each available content type.<br />
        You may also save your search criteria by name if you want to recall a prior search result.<br />
        When you see a search result you like, click the green checkmark to add a search to your saved searches.<br />
        <b>My Favorites</b><br />
        This tab provides a listing of all your favorite publications.<br />
        The format of the display is the same as the search screen, so you’ll be able to view or download any of your
        favorites by clicking the blue text.<br />
        If you like a publication and would like quick access to it, you can add the publication to your favorites<br />
        by clicking the white star with the orange outline to create a listing of your favorites.<br />
        When an item has a solid orange star, that means you have already added this item as one of your
        favorites.<br />
      </div>
    </mat-expansion-panel>
  </mat-accordian>
</div>
