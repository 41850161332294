import { Component, ViewContainerRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm, FormsModule } from "@angular/forms";
import { HttpParams, HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ConfirmationDialog5 } from '../../confirmation-dialog5/confirmation-dialog';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-contactus",
  templateUrl: "./contactus.component.html",
  styleUrls: ["./contactus.component.scss"]
})
export class ContactusComponent implements OnInit {
  product_service_baseUrl: string = "";
  product_service_baseUrlPlus: string = "";
  name: string;
  familyname: string;
  title: string;
  company: string;
  email: string;
  phone: string;
  message: string = this.getMessageParam();
  showcheck: boolean = false;
  contacform = NgForm;
  disabled: string;
  jwtParam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token")) ;
  dialogRef: MatDialogRef<ConfirmationDialog5>;

  constructor(private http: HttpClient, private route: ActivatedRoute, public router: Router, public dialog: MatDialog, private cookie: CookieService ) {
    this.router = router;
    this.product_service_baseUrl = environment.product_service_baseUrl;
    this.name = sessionStorage.getItem("firstname") ? sessionStorage.getItem("firstname") : localStorage.getItem("firstname") ;
    this.familyname = sessionStorage.getItem("lastname") ? sessionStorage.getItem("lastname") : localStorage.getItem("lastname");
    this.email = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username") ;
    this.phone = sessionStorage.getItem("phone") ? sessionStorage.getItem("phone") :localStorage.getItem("phone") ;
    this.company = sessionStorage.getItem("companyname") ? sessionStorage.getItem("companyname") : localStorage.getItem("companyname")  ;
    this.product_service_baseUrlPlus =
      this.product_service_baseUrl + "/asktheexpert?" + this.jwtParam;
  }

  ngOnInit() {}

  toast() {
    this.showcheck = true;
  }

  redir() {
    this.router.navigate(["/menu"]);
  }

  processForm() {
    let params = new HttpParams()
      .append("name", this.name)
      .append("familyname", this.familyname)
      .append("title", this.title)
      .append("company", this.company)
      .append("email", this.email)
      .append("phone", this.phone)
      .append("message", this.message)
    //Create new HttpParams
    this.http
      .get(this.product_service_baseUrlPlus, { params })

      .map((response: Response) => {
        console.log("response from Ask the Expert" + response.json());
        response.json();
      })
      .subscribe(res => console.log("the responsey:" + res));

    this.dialogRef = this.dialog.open(ConfirmationDialog5, {
      disableClose: false,
      width: "500px"
      });
      this.dialogRef.componentInstance.title = "Confirmation"
      this.dialogRef.componentInstance.confirmMessage = 
        "Thank you for your inquiry and we are delighted to assist you. One of our content experts will be responding to your inquiry within 1-2 business days.  ~ Your Client Services Team"
      this.dialogRef.afterClosed().subscribe(result => {
      if(result) {
        
       //this.router.navigate(['/home']);
      }
      this.dialogRef = null;
      });
      this.router.navigate(['/menu'])
  }

  resetForm(contacform: NgForm) {
    this.router.navigate(["/contactus"]);
    contacform.resetForm();
  }

  getMessageParam() {
    let msg = "";
    this.route.queryParams.subscribe(params => {
        msg = params['message'];
      });
      return msg;
  }
}
