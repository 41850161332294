import { Injectable } from '@angular/core';
import { IUser } from './user';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class AuthService {
  currentUser: IUser;
  redirectUrl: string;
  
  constructor() { 

  }

  isLoggedIn(): boolean {
    // if (this.consent == "true"){
      //let token = sessionStorage.getItem('token');
      let token = sessionStorage.getItem('token')? sessionStorage.getItem('token') : localStorage.getItem('token');
     // console.log(localStorage.getItemAll);
      return !!token;     
    // } else {
    // let token = sessionStorage.getItem('token');
    // return null;
// }
}

get token() {
  return sessionStorage.getItem('token');
  //return sessionStorage.getItem('token')? sessionStorage.getItem('token') : localStorage.getItem('token');
}

public getToken(): string {
  //return sessionStorage.getItem('token');
  return sessionStorage.getItem('token')? sessionStorage.getItem('token') : localStorage.getItem('token');
}

isLoggedIn1(){
  //let token = sessionStorage.getItem('token')? sessionStorage.getItem('token') : localStorage.getItem('token');
  let token = sessionStorage.getItem('token')? sessionStorage.getItem('token') : null;
  return new HttpHeaders (
    {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': token
    }
  );
}


login(userName: string, password: string): void {

  if (userName === 'admin') {
      this.currentUser = {
          id: 1,
          userName: userName,
          isAdmin: true
      };
   //   alert(password);
   //   return;
  } 
  this.currentUser = {
      id: 2,
      userName: userName,
      isAdmin: false
  }; 

}

  logout(): void {
    this.currentUser = null;
  }


}

