import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { newContent } from '../dashsearch/newcontentmodel';
import { ServicesService } from './services.service';

@Injectable({
  providedIn: 'root'
})
export class ComingSoonService {
  tokenparam = this.sessionService.tokenparam;
  private baseUrl = this.sessionService.baseUrl;

  getUserName() {
    this.sessionService.getUserName();
  }

  constructor(private http: HttpClient, private sessionService: ServicesService) { }

  public getMyServicesContact(): Observable<any> {
    let savedsearchURL1 = `${this.baseUrl}/product/newContent?${this.tokenparam}`;
    // let savedsearchURL1 = `${this._url}/savedSearch`;
    return this.http.get(savedsearchURL1)
      .catch(this.errorHandler);
  }

  getComingSoon() {
    let savedsearchURL1 = `${this.baseUrl}/product/newContent?${this.tokenparam}`;
    return this.http.get(savedsearchURL1).map((data: newContent) => {
      console.log(data.comingSoon);
      return data.comingSoon;
    });
  }

  public getServicesContact(): Observable<any> {
    let savedsearchURL2 = `${this.baseUrl}/product/newContent`;
    return this.http.get(savedsearchURL2)
      .catch(this.errorHandler);
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error")
  }

}
