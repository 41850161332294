import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-ads',
  templateUrl: './login-ads.component.html',
  styleUrls: ['./login-ads.component.scss']
})
export class LoginAdsComponent implements OnInit {
  _allExpandState = false;

  public get allExpandState(): boolean {
    return this._allExpandState;
  }
  accessExpressLink = "https://www.eversana.com/products/health-strategies-insights/#section-3";
  spoLink = "https://www.eversana.com/2020/04/27/spoptimizer-by-eversana/";
  constructor() { }

  ngOnInit() {
  }

  onAdClick(button: string){

    if(button=='accessExpress'){
      window.open(this.accessExpressLink);
    } else if (button=='spo'){
      window.open(this.spoLink);
    }
  }

}
