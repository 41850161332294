import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  Input
} from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DashboardservicesService } from "../services/dashboardservices.service";
import { DashSearchService } from "../dashsearch/dashsearch.service";
import { Idashsearchmodel } from "../dashsearch/dashsearchmodel";
import {
  newContent,
  comingSoon,
  whatsNew,
  getCaughtUp
} from "../dashsearch/newcontentmodel";
import { FilterPipe } from "../shared/pipes";
import { environment } from "../../environments/environment";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { MatSidenav } from "@angular/material";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { Subject } from "rxjs/Subject";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfirmationDialog3 } from "../confirmation-dialog3/confirmation-dialog";
import { element } from "@angular/core/src/render3";
import { SpinnerService } from "../spinner.service";

@Component({
  selector: "app-mydashboard",
  templateUrl: "./mydashboard.component.html",
  styleUrls: ["./mydashboard.component.scss"],
  providers: [DashboardservicesService, DashSearchService]
})
export class MydashboardComponent implements OnInit {
  color = "accent";
  mode = "determinate";
  value = 30;
  dashboardemptyarea =
    'This page can be used to store your most frequently used items for quick access.<br><br>Please use the <strong><i><a class="linkcolor" href="/#/search">search</a></i></strong> or <strong><i><a class="linkcolor" href="/#/myfavorites">favorites</a></i></strong> screen identify your most frequently used content<br><br>and click the workspace icon to add it on this page.';

  dialogRef: MatDialogRef<ConfirmationDialog3>;
  theSearch: Observable<Idashsearchmodel[]>;
  theSearchArray: Idashsearchmodel[];

  position = new FormControl("after");
  hideDelay = new FormControl(0);
  queryString: any;
  emptyDashboard: boolean = true;
  public visibility: boolean = false;

  //mode = new FormControl("over");
  public getCaughtUp: Observable<getCaughtUp[]>;
  public whatsNew: Observable<whatsNew[]>;
  public comingSoon: Observable<comingSoon[]>;
  @ViewChild("sidenav") public sidenav: MatSidenav;

  item1card: any;
  isActive = false;
  public baseUrl: string = environment.product_service_baseUrl;
  panelOpenState = true;
  username: string = "webuser";
  password: string = "Mwun7%aeP/BhG+WD";
  basic = "";
  credentials = "";
  users = null;
  example: any;
  openedvalue: boolean = true;
  closethisdashboard: boolean = true;

  whatsnewvalSrc: Subject<boolean>;
  whatsnewval: boolean = true;
  whatsnewval2: boolean = false;

  opentabvalSrc: Subject<number>;
  opentabval: number = 1;
  opentabval2: number = 0;

  // constructor() { }
  constructor(
    public _dashSearchService: DashSearchService,
    public _dashboardservicesService: DashboardservicesService,
    public dialog: MatDialog,
    private spinnerService: SpinnerService
  ) {
    // this.clickfortab()
  }
  public baseUrl1: string =
    this.baseUrl +
    "/download?" +
    this._dashboardservicesService.tokenparam +
    "&";
  getusename = this._dashboardservicesService.getUserName();
  public dashServ = [];

  ngAfterViewChecked() {
    this.whatsnewvalSrc = this._dashboardservicesService.whatsnewvalSource;
    this.opentabvalSrc = this._dashboardservicesService.opentabvalSource;
  }

  ngOnInit() {
    this.spinnerService.visibility.subscribe(state => {
      this.visibility = state;
      // console.log('visibility', state);
    });

    this.spinnerService.notVisibility.subscribe(state => {
      this.visibility = state;
      // console.log('notVisibility', state);
    });
    this._dashboardservicesService
      .getdashServ()
      .subscribe(data => (this.dashServ = data));

    this.theSearch = this._dashSearchService.getThefaves();
    this.comingSoon = this._dashSearchService.getcomingSoon();
    this.getCaughtUp = this._dashSearchService.getGetCaughtUp();
    this.whatsNew = this._dashSearchService.getWhatsNew();

    this._dashSearchService.getThefaves().subscribe(data => {
      console.log("data----->", data);
      this.theSearchArray = data;
    });

    this._dashSearchService.hasDashboard().subscribe(data => {
      console.log("data----->", data);
      this.emptyDashboard = !data;
    });

    console.log(
      "In init emptyDashboard--->" +
        this.emptyDashboard +
        "---visibility---->" +
        this.visibility
    );
  }

  reloadf() {
    this.theSearch = this._dashSearchService.getThefaves();
    console.log("In init emptyDashboard reload--->" + this.emptyDashboard);
  }

  clickfortab(fullnameofreport) {
    this.sidenav.toggle();
    this.openedvalue = false;
    this._dashboardservicesService.changeWhatsnewval(this.whatsnewval);
    this._dashboardservicesService.changeopentabval(this.opentabval);
    // window.location.hash = '';
    // window.location.hash = fullnameofreport;
  }

  showagain() {
    this.openedvalue = true;
    this.sidenav.toggle();
    this._dashboardservicesService.changeWhatsnewval(this.whatsnewval2);
    this._dashboardservicesService.changeopentabval(this.opentabval2);
  }

  htmlnoext(str) {
    let ext = str.split(".");
    if (ext[1] == null) {
      return true;
    } else {
      return false;
    }
  }

  getAndTruncateDescription(desc) {
    if (desc != null && desc.length > 205) {
      return desc.substring(0, 199) + " ...";
    } else {
      return desc;
    }
  }

  extension(str) {
    let ext = str.lastIndexOf(".");
    if (ext == -1) {
      return null;
    } else {
      if (str.slice(ext + 1, str.length) === "zip") return " data";
      else return " " + str.slice(++ext, str.length);
    }
  }

  closedashboard(i, report) {
    // alert(report)

    this.dialogRef = this.dialog.open(ConfirmationDialog3, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage =
      "Are you sure you want to remove the item:";
    this.dialogRef.componentInstance.confirmMessage2 =
      report.reportTypeFullName;
    this.dialogRef.componentInstance.confirmMessage3 = "from your Workspace?";
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        report.dashboard = false;
        this._dashSearchService.updatefavoriteserv(report).subscribe();
        report.dashboard = false;
        // this.theSearch.forEach(elements => {
        //   elements.forEach(element => {
        //     if (element.dashboard == true) {
        //       this.emptyDashboard = false;
        //     } else {
        //       this.emptyDashboard = true;
        //     }
        //   });
        // });
      }
      this._dashSearchService.hasDashboard().subscribe(data => {
        console.log("data----->", data);
        this.emptyDashboard = !data;
      });
      this.dialogRef = null;
    });
    console.log("In init closedashboard--->" + this.emptyDashboard);
  }

  dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.theSearchArray,
      event.previousIndex,
      event.currentIndex
    );
  }
}
