import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../auth-servicecognito/user-login.service";
import {CognitoCallback} from "../auth-servicecognito/cognito.service";
import { User } from "../auth-user/userpasswords.interface"
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-forgot2',
  templateUrl: './forgot2.component.html',
  styleUrls: ['./forgot2.component.scss']
})
export class Forgot2Component implements CognitoCallback, OnInit, OnDestroy {
  public user: User;
  pageTitle = 'Enter New Password';
  verificationCode: string;
  email: string;
  password: string;
  passwordConfirm: string;
  errorMessage: string;
  private sub: any;

  constructor(public router: Router, public route: ActivatedRoute,
              public userService: UserLoginService) {
              this.onInit    
      console.log("email from the url: " + this.email);
  }

  onInit() {
    this.user = new User()
    this.errorMessage = null;
}


  ngOnInit() {
      this.sub = this.route.params.subscribe(params => {
          this.email = params['email'];
      });
      this.errorMessage = null;

      this.user = {
        username: '',
        email: '',
        password: '',
        passwordConfirm: ''
    }


  }

  ngOnDestroy() {
      this.sub.unsubscribe();
  }

  onNext() {
      this.errorMessage = null;
      this.userService.confirmNewPassword(this.email, this.verificationCode, this.user.password, this);
  }

  cognitoCallback(message: string) {
      if (message != null) { //error
          this.errorMessage = message;
          console.log("result: " + this.errorMessage);
      } else { //success
          this.router.navigate(['/login']);
      }
  }

}