import { Component, Input, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { SaveSearchModel } from '../dashsearch/savesearchmodel';
import { DashSearchService} from '../dashsearch/dashsearch.service'


@Component({
  selector: 'app-deleteconfirmation-dialog',
  templateUrl: 'deleteconfirmation-dialog.html'
})
export class DeleteconfirmationDialog {
  searchName: string;


  constructor(public dialogRef3: MatDialogRef<DeleteconfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SaveSearchModel, public _dashSearchService: DashSearchService) {
      this.loadSaveSearches
    }

    public savedSearch = []

    loadSaveSearches(){
      this._dashSearchService.getTheSavedSearch()
      .subscribe(data=>this.savedSearch = data);
      
      
    }  



    onNoClick(): void {
      this.dialogRef3.close();
    }
  
    public confirmMessage:string;
    public confirmMessage2:string;
    public confirmMessage3:string;
}
