import { Injectable } from "@angular/core";
import { Observable, bindNodeCallback } from 'rxjs';
import { environment } from "../../environments/environment";
import { CognitoUserPool, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { PARAMETERS } from "@angular/core/src/util/decorators";
import { GroupedObservable } from "rxjs/operators/groupBy";
//import { AllGroupsUser } from "../auth-register/registration.component";
var aws = require('aws-sdk');
aws.config.update({accessKeyId: 'akid', secretAccessKey: 'secret'});


var cognitoisp = new CognitoIdentityServiceProvider({ apiVersion: '2016-04-19', region: 'us-east-1' });

var params = {
    AuthFlow: 'REFRESH_TOKEN',
    ClientId: environment.poolData.ClientId,
    UserPoolId: environment.poolData.UserPoolId,
    AuthParameters: {
      'REFRESH_TOKEN': localStorage.getItem('refreshToken')
    }
  }


export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;

    handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}


export interface ChallengeParameters {
    CODE_DELIVERY_DELIVERY_MEDIUM: string;

    CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
    callback(): void;

    callbackWithParam(result: any): void;
}



@Injectable()
export class CognitoUtil {

    public static _REGION = environment.region;
    public static _IDENTITY_POOL_ID = environment.identityPoolId;
    public static _USER_POOL_ID = environment.poolData.UserPoolId;
    public static _CLIENT_ID = environment.poolData.ClientId;
    public static _POOL_DATA: any = {
        UserPoolId: CognitoUtil._USER_POOL_ID,
        ClientId: CognitoUtil._CLIENT_ID
    };
    public cognitoCreds: AWS.CognitoIdentityCredentials;
    public cognitoGroups: CognitoIdentityServiceProvider;

    // constructor(cognitoUser: CognitoUser){


        
    // }




    getUserPool() {
        if (environment.cognito_idp_endpoint) {
            CognitoUtil._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
        }
        return new CognitoUserPool(CognitoUtil._POOL_DATA);
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }

    refreshTheToken(){
        cognitoisp.adminInitiateAuth(params, function(err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else     console.log('successful response:'+ data);           // successful response
          });
     } 


    // AWS Stores Credentials in many ways, and with TypeScript this means that
    // getting the base credentials we authenticated with from the AWS globals gets really murky,
    // having to get around both class extension and unions. Therefore, we're going to give
    // developers direct access to the raw, unadulterated CognitoIdentityCredentials
    // object at all times.
    setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
        this.cognitoCreds = creds;
    }

    getCognitoCreds() {
        return this.cognitoCreds;
    }

    // This method takes in a raw jwtToken and uses the global AWS config options to build a
    // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
    // to avoid unnecessary calls to setCognitoCreds.

    buildCognitoCreds(idTokenJwt: string) {
        let url = 'cognito-idp.' + CognitoUtil._REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtil._USER_POOL_ID;
        if (environment.cognito_idp_endpoint) {
            url = environment.cognito_idp_endpoint + '/' + CognitoUtil._USER_POOL_ID;
        }
        let logins: CognitoIdentity.LoginsMap = {};
        logins[url] = idTokenJwt;
        let params = {
            IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID, /* required */
            Logins: logins
        };
        let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
        if (environment.cognito_identity_endpoint) {
            serviceConfigs.endpoint = environment.cognito_identity_endpoint;
        }
        let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
        this.setCognitoCreds(creds);
        return creds;
    }


getGroups(){
    var params = {
        UserPoolId: environment.poolData.UserPoolId, /* required */
        Limit: 10,
        NextToken: ''       
      };

      aws.cognitoidentityserviceprovider.ListGroups(params, function(err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else     console.log(data);      
        return data;// successful response     // successful response
      });
}

    getList(){
        var params = {
            UserPoolId: environment.poolData.UserPoolId, /* required */
            AttributesToGet: [],
            Filter: "",
            Limit: 10
        };
        var cognito = new aws.CognitoIdentityServiceProvider.CognitoUserPool(CognitoUtil._POOL_DATA);
            cognito.ListUsers(params, function(err, data) {
            if (err) console.log(err, err.stack);// an error occurred
            else return data;// successful response
        });
    }    

    getCognitoIdentity(): string {
        return this.cognitoCreds.identityId;
    }

    getAccessToken(callback: Callback): void {
        if (callback == null) {
            throw("CognitoUtil: callback in getAccessToken is null...returning");
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        this.cognitoUser.refreshSession(CognitoUserSession.prototype.getRefreshToken().getToken(), callback)
                        // callback.callbackWithParam(session.getAccessToken().getJwtToken());
                    }
                }
            });
        }
        else {
            callback.callbackWithParam(null);
        }
    }

    getIdToken(callback: Callback): void {
        if (callback == null) {
            throw("CognitoUtil: callback in getIdToken is null...returning");
        }
        if (this.getCurrentUser() != null)
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getIdToken().getJwtToken());
                    } else {
                        console.log("CognitoUtil: Got the id token, but the session isn't valid");
                    }
                }
            });
        else
            callback.callbackWithParam(null);
    }

    getRefreshToken(callback: Callback): void {
        if (callback == null) {
            throw("CognitoUtil: callback in getRefreshToken is null...returning");
        }
        if (this.getCurrentUser() != null)
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }

                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getRefreshToken());
                    }
                }
            });
        else
            callback.callbackWithParam(null);
    }


    public cognitoUser: CognitoUser;
    refresh(): void {
        this.getCurrentUser().getSession(function (err, session) {
            if (err) {
                console.log("CognitoUtil: Can't set the credentials:" + err);
            }

            else {
                if (session.isValid()) {
                    CognitoUser.prototype.refreshSession(CognitoUserSession.prototype.getRefreshToken(), bindNodeCallback)
                    console.log("CognitoUtil: refreshed successfully");
                } else {
                    console.log("CognitoUtil: refreshed but session is still not valid");
                }
            }
        });
    }





}
