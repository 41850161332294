<div class="container pd-30" style="margin-bottom: 60px;">
  <div class="row justify-content-md-center">
    <div class="col col-md-6 col-lg-6">
    <form role="form" #contacform="ngForm">
      <div class="form-group">
        <label for="exampleInputEmail1">First Name *</label>
        <input matInput class="form-control" id="firstname" type="text" name="firstname"
            [(ngModel)]="name" required minlength="3" #firstnameInput="ngModel" />      
      </div>

      <div class="form-group">
        <label for="exampleInputEmail1">Company *</label>
        <input matInput class="form-control" id="company" type="text" name="company"
            [(ngModel)]="company" required minlength="3" #companyInput="ngModel" /> 
            <span class="help-block" *ngIf="(companyInput.touched || companyInput.dirty) && companyInput.errors"></span>
      </div>

      <div class="form-group">
        <label for="exampleInputEmail1">Job Title *</label>
        <input matInput class="form-control" id="title" type="text" name="title" [(ngModel)]="title"
          required #titleInput="ngModel" />
          <span class="help-block" *ngIf="(titleInput.touched || titleInput.dirty) && titleInput.errors"></span>
      </div>

      <div class="form-group">
        <label for="exampleInputEmail1">Work Email *</label>
        <input matInput class="form-control" id="workemail" type="email" name="workemail"
          [(ngModel)]="email" required email #workemailInput="ngModel" />
          <span class="help-block" *ngIf="(workemailInput.touched || workemailInput.dirty) && workemailInput.errors"></span>
      </div>

      <div class="form-group">
        <label for="exampleInputEmail1">Phone Number</label>
        <input matInput class="form-control" id="phonenumber" type="text" name="phonenumber"
        [(ngModel)]="phone" phone #phonenumberInput="ngModel" />
      </div>

      <div class="form-group">
        <label for="exampleInputEmail1">Describe Your Inquiry *</label>        
        <textarea matInput rows="5" cols="40" class="form-control" name="message" [(ngModel)]="message" 
        #messageInput="ngModel"></textarea>
        <span class="help-block" *ngIf="(messageInput.touched || messageInput.dirty) && messageInput.errors"></span>
      </div>
     
      <button type="submit" class="btn btn-primary pull-right" (click)="processForm()" type="submit" [disabled]="contacform.invalid" style="background-color: #a71043;border-color: #a71043;">Submit</button>
    </form>
    </div>
  </div>

</div>

