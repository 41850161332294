<div class="flex-containerabout">



<div class="flex-container3">
  <mat-card class="item3card mat-elevation-z5">
    <mat-card-header>
      
      <mat-card-title class="textcolor1">{{pageTitle}}</mat-card-title>
    </mat-card-header>
    <mat-card-content> 
   
  
      <!--    <form novalidate (ngSubmit)="login(loginForm)" #loginForm="ngForm" autocomplete="off" > -->
          <form novalidate  #loginForm="ngForm" autocomplete="off" method="POST" action="#" role="form"> 
              <fieldset>
                  <div class="form-group" 
                       [ngClass]="{'has-error': (emailVar.touched || 
                                                 emailVar.dirty) && 
                                                 !emailVar.valid }">
  
                              <mat-form-field>
                          <input matInput class="form-control" 
                                  id="emailId" 
                                  type="text" 
                                  placeholder="email (required)"
                                  required
                                  email
                                  [(ngModel)] = "email"
                                  name="email"
                                  #emailVar="ngModel" />
                          <span class="help-block" *ngIf="(emailVar.touched ||
                                                           emailVar.dirty) &&
                                                           emailVar.errors">

                          </span>
                          </mat-form-field>
                     
                  </div>
                  
                  <div class="form-group">
                      <div>
                          <span>
                              <button mat-raised-button color="accent"
                              (click)="onNext()">
                              Next
                          </button>
                          </span>
                       </div>
                  </div>
            </fieldset>

            <div class='loginlinks'>
              <a [routerLink]="['/login']">Login</a>
          </div>    
                   
          </form>
          <div class="has-error" style="text-align: center; font-weight: bold;color: red; padding-bottom:20px;" *ngIf="errorMessage">{{errorMessage}}</div>
       <!--   <a href="" class="pass-forgot">Forgot your password?</a> -->
    </mat-card-content>
  </mat-card>
  </div>
  </div>