import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UserRegistrationService } from "../auth-servicecognito/user-registration.service";
import { UserLoginService } from "../auth-servicecognito/user-login.service";
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import {
  NgForm,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { environment } from "../../environments/environment";
import {
  ChallengeParameters,
  CognitoCallback,
  LoggedInCallback
} from "../auth-servicecognito/cognito.service";

import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
  MAT_DIALOG_DATA
} from "@angular/material";
import { ConfirmationDialog2 } from "../confirmation-dialog2/confirmation-dialog";
import { Observable } from "rxjs";
import { AuthorizationService } from "../shared/authorization.service";
import { AuthService } from "../auth-user/auth.service";
import { CookieService } from 'ngx-cookie-service';

export class NewPasswordUser {
  username: string;
  existingPassword: string;
  password: string;
  passwordConfirm: string;
}
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
  selector: "awscognito-angular2-app",
  templateUrl: "./newpassword.html",
  styleUrls: ["./newpassword.component.scss"]
})
export class NewPasswordComponent implements CognitoCallback, LoggedInCallback {
  //Minimum six characters, at least one letter and one number:
  patternNormal: any = "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{6,}$";

  //Minimum eight characters, at least one letter, one number and one special character:
  patternMedium: any =
    "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$";

  //Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
  patternHign: any =
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$";

  //Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
  patternHig2: any = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d@$!%*?&]{8,}$";

  registrationUser: NewPasswordUser;
  username: string;
  existingPassworda: string;
  passworda: string;
  passwordConfirma: string;
  router: Router;
  _baseUrl: string = "";
  errorMessage: string;
  errorMgs: string;
  selectedPattern: string;
  dialogRef: MatDialogRef<ConfirmationDialog2>;
  data: any;
  error: any;

  constructor(
    public userRegistration: UserRegistrationService,
    public userService: UserLoginService,
    private http: HttpClient,
    router: Router,
    public dialog: MatDialog,
    private auth: AuthorizationService,
    private authService: AuthService,
    private cookie: CookieService
  ) {
    this.router = router;
    this._baseUrl = environment.user_baseUrl;
    this.onInit();
  }

  onInit() {
    this.username = this.userService.getUserName();
    this.userService.isAuthenticated(this);
    this.registrationUser = new NewPasswordUser();
    this.errorMessage = null;
    this.selectedPattern = this.patternHig2; //will change based on user preference
    if (this.selectedPattern === this.patternNormal) {
      this.errorMgs = "Password must have min 6 char,at least 1 num";
    } else if (this.selectedPattern === this.patternMedium) {
      this.errorMgs =
        "Minimum eight characters, at least one letter, one number and one special character";
    } else if (this.selectedPattern === this.patternHign) {
      this.errorMgs =
        "Minimum 8 characters, at least one uppercase letter, one lowercase letter and one number and one special character";
    } else if (this.selectedPattern === this.patternHig2) {
      this.errorMgs =
        "Minimum 8 characters, at least one uppercase letter, one lowercase letter and one number. ";
    }
  }

  onLogin2() {
    if (this.existingPassworda == null) {
      this.errorMessage = "Please provide Existing Password";
      return;
    }
    this.errorMessage = null;
    this.username = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username") ;
    this.userService.authenticate(this.username, this.existingPassworda, this);
    // this.auth.signIn(this.username, this.existingPassworda).subscribe((data) =>{
    //     this.authService.login(this.username, this.existingPassworda);
    //     const email: string = data.getIdToken().payload['email']
    // }, (err)=> {
    //     alert(err)
    //   // this.errorMessage = 'Please enter a valid email and password.';
    //    // err.message.result

    //   })
  }

  changePassword(): void {
    let accesstokenx: string = sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token") ;
    let accesstokenwithbearer: string = "Bearer " + accesstokenx;
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accesstokenx);
    let params = new HttpParams()
      .append("JWT", accesstokenwithbearer)
      .append("previouspassword", this.existingPassworda)
      .append("proposedpassword", this.passworda); //Create new HttpParams
    this.http
      .get(this._baseUrl + "/changeuserpassword?", { params })

      .map((res: Response) => {
        if (res) {
          if (res.status === 201) {
            return [{ status: res.status, json: res }];
          } else if (res.status === 200) {
            return [{ status: res.status, json: res }];
          }
        }
      })
      .catch((error: any) => {
        if (error.status < 400 || error.status === 500) {
          return Observable.throw(new Error(error.status));
        }
      })
      .subscribe(
        res => {},
        error => {
          console.log(error);
        }
      );

    this.dialogRef = this.dialog.open(ConfirmationDialog2, {
      disableClose: false
    });

    this.dialogRef.componentInstance.title = "Password Change";
    this.dialogRef.componentInstance.confirmMessage =
      "Your Password Has Been Changed!";
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(["/mydashboard"]);
      }
      this.dialogRef = null;
    });
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      // this.router.navigate(['/home']);
    }
  }

  resetForm(loginForm: NgForm) {
    this.router.navigate(["/newPassword"]);
    loginForm.resetForm();
  }

  cognitoCallback(message: string, result: any) {
    if (message != null) {
      //error
      this.errorMessage = "Incorrect Existing Password";
      console.log("result: " + this.errorMessage);
      if (this.errorMessage === "User is not confirmed.") {
        console.log("redirecting");
        let username = this.userService.getUserName();
        this.router.navigate(["/confirmRegistration", username]);
      } else if (this.errorMessage === "User needs to set password.") {
        console.log("redirecting to set new password");
        this.router.navigate(["/newPassword"]);
      }
    } else {
      //success
      this.changePassword();
      // this.router.navigate(['/home']);
    }
  }

  // ngOnInit() {
  //     this.errorMessage = null;
  //     console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
  //     this.userService.isAuthenticated(this);
  // }

  // onRegister() {
  //     console.log(this.registrationUser);
  //     this.errorMessage = null;
  //     this.userRegistration.newPassword(this.registrationUser, this);
  // }

  // cognitoCallback(message: string, result: any) {
  //     if (message != null) { //error
  //         this.errorMessage = message;
  //         console.log("result: " + this.errorMessage);
  //     } else { //success
  //         //move to the next step
  //         console.log("redirecting");
  //         this.router.navigate(['/login']);
  //     }
  // }

  // isLoggedIn(message: string, isLoggedIn: boolean) {
  //     // if (isLoggedIn)
  //   //  this.router.navigate(['/login']);
  // }
}
