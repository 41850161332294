import { Directive } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Directive({
  selector: '[appShared]'
})
export class SharedDirective {

  constructor(private cookie: CookieService) { }


  

  cantsee(a){
    let string = sessionStorage.getItem('groups') ? sessionStorage.getItem('groups') : localStorage.getItem('groups') ;
    if (typeof string != 'undefined' && string) {
      var groupdata = JSON.parse(string);
      for (var i = 0; i < groupdata.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (groupdata[i] == a[j]){
            return groupdata[i];
          }
        }
      }
    }  
  }
}
