import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA
} from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ClickOutsideModule } from "ng-click-outside";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from "angular2-moment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MaterialModule } from "./shared/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ResearchItem1Component } from "./research-item1/research-item1.component";
import { ExternalcontentComponent } from "./externalcontent/externalcontent.component";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { NewsComponent } from "./otherMenuItems/news/news.component";
import { AboutComponent } from "./otherMenuItems/about/about.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { HomeComponent } from "./home/home.component";
import { UserModule } from "./auth-user/user.module";
import { HeaderComponent } from "./header/header.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { DashsearchComponent } from "./dashsearch/dashsearch.component";
import { MyHsgServicesComponent } from "./otherMenuItems/myhsgservices/myhsgservices.component";
import { ServiceUnitComponent } from "./otherMenuItems/service-unit/service-unit.component";
import { ServicetitlesComponent } from "./otherMenuItems/servicetitles/servicetitles.component";
import { ContactusComponent } from "./otherMenuItems/contactus/contactus.component";
import { ServicesService } from "./services/services.service";
import { ProductService } from "./myservices/product.service";
import { DashboardservicesService } from "./services/dashboardservices.service";

import { CdkTableModule } from "@angular/cdk/table";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { FilterPipe } from "./shared/pipes";
import { SharedDirective } from "./shared/shared.directive";
import { ForgotComponent } from "./auth-forgot/forgot.component";
import { NewPasswordComponent } from "./auth-newpassword/newpassword.component";
import { RegisterComponent } from "./auth-register/registration.component";
import { ResetComponent } from "./auth-reset/reset.component";
import { Forgot2Component } from "./auth-forgot2/forgot2.component";
import { LogoutComponent } from "./auth-logout/logout.component";
import { RegistrationConfirmationComponent } from "./auth-confirm/confirmRegistration.component";
import { UserRegistrationService } from "./auth-servicecognito/user-registration.service";
import { CompareValidatorDirective } from "./auth-forgot2/compare-validator.directive";
import { ResendCodeComponent } from "./auth-resend/resendCode.component";
import { ResetpasswordService } from "./auth-reset/resetpassword.service";
import { DashSearchService } from "./dashsearch/dashsearch.service";
import { MyfavoritesComponent } from "./myfavorites/myfavorites.component";
import { GroupByPipe } from "./pipes/groupby.pipe";
import { DefaultdashboardComponent } from "./defaultdashboard/defaultdashboard.component";
import { ConfirmationDialog } from "./confirmation-dialog/confirmation-dialog";
import { ConfirmationTimeoutDialog } from "./confirmation-timeout-dialog/confirmation-dialog";
import {
  CognitoUser,
  CognitoAccessToken,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import { ContentpublisherComponent } from "./contentpublisher/contentpublisher.component";
import { PublisherService } from "./contentpublisher/publisher.service";
import { SearchyearfilterPipe } from "./contentpublisher/searchyearfilter.pipe";
import { SearchreportfilterPipe } from "./contentpublisher/searchreportfilter.pipe";
import { SavesearchDialog } from "./savesearch-dialog/savesearch-dialog";
import { DeleteconfirmationDialog } from "./deleteconfirmation-dialog/deleteconfirmation-dialog";
import { Ng2CarouselamosModule } from "ng2-carouselamos";
import { ConfirmationDialog2 } from "./confirmation-dialog2/confirmation-dialog";
import { ConfirmationDialog3 } from "./confirmation-dialog3/confirmation-dialog";
import { ConfirmationDialog4 } from "./confirmation-dialog4/confirmation-dialog";
import { ConfirmationDialog5 } from "./confirmation-dialog5/confirmation-dialog";
import { ConfirmationDialog6 } from "./confirmation-dialog6/confirmation-dialog";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { MydashboardComponent } from "./mydashboard/mydashboard.component";
import { WhatsnewComponent } from "./whatsnew/whatsnew.component";
import { AuthService } from "./auth-user/auth.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./token.interceptor";
import { DataviewsComponent } from "./dataviews/dataviews.component";
import { DataviewsService } from "./dataviews/dataviews.service";
import { DatapublishService } from "./datapublishertable/datapublish.service";
import { KeysPipe } from "./pipes/keys.pipe";
import { FilteruniquePipe } from "./pipes/filterunique.pipe";
import { SpinnerService } from "./spinner.service";
import { InterceptorService } from "./spinner.interceptor";
import { HighchartsComponent } from "./highcharts/highcharts.component";
import { HighchartsChartModule } from "highcharts-angular";
import { FilterItemDirective } from "./dataviews/filter-item.directive";
import { SavefilterDialog } from "./savefilter-dialog/savefilter-dialog";
import { LoadFiltersDialog } from "./loadfilters-dialog/loadfilters-dialog";
import { ConsentDialog } from "./consent-dialog/consent-dialog";
import { LoginserviceService } from "./login/loginservice.service";
import { FaqComponent } from "./faq/faq.component";
import { DatapublisherComponent } from "./datapublisher/datapublisher.component";
import { DatapublishertableComponent } from "./datapublishertable/datapublishertable.component";
import { MyservicesComponent } from "./myservices/myservices.component";
import { UserbatchComponent } from './userbatch/userbatch.component';
import { LoginAdsComponent } from './login-ads/login-ads.component';
import { OncologyComponent } from './oncology/oncology.component';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { CookieService } from 'ngx-cookie-service';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NewFeaturesComponent } from './new-features/new-features.component';
import {JoyrideModule} from 'ngx-joyride';
import { FileDeleteComponent } from './file-delete/file-delete.component';
import { SAVER, getSaver } from './saver.provider'
import { MatProgressBarModule } from "@angular/material";
/* Feature Modules */

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    HighchartsChartModule,
    HttpClientJsonpModule,
    FlexLayoutModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    UserModule,
    AppRoutingModule,
    Ng2CarouselamosModule,
    MomentModule,
    DragDropModule,
    CdkTableModule,
    NgIdleKeepaliveModule.forRoot(),
    JoyrideModule.forRoot(),
    MatProgressBarModule,
  ],
  declarations: [
    AppComponent,
    ResearchItem1Component,
    HeaderComponent,
    ExternalcontentComponent,
    DashboardComponent,
    AboutComponent,
    NewsComponent,
    PageNotFoundComponent,
    HomeComponent,
    BreadcrumbComponent,
    MyHsgServicesComponent,
    ServiceUnitComponent,
    ServicetitlesComponent,
    ContactusComponent,
    DashsearchComponent,
    FilterPipe,
    SharedDirective,
    ForgotComponent,
    NewPasswordComponent,
    RegisterComponent,
    ResetComponent,
    Forgot2Component,
    LogoutComponent,
    RegistrationConfirmationComponent,
    CompareValidatorDirective,
    ResendCodeComponent,
    MyfavoritesComponent,
    GroupByPipe,
    DefaultdashboardComponent,
    ConsentDialog,
    LoadFiltersDialog,
    SavefilterDialog,
    ConfirmationTimeoutDialog,
    ConfirmationDialog,
    ConfirmationDialog2,
    ConfirmationDialog3,
    ConfirmationDialog4,
    ConfirmationDialog5,
    ConfirmationDialog6,
    ContentpublisherComponent,
    SearchyearfilterPipe,
    SearchreportfilterPipe,
    SavesearchDialog,
    DeleteconfirmationDialog,
    MydashboardComponent,
    WhatsnewComponent,
    DataviewsComponent,
    KeysPipe,
    FilteruniquePipe,
    HighchartsComponent,
    FilterItemDirective,
    SavefilterDialog,
    FaqComponent,
    DatapublisherComponent,
    DatapublishertableComponent,
    MyservicesComponent,
    UserbatchComponent,
    LoginAdsComponent,
    OncologyComponent,
    WhatsNewComponent,
    ComingSoonComponent,
    LandingPageComponent,
    NewFeaturesComponent,
    FileDeleteComponent
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    LoginserviceService,
    DashSearchService,
    FilteruniquePipe,
    DataviewsService,
    DatapublishService,
    ServicesService,
    ProductService,
    UserRegistrationService,
    SharedDirective,
    ResetpasswordService,
    CognitoAccessToken,
    PublisherService,
    DashboardservicesService,
    {provide: SAVER, useFactory: getSaver},
    CookieService ,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    SpinnerService
  ],
  entryComponents: [
    ConsentDialog,
    LoadFiltersDialog,
    SavefilterDialog,
    ConfirmationTimeoutDialog,
    ConfirmationDialog,
    ConfirmationDialog2,
    ConfirmationDialog3,
    ConfirmationDialog4,
    ConfirmationDialog5,
    ConfirmationDialog6,
    SavesearchDialog,
    DeleteconfirmationDialog,
    DataviewsComponent
  ]
})
export class AppModule {}
