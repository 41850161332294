<div class="flex-containerabout">
    <div class="flex-container3">
      <mat-card class="item3card mat-elevation-z5">
        <mat-card-header>
          
          <mat-card-title class="textcolor1">{{pageTitle}}</mat-card-title>
        </mat-card-header>
        <mat-card-content> 
       
      
          <!--    <form novalidate (ngSubmit)="login(loginForm)" #loginForm="ngForm" autocomplete="off" > -->
              <form novalidate #loginForm="ngForm" autocomplete="off" method="POST" action="#" role="form"> 

                  <fieldset>
                      <div class="form-group" 
                           [ngClass]="{'has-error': (verificationCodeVar.touched || 
                                                     verificationCodeVar.dirty) && 
                                                     !verificationCodeVar.valid }">
      
                                  <mat-form-field>
                              <input matInput class="form-control" 
                                      id="verificationCode" 
                                      type="verificationCode" 
                                      maxlength="50"
                                      placeholder="verification Code"
                                      required
                                      [(ngModel)] = "verificationCode"
                                      [ngModelOptions]="{standalone: true}"
                                      name="verificationCode"
                                      #verificationCodeVar="ngModel" />
                              <span class="help-block" *ngIf="(verificationCodeVar.touched ||
                                                               verificationCodeVar.dirty) &&
                                                               verificationCodeVar.errors">
    
                              </span>
                              </mat-form-field>
                         
                      </div>                
                      
                      <div class="form-group" 
                      [ngClass]="{'has-error': password.invalid && (password.dirty || password.touched) }">
                             <mat-form-field>
                         <input matInput 
                                 class="form-control" 
                                 name="password"
                                 id="password" 
                                 type="password"
                                 maxlength="25"
                                 placeholder="Password (required)"
                                 required
                                 [(ngModel)] = user.password
                                 #password="ngModel" />
                         <span class="bottom_error" *ngIf="password.invalid && (password.dirty || password.touched)">
                           
                         </span>
                         </mat-form-field>
                    
                 </div>   
                 
                 <div class="form-group" 
                 [ngClass]="{'has-error': passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched) }">
                        <mat-form-field>
                    <input matInput 
                            class="form-control" 
                            name="passwordConfirm"
                            id="passwordConfirm" 
                            type="password"
                            maxlength="25"
                            placeholder="Confirm Password (required)"
                            compare="password"
                            [(ngModel)] = user.passwordConfirm
                            #passwordConfirm="ngModel" />
                            <span class="bottom_error" *ngIf="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)">
                                <span class="bottom_error" *ngIf="passwordConfirm.errors['required']">   Password Confirmation is required</span>
                                <span class="bottom_error" *ngIf="passwordConfirm.errors['compare']">   Password Confirmation do Not Match</span>
                               </span>      
                    </mat-form-field>

               
            </div>                      
                      
                      <div class="form-group">
                          <div>
                              <span>
                                  <button mat-raised-button color="accent" (click)="onNext()" [disabled]="loginForm.invalid">
                                  Reset Password
                              </button>
                              </span>
                           </div>
                      </div>
                </fieldset>
    
                <div class='loginlinks'>
                  <a [routerLink]="['/login']">Login</a>
              </div>    
                       
              </form>

              <div class="has-error" style="text-align: center; font-weight: bold;color: red; padding-bottom:20px;" *ngIf="errorMessage">{{errorMessage}}</div>
           <!--   <a href="" class="pass-forgot">Forgot your password?</a> -->
        </mat-card-content>
      </mat-card>
      </div>
      </div>












