<script src="https://code.highcharts.com/highcharts.js"></script>
<script src="https://code.highcharts.com/modules/series-label.js"></script>
<script src="https://code.highcharts.com/modules/exporting.js"></script>
<script src="https://code.highcharts.com/modules/export-data.js"></script>
<!-- <script src="http://highcharts.github.io/export-csv/highcharts-export-csv.js"></script> -->
<script src="http://highcharts.github.io/export-csv/export-csv.js"></script>

<div class="data-full-container">
  <div class="data-container mat-elevation-z8">

    <div class="header">
      <mat-table class="height1" [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="title">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>
            <mat-form-field>
              <input matInput class="dataviewsplaceholder" #filter1 (keyup)="titleFilter($event.target.value)"
                placeholder="Title">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter1.value"
                (click)="filter1.value=''; resetFilter('','title');">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.title}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="service">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>
            <mat-form-field>
              <mat-select class="dataviewsplaceholder" #filter2 placeholder="Service"
                (selectionChange)="serviceFilter($event.value)" [(ngModel)]="selectedValue">
                <mat-option (click)="filter2.value=''; resetFilter('','service');">Clear</mat-option>
                <mat-option class="trunc" (keyup)="serviceFilter($event.target.value)"
                  *ngFor="let element of (dataSource.data | filterunique: 'service')" [value]="element.service">
                  {{ element.service }}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.service}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="year">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>
            <mat-form-field>
              <mat-select class="dataviewsplaceholder" #filter3 placeholder="Year"
                (selectionChange)="yearFilter($event.value)" [(ngModel)]="selectedValue">
                <mat-option (click)="filter3.value=''; resetFilter('','year');">Clear</mat-option>
                <mat-option class="trunc" *ngFor="let element of (dataSource.data | filterunique: 'year')"
                  [value]="element.year">{{ element.year }}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.year}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reportType">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>

            <mat-form-field>
              <mat-select class="dataviewsplaceholder" #filter4 placeholder="Report Type"
                (selectionChange)="reportFilter($event.value)" [(ngModel)]="selectedValue4">
                <mat-option (click)="filter4.value=''; resetFilter('','reportType');">Clear</mat-option>
                <mat-option class="trunc" *ngFor="let element of (dataSource.data | filterunique: 'reportType')"
                  [value]="element.reportType">{{ element.reportType }}</mat-option>
              </mat-select>
            </mat-form-field>

          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.reportType}}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="selected(row)" [class.selected]="row==selectedRow"
          *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>

      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>

    </div>
  </div>





  <!-- <div *ngIf="dataTables != null">
  <div  class="data-container2 mat-elevation-z8">
      <button class="rightfloat" mat-button [matMenuTriggerFor]="menu"><mat-icon >menu</mat-icon></button>
      <mat-menu #menu="matMenu" yPosition="below" xPosition="before" [disableRipple]="true" class="contextcsv">
      
            <input  #myControl [value]="(displayedColumns2 | json)" [value2]="(headarray)" type="hidden" />
            <button mat-menu-item class="whitefont" (click)="csvdownload(myControl.value2, myControl.value)">Download CSV</button>
            <div mat-menu-item mat-filter-item [disableRipple]="true" class="contextlimit">
              
                <div class="inputcontext1">
                  Limit Filter:<br>
                   
                        <div *ngFor="let directionbut of tasks">
                          <button  class="smallbutton" mat-raised-button (click)="enableDisableRule(directionbut)" >{{directionbut.status}}</button>
                          <input matInput class="numbersinput" placeholder="Rows#" [(ngModel)]='numbervalue' name="numbervalue"/>
                          <button  (click)="topten(numbervalue, directionbut.status)" class="smallbutton" [disabled]="toptenactive=='true'" mat-mini-fab >ok</button>
                          </div>
                        

              </div> </div>
            <button mat-menu-item  class="whitefont" (click)="saveFilterDialogOpen()">Save Search</button>
            <button mat-menu-item  class="whitefont" (click)="loadFilterDialogOpen()">Load Saved Settings</button>

            <button mat-menu-item  class="whitefont" *ngFor="let col of headarray  | slice:0:1; let i=index" (click)="clearAllColumns()">Reset</button>
         
    
       
         
        </mat-menu>
      

        


  <div class="header">
      <mat-table class="height1" [dataSource]="dataTables" matSort  cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" >

        
        <div [matColumnDef]="col" *ngFor="let col of headarray"   >
         
          <mat-header-cell [id]="col" class="sortx moveleft" *matHeaderCellDef  mat-sort-header arrowPosition="before" (click)="savesort(col)" aria-sort cdkDrag>
             
              <button class="removebutton" mat-icon-button (click)="removeColumn(col)" color="warn"><mat-icon >remove_circle</mat-icon></button> 
              <br>
             
            <div class="headstylehead">  {{ col }} </div>

            <mat-menu #menu2>
                <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                    {{ col }} 
                  </div> 
                  <div mat-menu-item mat-filter-item [disableRipple]="true">
                      <mat-form-field>
                        <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions' [(value)]='searchCondition[col]'>
                          <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div mat-menu-item mat-filter-item [disableRipple]="true">
                        <mat-form-field>
                          <input matInput placeholder="Value" [(ngModel)]="searchValue[col]">
                        </mat-form-field>
                      </div>          
                      
                      <div mat-menu-item mat-filter-item [disableRipple]="true">
                          <button mat-raised-button (click)="clearColumn(col)">Clear</button>
                          <button mat-raised-button color="primary" (click)="applyFilter(col)">Search</button>
                  
                      </div>                 
            </mat-menu>
            
            <button (click)="$event.stopPropagation()" mat-button class="btn-togglemenu" [matMenuTriggerFor]="menu2">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

          
          </mat-header-cell>
          <ng-container *ngFor="let data of dataTables; let i = index"> 
           <ng-container *ngFor="let header of dataTables; let i2 = index"> 
          <mat-cell *matCellDef="let element" > 
             {{ element[col] }} </mat-cell>
            < </ng-container> 
           </ng-container> 
          </div>

        <mat-header-row *matHeaderRowDef="headarray" ></mat-header-row>
        <mat-row *matRowDef="let row; columns: headarray;" ></mat-row>
      </mat-table>
      </div>
      <mat-paginator #paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]"
      [showFirstLastButtons]="true">
      </mat-paginator>

      
              </div>
              
            </div> -->




  <!-- <div  class="data-container2 mat-elevation-z8">
                    <button class="rightfloat" matSort mat-button [matMenuTriggerFor]="menu"><mat-icon >menu</mat-icon></button>
                    <mat-menu #menu="matMenu" yPosition="below" xPosition="before" [disableRipple]="true" class="contextcsv">
                    
                        <input  #myControl [value]="(displayedColumns2 | json)" [value2]="(headarray)" type="hidden" />
                        <button mat-menu-item class="whitefont" (click)="csvdownload(myControl.value2, myControl.value)">Download CSV</button>
                        <div mat-menu-item mat-filter-item [disableRipple]="true" class="contextlimit">
                          
                            <div class="inputcontext1">
                              Limit Filter:<br>
                               
                                    <div *ngFor="let directionbut of tasks">
                                      <button  class="smallbutton" mat-raised-button (click)="enableDisableRule(directionbut)" >{{directionbut.status}}</button>
                                      <input matInput class="numbersinput" placeholder="Rows#" [(ngModel)]='numbervalue' name="numbervalue"/>
                                      <button  (click)="topten(numbervalue, directionbut.status)" class="smallbutton" [disabled]="toptenactive=='true'" mat-mini-fab >ok</button>
                                      </div>
                                    
                          </div> </div>
                        <button mat-menu-item  class="whitefont" (click)="saveFilterDialogOpen()">Save Search</button>
                        <button mat-menu-item  class="whitefont" (click)="loadFilterDialogOpen()">Load Saved Settings</button>
                        <button mat-menu-item  class="whitefont" *ngFor="let col of headarray  | slice:0:1; let i=index" (click)="clearAllColumns()">Reset</button>   
                    </mat-menu>
              
                    <div class="header">
                          <table #table class="height1 notmattable" width="100%" [dataSource]="datas" matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                              <tr>
                                <ng-container  *ngFor="let data of datas" >
                                  <th  [id]="data.title" class="sortx " cdkColumnDef="{{data.title}}" mat-sort-header arrowPosition="before" (click)="savesort(data.title)" aria-sort cdkDrag>{{ data.title }}
                                      <button class="removebutton" mat-icon-button (click)="removeColumn(data.title)" color="warn"><mat-icon >remove_circle</mat-icon></button>
                                      <mat-menu #menu2>
                                          <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                                              {{ data.title }} 
                                            </div> 
                                            <div mat-menu-item mat-filter-item [disableRipple]="true">
                                                <mat-form-field>
                                                  <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions' [(value)]='searchCondition[data.title]'>
                                                    <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                                                  </mat-select>
                                                </mat-form-field>
                                              </div>
                          
                                              <div mat-menu-item mat-filter-item [disableRipple]="true">
                                                  <mat-form-field>
                                                    <input matInput placeholder="Value" [(ngModel)]="searchValue[data.title]">
                                                  </mat-form-field>
                                                </div>          
                                                
                                                <div mat-menu-item mat-filter-item [disableRipple]="true">
                                                    <button mat-raised-button (click)="clearColumn(data.title)">Clear</button>
                                                    <button mat-raised-button color="primary" (click)="applyFilter(data.title)">Search</button>
                                            
                                                </div>                 
                                      </mat-menu>
                                      
                                      <button (click)="$event.stopPropagation()" mat-button class="btn-togglemenu" [matMenuTriggerFor]="menu2">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                      </button>
                                    </th>
                                </ng-container> 
                              </tr>
                            
                              <tr class="trrow" *ngFor="let data of displayedColumns2; let i = index">
                                <ng-container *ngFor="let col of datas; let i2 = index">
                                  <td >
                                  {{datasx[i2].data[i]}}
                                  </td>
                                </ng-container>
                            
                              </tr>
                              
                              <tr cdk-header-row *cdkHeaderRowDef="headarray" ></tr>
                              <tr cdk-row *cdkRowDef="let row; columns: headarray;"></tr>                              
                            </table>
              </div>
              <mat-paginator #paginator2
              
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 20]"
              [showFirstLastButtons]="true">
              </mat-paginator>
            </div> -->





  <div class="data-container2 mat-elevation-z8">
    <button class="rightfloat" matSort mat-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" yPosition="below" xPosition="before" [disableRipple]="true" class="contextcsv">

      <input #myControl [value]="(displayedColumns2 | json)" [value2]="(headarray)" type="hidden" />
      <!-- <button mat-menu-item class="whitefont" (click)="csvdownload(myControl.value2, myControl.value)">Download
        CSV</button> -->
      <a mat-menu-item class="whitefont"
        href="{{downloadCSVURL}}&filename={{filename}}.csv&jsonToConvert={{dataAsString}}" target='_blank'>Download
        CSV</a>
      <div mat-menu-item mat-filter-item [disableRipple]="true" class="contextlimit">

        <div class="inputcontext1">
          Limit Filter:<br>

          <div *ngFor="let directionbut of tasks">
            <button class="smallbutton" mat-raised-button
              (click)="enableDisableRule(directionbut)">{{directionbut.status}}</button>
            <input matInput class="numbersinput" placeholder="Rows#" [(ngModel)]='numbervalue' name="numbervalue" />
            <button (click)="topten(numbervalue, directionbut.status)" class="smallbutton"
              [disabled]="toptenactive=='true'" mat-mini-fab>ok</button>
          </div>

        </div>
      </div>
      <!-- <button mat-menu-item class="whitefont" (click)="saveFilterDialogOpen()">Save Search</button>
      <button mat-menu-item class="whitefont" (click)="loadFilterDialogOpen()">Load Saved Settings</button> -->
      <button mat-menu-item class="whitefont" *ngFor="let col of headarray  | slice:0:1; let i=index"
        (click)="clearAllColumns()">Reset</button>
    </mat-menu>
    <table width="100%" cdkDropList #table="cdkDropList" class="height1 notmattable" matSort
      cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
      <tr>
        <ng-container *ngFor="let data of datas">

          <th [id]="data.title" class="sortx " cdkColumnDef="{{data.title}}" mat-sort-header arrowPosition="before"
            (click)="savesort(data.title)" aria-sort cdkDrag matSort>
            <button class="removebutton" mat-icon-button (click)="removeColumn(data.title)" color="warn">
              <mat-icon>remove_circle</mat-icon>
            </button>
            <div class="headstylehead">{{ data.title }}</div>
            <mat-menu #menu2>
              <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                {{ data.title }}
              </div>
              <div mat-menu-item mat-filter-item [disableRipple]="true">
                <mat-form-field>
                  <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions'
                    [(value)]='searchCondition[data.title]'>
                    <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div mat-menu-item mat-filter-item [disableRipple]="true">
                <mat-form-field>
                  <input matInput placeholder="Value" [(ngModel)]="searchValue[data.title]">
                </mat-form-field>
              </div>

              <div mat-menu-item mat-filter-item [disableRipple]="true">
                <button mat-raised-button (click)="clearColumn(data.title)">Clear</button>
                <button mat-raised-button color="primary" (click)="applyFilter(data.title)">Search</button>

              </div>
            </mat-menu>

            <button (click)="$event.stopPropagation()" mat-button class="btn-togglemenu" [matMenuTriggerFor]="menu2">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

          </th>

        </ng-container>
      </tr>
      <tr class="trrow" *ngFor="let data of displayedColumns2; let row = index">
        <ng-container *ngFor="let header of datas; let col = index">
          <td>
            {{datas[col].data[row]}}
          </td>
        </ng-container>
      </tr>
    </table>
  </div>





</div>





<!-- 
<pre>headarray: {{headarray | json}}</pre>
<pre>tablearray: {{displayedColumns2 | json}}</pre> -->




<!--  <div *ngFor="let data of dataTables | async; let i = index" >
    <div>data jason: {{data}}</div>
    <div *ngIf="i == 0"><div *ngFor="let item of (data | keys)"> item key: {{ item.key }}</div></div>
   <div *ngFor="let item of (data | keys)"> item value: {{ item.value }}</div>
  </div> -->

<!-- <div class="full-left">
    <h2>Demo #2: Highstock with simple updates</h2>
  
    <div class="medium-right">
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="charts[usedIndex].hcOptions"
        [(update)]="updateDemo2"
        style="width: 100%; height: 350px; display: block;">
      </highcharts-chart>
    </div>
  </div> -->
