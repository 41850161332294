<div class="flex-container3">
  <mat-card class="item3card mat-elevation-z5">
    <mat-card-header>
      <mat-card-title class="textcolor1">{{pageTitle}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form novalidate class="form-signin" method="POST" role="form" #regForm="ngForm" autocomplete="off"
        enctype="multipart/form-data">

        <div class="form-group">
          <fieldset>

            <mat-form-field>
              <textarea matInput required id="tableTaxonomy" name="tableTaxonomy" [(ngModel)]="tableTaxonomy">
                  For All tables
                  {"objectType": "SMARTTABLE",
                    "promoteNow": true,
                    "promoteAllObjects": true,
                    "service": "TEST",
                    "year": "2019",
                    "reportType": "Asthma",
                    "schema": "hsgdevdatabase"}

                  For Specific Table
                  {"objectType": "SMARTTABLE",
                  "promoteNow": true,
                  "service": "TEST",
                  "year": "2019",
                  "reportType": "Rare",
                  "schema": "hsgdevdatabase",
                  "objectName": "Q10_HPS_PSB_Rating_Attributes_Patient_Services_Implementing_Providers_RARE"}
           </textarea>

            </mat-form-field>
          </fieldset>

          <div><br /><br />
            <button type="button" (click)="onPublish()" value="Publish" name="Publish" class="buttonToPublish"
              [disabled]="!regForm.valid">Publish
              Table</button>
            &nbsp;&nbsp;&nbsp;&nbsp;

            &nbsp;&nbsp;&nbsp;&nbsp;
            <button (click)="resetForm(regForm)"> Cancel </button>
            <br /><br />
          </div>
        </div>
      </form>

      <div class="has-error" style="text-align: center; font-weight: bold;color: red; padding-bottom:20px;"
        *ngIf="errorMessage">{{errorMessage}}</div>
    </mat-card-content>
  </mat-card>
</div>
