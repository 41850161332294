// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  sessionTime: 30,
  idleTime: 3570,
  region: "us-east-1",
  adminAccessKeyId: "XXXXXXXXXXXXXXXXXXXXXXXXXXX",
  adminSecretKeyId: "XXXXXXXXXXXXXXXXXXXXXXXXXXX",
  identityPoolId: "",
  poolData: {
    UserPoolId: "us-east-1_XaKooodhV", // Your user pool id here hsg
    ClientId: "46h514aev4l1vlgokpsqefgg43", // Your client id here  hsg
    Paranoia: 7,
  },
  rekognitionBucket: "",
  albumName: "",
  bucketRegion: "us-east-1",

  cognito_idp_endpoint: "",
  cognito_identity_endpoint: "",
  sts_endpoint: "",
  dynamodb_endpoint: "",
  s3_endpoint: "",
  data_promote: "DataPromoteFlagValue",
  //user_baseUrl: "https://dev-portal.healthstrategies.com/user-service",
  //product_service_baseUrl: "https://dev-portal.healthstrategies.com/product-service",

  user_baseUrl: "URLName/user-service",
  product_service_baseUrl: "URLName/product-service",
};
