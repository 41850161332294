import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs/Observable';
import { environment } from "../../environments/environment";
import * as AWS from 'aws-sdk';
import { forEach } from '@angular/router/src/utils/collection';
import { CookieService } from 'ngx-cookie-service';

// const poolData = {
//   UserPoolId: 'us-east-1_XaKooodhV', // Your user pool id here
//   ClientId: '46h514aev4l1vlgokpsqefgg43' // Your client id here  

// };
// const userPool = new CognitoUserPool(poolData);

const attributeList = [];


@Injectable()
export class AuthorizationService {
  cognitoUser: any;
  attributeList = [];
  sessionTime = environment.sessionTime
  idleTime = environment.idleTime
  poolData = environment.poolData
  userPool = new CognitoUserPool(this.poolData);
  //cookie: CookieService;

  //constructor(private CognitoIdToken: CognitoIdToken) { }
  constructor(private cookieService: CookieService) { }

  register(email, password) {

    const attributeList = [];

    return Observable.create(observer => {
      this.userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          console.log("signUp error", err);
          observer.error(err);
        }

        this.cognitoUser = result.user;
        console.log("signUp success", result);
        observer.next(result);
        observer.complete();
      });
    });

  }

  confirmAuthCode(code) {
    const user = {
      Username: this.cognitoUser.username,
      Pool: this.userPool
    };
    return Observable.create(observer => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmRegistration(code, true, function (err, result) {
        if (err) {
          console.log(err);
          observer.error(err);
        }
        console.log("confirmAuthCode() success", result);
        observer.next(result);
        observer.complete();
      });
    });
  }

  signIn(email, password) {

    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: this.userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return Observable.create(observer => {

      cognitoUser.authenticateUser(authenticationDetails, {
        
        onSuccess: (result) =>  {
          const payload: any = result.getAccessToken().getJwtToken();
          sessionStorage.setItem('token', payload);
          this.cookieService.set('token', payload);
          localStorage.setItem('token',payload);
          const idToken: any = result.getIdToken().getJwtToken();
          sessionStorage.setItem('idToken', idToken);
          this.cookieService.set('idToken', idToken);
          localStorage.setItem('idToken',idToken);
          const refreshToken: any = result.getRefreshToken().getToken()
          sessionStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('refreshToken',refreshToken);
          this.cookieService.set('refreshToken',refreshToken);
          console.log(payload);
          observer.next(result);
          observer.complete();
        },
        onFailure: function (err) {
          console.log(err);
          observer.error(err);
        },

        newPasswordRequired: () => {

          console.log(cognitoUser);
          console.log(cognitoUser.getAuthenticationFlowType(), 'YOU NEED TO CHANGE PASSWORD');
          const userData = {
            Username: cognitoUser.getUsername,
            Pool: this.poolData,
          };
          cognitoUser.completeNewPasswordChallenge(
            password,
            {},
            {
              onSuccess: (user) => {
                console.log('success', user);
              },
              onFailure: (error) => {
                console.log(error);
              },
            },
          );
        }
      });
    });
  }


  getAuthenticatedUser() {
    // gets the current user from the local storage
    return this.userPool.getCurrentUser();
  }

  isAuth() {
    const userData = {
      Username: sessionStorage.getItem('username') ? sessionStorage.getItem('username') : localStorage.getItem('username') ,
      Pool: this.userPool
    };
    const cognitoUser = new CognitoUser(userData);
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log('the error:' + err);
          return "invalidsession";
        } else {
          // return session.isValid() 
          alert('session validity: ' + session.isValid());
        }

      });
    }
  }


  refreshToken() {



    const userData = {
      Username: sessionStorage.getItem('username') ? sessionStorage.getItem('username') : localStorage.getItem('username') ,
      Pool: this.userPool
    };


    const cognitoUser = new CognitoUser(userData);
    // alert(cognitoUser)
    // cognitoUser.getSession(function (err, session) {
    //  let jwt = session.getJwtToken();
    // let refresh_token = session.getRefreshToken();
    //let jwt = sessionStorage.getItem('idToken');
    let jwt = sessionStorage.getItem('idtoken')? sessionStorage.getItem('idtoken') : localStorage.getItem('idtoken');
    // let refresh_token: any = sessionStorage.getItem('refreshToken');

    console.log(localStorage.getItem('idtoken'));
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          alert(err);
          return;
        }
        console.log('session validity: ' + session.isValid());
        console.log('refresh_token: ' + session.getRefreshToken().getToken());
        let refresh_token = session.getRefreshToken().getToken();
        // alert('session validity: ' + session.isValid());
        // alert('session getRefreshToken: ' + refresh_token);
        // AWS.Credentials.expiryWindow = 15


      });
    }

  }


  isLoggedIn() {
    return this.userPool.getCurrentUser() != null;
  }



  logOut() {
    this.getAuthenticatedUser().signOut();
    this.cognitoUser = null;

  }

  isAdmin() {
    let admin = false;
    let groups = sessionStorage.getItem("groups") ? sessionStorage.getItem("groups") : localStorage.getItem("groups");
    if (typeof groups != 'undefined' && groups) {
      var groupdata = JSON.parse(groups);
      for (var i = 0; i < groupdata.length; i++) {
        if (groupdata[i] == "admin") {
          admin = true;
        }
      }
      return admin;
    }
  }
}