import { Pipe, PipeTransform } from '@angular/core';
import { Servicesmodel } from './publishermodel';
import { ServiceYear } from './publishermodel';

@Pipe({
  name: 'searchreportfilter'
})
export class SearchreportfilterPipe implements PipeTransform {
  transform(reporType: ServiceYear[], term: string): any {
    // I am unsure what id is here. did you mean title?
    return reporType.filter(reporType => reporType.year.indexOf(term) !== -1);
}
}
