import { Component, OnInit, ViewChild } from "@angular/core";
import { MatAccordion } from "@angular/material";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"]
})
export class FaqComponent implements OnInit {
  panelOpenState = false;
  allExpandState = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor() {}

  ngOnInit() {}
}
