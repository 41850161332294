import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oncology',
  templateUrl: './oncology.component.html',
  styleUrls: ['./oncology.component.scss']
})
export class OncologyComponent implements OnInit {

  
  consumerProductSelectionTrendsLink =
    "https://www.eversana.com/products/health-strategies-insights/research/patient-access/consumer-product-selection-insights/";

  organizedCustomerAccountTrends ="https://www.eversana.com/products/health-strategies-insights/research/market-access/organized-customer-account-trends/";

  employerTrendsManagementDecision =
    "https://www.eversana.com/products/health-strategies-insights/research/market-access/employer-trends-and-management-decisions/";

  leadingPayerAccountTrends =
    "https://www.eversana.com/products/health-strategies-insights/research/market-access/leading-payer-account-trends/";

  medicalGrouptrends = "https://www.eversana.com/products/health-strategies-insights/research/market-access/medical-group-trends/";

  regionalAccountTrends =
    "https://www.eversana.com/products/health-strategies-insights/research/market-access/regional-account-trends/";
  constructor() { }

  ngOnInit() {
  }
  onAdClick(button: string) {
    if (button == "CPST") {
      window.open(this.consumerProductSelectionTrendsLink);
    } else if (button == "ETMD") {
      window.open(this.employerTrendsManagementDecision);
    } else if (button == "LPAT") {
      window.open(this.leadingPayerAccountTrends);
    } else if (button == "MGT") {
      window.open(this.medicalGrouptrends);
    } else if (button == "OCAT") {
      window.open(this.organizedCustomerAccountTrends);
    } else if (button == "RAT") {
      window.open(this.regionalAccountTrends);
    } 
  }
}
