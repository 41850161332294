<div class="container search">
  <button class="leftfloat" #menuTrigger="matMenuTrigger" mat-button [matMenuTriggerFor]="menu"
    (click)="oversearch()">Saved
    Searches <mat-icon class="arrow_drop_down">arrow_drop_down</mat-icon></button>

  <mat-menu class="savemenu1" #menu="matMenu" yPosition="below" xPosition="after" [disableRipple]="true">

    <div mat-menu-item mat-filter-item [disableRipple]="true" *ngFor="let saved of savedSearch | async"
      class="saveditem">
      <div class="iteminfo" (click)="searchservicewithparam(saved.searchText)" (click)="menuTrigger.closeMenu()">
        <div class="gray">{{saved.searchName}}</div>
        <div class="graysmaller">{{saved.searchText}}</div>
      </div>
      <div class="itemcontrol">
        <!-- <mat-icon color="primary" (click)="searchservicewithparam(saved.searchText)" class="iconwrap">check_box</mat-icon> -->
        <mat-icon color="warn" (click)="clear_delete(saved.searchName,saved.searchText)" class="iconwrap">
          indeterminate_check_box</mat-icon>
      </div>
    </div>

  </mat-menu>

  <mat-card class="searchexpand">
    <span>
      <form role="form" #search="ngForm">
        <div class="inputWithIconwrapper">
          <div class="inputWithIcon">
            <input class="search" [(NgModel)]="searchterm" [value]="searchterm"
              (input)="searchterm=$event.target.value">
            <i>
              <mat-icon (click)="openDialog()" class="saveiconbutton" matTooltipClass="tooltipy"
                matTooltip="Save This Search">save_alt</mat-icon>
            </i>

            <i class="buttonarea"><button mat-raised-button (click)="searchservice()" type="submit" class="btn btn-primary"
                color="accent">Search</button>
              <!-- <button mat-raised-button (click)="clearsearchterm()" type="submit" class="xsmall" color="warn">Reset</button> -->
            </i>
          </div>
        </div>

      </form>


    </span>


    <div *ngIf="(visibility==false)&&theSearch.length != 0">
      <div *ngFor="let toggleButton of subscribeButton">

        <mat-card-header>
          <mat-card-title *ngIf='(visibility==false)&&(searchterm)?.length != 0'>
            <span *ngIf="!isAdmin()">
              <div class="togglesubscribebutton">
                <span [class.togglelabel]="toggleButton.toggle==true"
                  [class.togglelabelhighlight]="toggleButton.toggle==false">
                  All Products </span>
                <mat-slide-toggle labelPosition="" mat-raised-button (click)="toggleSubscribeClicked(toggleButton)">
                </mat-slide-toggle>
                <span [class.togglelabel]="toggleButton.toggle==false"
                  [class.togglelabelhighlight]="toggleButton.toggle==true">
                  Subscribed Products</span>
              </div>
            </span>

          </mat-card-title>
        </mat-card-header>
      </div>
    </div>

  </mat-card>

  <div *ngIf="(visibility==false)">
    <div *ngFor="let toggleButton of subscribeButton">
      <div class="row">
        <!-- <div *ngIf='(theSearch)?.length != 0' class="topline "></div> -->
      </div><br><br>
      <div *ngIf="(searchterm)?.length != 0">
        <div class="resultslabel">Search Results Based On: "{{searchterm}}"</div>
        <div class="resultslabel" *ngIf="getSearchResultCount() > 0">
          <p>{{getSearchResultCount()}} products found.</p>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let dashsearchmodel of theSearch; let i = index">
          <div *ngIf="dashsearchmodel.purchased == true">
            <div class="moved">
              <div (click)="likeMe(i)" [class.favoritecolor2]="dashsearchmodel.favorite == false"
                [class.favoritecolor]="dashsearchmodel.favorite == true">
                <div class="topicon">
                  <a class="dontchangeme" matTooltip="Remove from Favorite" [matTooltipHideDelay]="hideDelay"
                    matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                      *ngIf="dashsearchmodel.favorite == true">
                      <mat-icon class="starpos">star</mat-icon>
                    </span> </a>
                  <a class="dontchangeme" matTooltip="Add to Favorite" [matTooltipHideDelay]="hideDelay"
                    matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                      *ngIf="dashsearchmodel.favorite == false">
                      <mat-icon class="starposdisabled">star_border</mat-icon>
                    </span></a>
                </div>
              </div>
              <div class="bottomicon">
                <div (click)="dashAdd(i)" [class.dashcolor]="dashsearchmodel.dashboard == false"
                  [class.dashcolor1]="dashsearchmodel.dashboard == true">
                  <a class="dontchangeme" matTooltip="Remove from Workspace" [matTooltipHideDelay]="hideDelay"
                    matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                      *ngIf="dashsearchmodel.dashboard == true">
                      <mat-icon *ngIf="hasHtml(dashsearchmodel.reportFiles)">dashboard</mat-icon>
                    </span> </a>
                  <a class="dontchangeme" matTooltip="Add to Workspace" [matTooltipHideDelay]="hideDelay"
                    matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                      *ngIf="dashsearchmodel.dashboard == false">
                      <mat-icon *ngIf="hasHtml(dashsearchmodel.reportFiles)">dashboard</mat-icon>
                    </span></a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="dashsearchmodel.purchased == false && toggleButton.toggle == false">
            <div class="moved-hidden">
              <div (click)="likeMe(i)" [class.favoritecolor2]="dashsearchmodel.favorite == false"
                [class.favoritecolor]="dashsearchmodel.favorite == true">
                <div class="topicon">
                  <a class="dontchangeme" matTooltip="Remove from Favorite" [matTooltipHideDelay]="hideDelay"
                    matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                      *ngIf="dashsearchmodel.favorite == true">
                      <mat-icon class="starpos">star</mat-icon>
                    </span> </a>
                  <a class="dontchangeme" matTooltip="Add to Favorite" [matTooltipHideDelay]="hideDelay"
                    matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                      *ngIf="dashsearchmodel.favorite == false">
                      <mat-icon class="starposdisabled">star_border</mat-icon>
                    </span></a>
                </div>
              </div>
              <div class="bottomicon-hidden" *ngIf="hasHtml(dashsearchmodel.reportFiles)">
                <div (click)="dashAdd(i)" [class.dashcolor]="dashsearchmodel.dashboard == false"
                  [class.dashcolor1]="dashsearchmodel.dashboard == true">
                  <a class="dontchangeme" matTooltip="Remove from Workspace" [matTooltipHideDelay]="hideDelay"
                    matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                      *ngIf="dashsearchmodel.dashboard == true">
                      <mat-icon *ngIf="hasHtml(dashsearchmodel.reportFiles)">dashboard</mat-icon>
                    </span> </a>
                  <a class="dontchangeme" matTooltip="Add to Workspace" [matTooltipHideDelay]="hideDelay"
                    matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                      *ngIf="dashsearchmodel.dashboard == false">
                      <mat-icon *ngIf="hasHtml(dashsearchmodel.reportFiles)">dashboard</mat-icon>
                    </span></a>
                </div>
              </div>
            </div>
          </div>

          <span *ngIf="dashsearchmodel.purchased == true || toggleButton.toggle == false">
            <div class="row reportresult">

              <div class="twofixed columns">
                <span *ngIf="(dashsearchmodel.purchased == false && toggleButton.toggle == false) ||
                            (dashsearchmodel.purchased == true && !hasHtml(dashsearchmodel.reportFiles))">
                  <span *ngIf="(dashsearchmodel.abstractStr)">
                    <div class="searchtitle1" matTooltip="{{dashsearchmodel.abstractStr}}"
                      [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg"
                      [matTooltipPosition]="position.value" target='_blank'>
                      {{dashsearchmodel.reportTypeFullName}}
                    </div>
                    <span class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</span>
                  </span>
                  <span *ngIf="!(dashsearchmodel.abstractStr)">
                    <div class="searchtitle1" target='_blank'>
                      {{dashsearchmodel.reportTypeFullName}}
                    </div><span class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</span>
                  </span>
                </span>
                <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index">
                  <span *ngIf="htmlnoext(reportFile.filename) && (reportFile.html == true)">
                    <span *ngIf="(dashsearchmodel.abstractStr)">

                      <a class="searchtitle1 linkcolor" matTooltip="{{dashsearchmodel.abstractStr}}"
                        [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg"
                        [matTooltipPosition]="position.value"
                        href="{{baseUrl2}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename}}&czip={{reportFile.czip}}"
                        target='_blank'>
                        {{dashsearchmodel.reportTypeFullName}}
                      </a>
                      <span class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</span>
                    </span>
                    <!-- </span> -->
                    <span *ngIf="!(dashsearchmodel.abstractStr)">

                      <a class="searchtitle1 linkcolor"
                        href="{{baseUrl2}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename}}&czip={{reportFile.czip}}"
                        target='_blank'>
                        {{dashsearchmodel.reportTypeFullName}}
                      </a><span class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</span>
                      <!-- </span> -->
                    </span>
                  </span>
                </span>
              </div>


              <div class="align">
                <span *ngIf="dashsearchmodel.purchased == true && 
                            ((dashsearchmodel.reportFiles.length == 1 && dashsearchmodel.reportFiles[0].html == false) ||
                            (dashsearchmodel.reportFiles.length > 1))">
                  <span class="graybordersy onefixed column center fsize comma "><b>Download:</b>
                    <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index">
                      <span *ngIf="reportFile">
                        <a class="linkcolor"
                          (click) ="downloadFile(getusename,dashsearchmodel.year,dashsearchmodel.service,dashsearchmodel.reportType,reportFile.filename,reportFile.czip)">{{extension(reportFile.filename)}}</a>

                      </span>
                    </span>
                  </span>
                </span>
                <span *ngIf="dashsearchmodel.purchased == false">
                  <span class="graybordersy onefixed column center fsize comma ">
                    <button mat-raised-button
                      (click)="forwardContactUs(dashsearchmodel.serviceFullName, dashsearchmodel.year,dashsearchmodel.reportTypeFullName)"
                      type="submit" class="small" color="accent">More Information</button>
                  </span>
                </span>
              </div>
              <div class="searchdescription">
                <span *ngIf="dashsearchmodel.purchased == true || toggleButton.toggle == false">
                  <!-- {{dashsearchmodel.description}} -->
                </span>
              </div>

            </div>
          </span>
        </div>
        <br /> <br />
      </div>
    </div>
  </div>
  <div *ngIf="visibility">
    <div class="centerloading">
      <mat-spinner class="spinner-margin" [color]="color" [mode]="mode" [value]="value"></mat-spinner>
    </div>
  </div>

  <!-- <div *ngIf='(theSearch)?.length === 0'>No results</div> -->
</div>
