import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'confirmation-dialog2',
  templateUrl: 'confirmation-dialog.html',
})
export class ConfirmationDialog2 {
  constructor(public dialogRef: MatDialogRef<ConfirmationDialog2>) {}
  public title:string;
  public confirmMessage:string;
}