import { Inject, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
//import {Http, Response} from "@angular/http";
import { Observable } from 'rxjs/Observable';
import { IServicesContactmodel } from '../models/servicesmodel';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/publishReplay';
import { map, startWith, publishReplay } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { CookieService } from 'ngx-cookie-service';
import { Http, ResponseContentType, RequestOptions } from '@angular/http';
import { Saver, SAVER } from '../saver.provider';
import { download } from '../download';




@Injectable()
export class ServicesService {

  tokenparam = 'JWT=Bearer ' + (sessionStorage.getItem('token') ? sessionStorage.getItem('token') : localStorage.getItem('token'));
  baseUrl: string = environment.product_service_baseUrl;

  getUserName(): string {
    let userName: string = sessionStorage.getItem('username') ? sessionStorage.getItem('username') : localStorage.getItem('username');
    const username: string = userName;
    return username;
  }

  constructor(private http: HttpClient, private cookie: CookieService,
    @Inject(SAVER) private save: Saver) { }

  public getMyServicesContact(): Observable<any> {
    let savedsearchURL1 = `${this.baseUrl}/product?`;
    return this.http.get(savedsearchURL1)
      .catch(this.errorHandler);
  }

  public getServicesContact(): Observable<any> {
    let savedsearchURL2 = `${this.baseUrl}/product`;
    return this.http.get(savedsearchURL2)
      .catch(this.errorHandler);
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error")
  }
 

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }

  download(url: string, filename?: string): Observable<any> {
    return this.http.get(url, {
      observe: 'events',
      responseType: 'blob'
    }).pipe(download(blob => this.save(blob, filename)))
  }


}