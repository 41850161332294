<div class="container text-center" style="padding: 5px 0px 10px;    margin-bottom: 25px;">
  <div  class=" text-center">

  <!--<strong>INTELLI</strong>CENTER<sup>&reg;</sup> <br /><br />-->
     <h3 class="subHeading" style="color: #9d9689; text-align: center; font-size: 1.25rem ! important ; margin-top: 5px ! important;">Explore New Offerings</h3>
  </div>
</div>

<div class="flex-container-ads" style="    margin-top: 0px;">
  <div class="login-ads">
    <mat-accordian multi>
      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title> <strong>ACCESS</strong>EXPRESS<span><sup>&copy;</sup></span> </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="description">
          The first self-service market research application that allows you to get real-time insights from hard-to-reach provider and payer experts directly to your inbox.


          <div>
            <button class="goto" (click)="onAdClick('accessExpress')">
              GO TO WEBSITE
              <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">input</mat-icon>
              <!-- <span class="iconposition"><mat-icon _ngcontent-c1="" aria-hidden="true" class="mat-icon material-icons notranslate mat-icon-no-color" role="img">star</mat-icon>               </span>
-->
            </button>
          </div>
        </div>
      </mat-expansion-panel>
      
      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title> <strong>SP</strong>OPTIMIZER </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="description">
          SPOPTIMIZER is the only objective online platform that ranks 90+ specialty pharmacy candidates targeted for current and future network needs based upon key service capabilities and organization attributes.

          <div>
            <button class="goto" (click)="onAdClick('spo')">
              GO TO WEBSITE
              <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">input</mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
      
      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Trends Suite
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="description">
          <p>Comprehensive solution that monitors and evaluates U.S. market access trends to anticipate environment shifts that affect access and reimbursement.</p>
          <p>Use these insights to inform and validate your market access strategies and tactics, identify current and future opportunities and risks for your brands, develop strategic account plans, and build programs that optimize patient access.</p>

          <div>
            <button class="goto" type="submit" [routerLink]="['/oncology']">
              Learn more
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordian>
  </div>
</div>
