import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { AuthService } from "./auth-user/auth.service";
import "rxjs/add/operator/do";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private authService: AuthService,
    private router: Router,
    private cookie: CookieService
  ) {
  }
  //function which will be called for all http calls

  //   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //     // get the token from a service
  //     const token: string = this.authService.getToken();
  //     // add it if we have one
  //     if (token) {
  //         req = req.clone({ headers: req.headers.set('jwt', 'Bearer ' + token) });
  //     }
  //     // if this is a login-request the header is
  //     // already set to x/www/formurl/encoded.
  //     // so if we already have a content-type, do not
  //     // set it, but if we don't have one, set it to
  //     // default --> json
  //     if (!req.headers.has('Content-Type')) {
  //         req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
  //     }
  //     // setting the accept header
  //     req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
  //     return next.handle(req);
  // }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    console.log("In error handler err.status--->" + err.status);
    //handle your auth error or rethrow
    //if (err.status === 401 || err.status === 403 || err.status === 0) {
    //navigate /delete cookies or whatever
    this.router.navigateByUrl(`/login`);
    localStorage.clear();
    sessionStorage.clear();
    localStorage.clear();
    location.reload();
    // location.replace("/login");
    this.router.navigate(["/login"]);
    // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
    return Observable.of(err.message);
    //}
    //return Observable.throw(err);
  }

  // intercept(req, next) {
  //   if (this.authService.isLoggedIn() != false) {
  //     let authService = this.injector.get(AuthService);
  //     let toquenizedReq = req.clone({
  //       headers: req.headers.set("jwt", `Bearer ${authService.getToken()}`)
  //     });
  //     return next.handle(toquenizedReq);
  //   } else {
  //     let authService = this.injector.get(AuthService);
  //     let toquenizedReq = req.clone({});
  //     return next.handle(toquenizedReq);
  //   }
  // }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    //console.log("In the intercept");
    const authReq = req.clone({
            headers: req.headers.set("JWT", `Bearer ${this.authService.getToken()}`)
          });
  
    // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
    return next.handle(authReq).catch(x => this.handleAuthError(x)); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
  }
}

// const newRequest = request.clone({
//   headers: request.headers.set("Authorization", "Bearer " + this.auth.getToken())
// });
// console.log("Before making api call : ", newRequest);
// return next
// .handle(newRequest).do(
//   success => console.log("success"),
//   error => {
//     if (error.status === 401){
//       console.error('YOU ARE NOT AUTHENTICATED.')
//     }
//   }
// );
