import { Component, OnInit } from '@angular/core';
import { PublisherService } from './publisher.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule, NgForm } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { SearchyearfilterPipe } from './searchyearfilter.pipe';
import { SearchreportfilterPipe } from './searchreportfilter.pipe';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-contentpublisher',
  templateUrl: './contentpublisher.component.html',
  styleUrls: ['./contentpublisher.component.scss']
})
export class ContentpublisherComponent implements OnInit {
  selectedFile: File = null;
  errorMessage: string;
  pageTitle: string = "Content Publisher"
  service: string;
  year: string;
  reportType: string;
  fileToUpload: any;
  userFilter: string;
  Servicesmodel = []
  ServiceYears = []
  years = []
  reportTypes = []
  file: any;
  fileList: any;
  chosenService = {}
  fileTypes = ["HTML", "PPTX", "PDF", "DATA", "ZIP"];
  fileType: String = "";

  selectedService = {};

  private baseUrl: string = environment.product_service_baseUrl;

  private _url: string = this.baseUrl;


  constructor(public _publisherService: PublisherService, private http: HttpClient, private router: Router) {

  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  onUpload() {
    this.verifyUpload(this.selectedFile, this.selectedFile.name, this.service, this.year, this.reportType, this.fileType);
  }

  verifyUpload(selectedFile, selectedFilename, service, year, reportType, fileType) {
    const fd = new FormData();

    fd.append('fileToUpload', selectedFile, selectedFile.name);
    fd.append('service', service);
    fd.append('year', year);
    fd.append('reportType', reportType);
    fd.append('fileType', fileType);

    const fileName: string = selectedFile.name;

    this._publisherService.uploadFile(fd);
  }

  resetForm(regForm: NgForm) {
    this.router.navigate(['/contentpublisher']);
    regForm.resetForm();
  }


  ngOnInit() {
    this.servicesmodel();
    this.serviceyear();
    this.errorMessage = null;
  }

  servicesmodel() {
    this._publisherService.getServiceModel()
      .subscribe(data => {
        this.Servicesmodel = data;
        console.log('Service in Publish', this.Servicesmodel);
      });
  }

  serviceyear() {
    this._publisherService.getServiceModel()
      .subscribe(data => this.Servicesmodel = data);
  }

  onSelectedChange(value: string) {
    // do something else with the value
    console.log(value);

    // rememberto update the selectedValue
    this.service = value;
  }

  // serviceyear(){
  //   this._publisherService.getServiceYear()
  //   .subscribe(data=>this.ServiceYears = data);
  // }

  // reporttype(){
  //   this._publisherService.getReportType()
  //   .subscribe(data=>this.ReportTypes = data);
  // }


  servicesChange(e) {
    console.log(e.target.value)
    this.Servicesmodel.filter(element => {
      if (element.service == e.target.value) {
        console.log(element.year[0], "first year")
        this.years = element.years;
      }
    });
  }

  yearChange(evt) {
    console.log(evt.target.value, this.year)
    this.years.filter(element => {
      if (element.name == evt.target.value) {
        this.reportTypes = element.reportTypes;
      }
    })
  }
}
