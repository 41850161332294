<div class="flex-container3">
  <mat-card class="item3card mat-elevation-z5">
    <mat-card-header>
      <mat-card-title class="textcolor1">{{pageTitle}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <form novalidate class="form-signin" method="POST" role="form" #regForm="ngForm" autocomplete="off"
        enctype="multipart/form-data">
        <div class="form-group">
          <fieldset>
            <mat-form-field>
              <mat-select placeholder="Service" type="text" id="service" name="service" [(ngModel)]="service"
                #groupname="ngModel" required>
                <mat-option *ngFor="let service of Servicesmodel; let j = index" [value]="service.family">
                  {{service.name}}
                  <span class="bottom_error" *ngIf="service.invalid && (service.dirty || service.touched)">
                  </span>
                </mat-option>
              </mat-select>

            </mat-form-field>


            <mat-form-field *ngIf="service">
              <mat-select placeholder="Year" type="text" id="year" name="year" [(ngModel)]="year" #groupname="ngModel"
                required>
                <div *ngFor="let service of Servicesmodel | SearchyearfilterPipe:service">
                  <mat-option *ngFor="let years of service.serviceYears" [value]="years.year">
                    {{years.year}}
                    <span class="bottom_error" *ngIf="years.invalid && (years.dirty || years.touched)">
                    </span>
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="year">
              <mat-select placeholder="Report Type" id="reportType" type="text" name="reportType"
                [(ngModel)]="reportType" (selectionChange)="onSelectedChange()" #groupname="ngModel" required>
                <div *ngFor="let service of Servicesmodel | SearchyearfilterPipe:service">
                  <div *ngFor="let years of service.serviceYears | searchreportfilter:year ">
                    <mat-option *ngFor="let reportTypes of years.reportTypes" [value]="reportTypes.name">
                      {{reportTypes.fullname}}
                      <span class="bottom_error"
                        *ngIf="reportTypes.invalid && (reportTypes.dirty || reportTypes.touched)">
                      </span>
                    </mat-option>

                  </div>
                </div>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="fileNameList.length">
              <mat-select placeholder="File Name" type="text" id="fileName" name="fileName" [(ngModel)]="fileName"
                #groupname="ngModel" required>
                <mat-option *ngFor="let fileName of fileNameList" [value]="fileName">
                  {{fileName}}
                </mat-option>
              </mat-select>

            </mat-form-field>
            <label *ngIf="!fileNameList.length && reportType">Files are not present under this folder.</label>
          </fieldset>

          <button *ngIf="fileNameList.length" type="button" (click)="onFileDelete(regForm)" value="Delete File"
            name="submit">Delete</button>
        </div>
      </form>

      <div class="has-error"
        style="text-align: left; font-weight: bold;color: red; padding-bottom:20px; padding-left:20px;"
        *ngIf="errorMessage">{{errorMessage}}</div>
    </mat-card-content>
  </mat-card>
</div>