<h1 mat-dialog-title>Save Filter Setting</h1>

<div mat-dialog-content style="color:#153961;"><b>{{confirmMessage}}</b></div>
<div mat-dialog-content>"{{confirmMessage2}}"</div><br>
<mat-form-field>
  <input matInput [(ngModel)]="data.filters" placeholder="Name your filter setting" required>
</mat-form-field>

<div mat-dialog-actions>
  <button mat-button style="cursor: pointer;color: #fff;background-color: #153961;" [mat-dialog-close]="data.filters" [disabled]="!data.filters">Save</button>
  <button mat-button (click)="dialogRef2.close(false)">Cancel</button>
</div>