import { Pipe, PipeTransform } from "@angular/core";
import { Servicesmodel } from "./publishermodel";
import { ServiceYear } from "./publishermodel";

@Pipe({
  name: "SearchyearfilterPipe"
})
export class SearchyearfilterPipe implements PipeTransform {
  transform(service: Servicesmodel[], term: string): any {
    //console.debug("term is---> " + term);
    //return service.filter(service => service.family.indexOf(term) !== -1);
    return service.filter(service => service.family === term);
  }
}
