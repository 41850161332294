import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'confirmation-dialog6',
  templateUrl: 'confirmation-dialog.html',
})

export class ConfirmationDialog6{
  constructor(public dialogRef: MatDialogRef<ConfirmationDialog6>) {}
  public title:string;
  public confirmMessage:string;
  public htmlContent: string;
}
