import { Injectable, OnChanges } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Servicesmodel } from "./publishermodel";
import { ServiceYear } from "./publishermodel";
import { ReportType } from "./publishermodel";
import { Element } from "../datapublishertable/element.model";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/publishReplay";
import { map, startWith, publishReplay } from "rxjs/operators";
import { environment } from "../../environments/environment";
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { ConfirmationDialog2 } from "../confirmation-dialog2/confirmation-dialog";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  NgForm,
} from "@angular/forms";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class PublisherService {
  dialogRef: MatDialogRef<ConfirmationDialog2>;
  router: Router;

  getSearchTerm(): string {
    let searchTerm: string = sessionStorage.getItem("searchterm") ? sessionStorage.getItem("searchterm") : localStorage.getItem("searchterm");
    let searchterm: string = searchTerm;
    return searchterm;
  }

  getUserName(): string {
    let userName: string = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username");
    const username: string = userName;
    return username;
  }

  tokenparam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token"));

  // getSearchTerm: string = this.getSearchTerm();
  // private _xurl:string = 'http://34.232.23.95/assets/searchsample2.json';

  private baseUrl: string = environment.product_service_baseUrl;
  private userUrl: string = environment.user_baseUrl;

  private _url: string = this.baseUrl + "/productList?" + this.tokenparam;
  private _url2: string = this.baseUrl + "/report?" + this.tokenparam;
  private fileListUrl = this.baseUrl + "/productFileList?" + this.tokenparam;
  private deleteURL = this.baseUrl + "/deleteFile?" + this.tokenparam;
  private userBatchUrl: string =
    this.userUrl + "/createbatch?" + this.tokenparam;
  private _publishTable: string =
    this.baseUrl + "/data/promote?" + this.tokenparam;
  private _publishTableList: string =
    this.baseUrl + "/data/promoteList?" + this.tokenparam;
  //  private _url:string = 'http://34.232.23.95:8181/product-service-0.1.0/';
  private _favoritesURL: string = this.baseUrl + "/favorite";
  //  private _yurl:string = 'http://34.232.23.95:8181/product-service-0.1.0/search?userId=jklein@entech.com&text=Management';
  private _yurl: string = this.baseUrl + "/search?text=" + this.getSearchTerm();

  //private _yurl:string = this.baseURL = this.search_service_baseUrl + 'favorite?userId='+this.getUserName()

  constructor(
    private http: HttpClient,
    router: Router,
    public dialog: MatDialog,
    private cookie: CookieService
  ) {
    this.router = router;
  }

  public getTheSearch(searchTerm: string): Observable<any> {
    let apiURL = `${this._url
      }search?userId=${this.getUserName()}&text=${searchTerm}`;
    return this.http.get(apiURL);
  }

  resetForm(regForm: NgForm) {
    this.router.navigate(["/contentpublisher"]);
    regForm.resetForm();
  }

  public uploadFile(fd) {
    this.http.post(this._url2, fd).subscribe(
      (res) => {
        console.log("File upload status: " + res);
      },
      (err) => {
        console.log("Error while uploading file. " + err);
      }
    );

    this.dialogRef = this.dialog.open(ConfirmationDialog2, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.title = "Upload Status";
    this.dialogRef.componentInstance.confirmMessage =
      "The file has been uploaded";
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //this.router.navigate(['/home']);
      }
      this.dialogRef = null;
    });
  }

  public uploadBatchFile(fd) {
    this.http.post(this.userBatchUrl, fd).subscribe(
      (res) => {
        console.log("File upload status: " + res);
      },
      (err) => {
        console.log("Error while uploading file. " + err);
      }
    );

    this.dialogRef = this.dialog.open(ConfirmationDialog2, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.title = "Upload Status";
    this.dialogRef.componentInstance.confirmMessage =
      "The file has been uploaded";
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //this.router.navigate(['/home']);
      }
      this.dialogRef = null;
    });
  }

  onPublish(tableJSON: string) {
    //const fd = new FormData();
    var errorFlag = true;
    var obj = null;
    try {
      obj = JSON.parse(tableJSON);
      console.log("onPublish.obj---->" + obj);
    } catch (objError) {
      if (objError instanceof SyntaxError) {
        console.log(objError.name);
        return;
      } else {
        console.log(objError.message);
      }
    }
    //fd.append("service", tableJSON);
    this.http.post(this._publishTable, obj).subscribe(
      (res) => {
        //console.log("success--->" + res);
        this.dialogRef.componentInstance.confirmMessage =
          "The table/s have been uploaded";
      },
      (err) => {
        //console.log("error----->" + err);
        this.dialogRef.componentInstance.confirmMessage =
          "The table/s have NOT been uploaded, contact ADMIN";
      }
    );

    this.dialogRef = this.dialog.open(ConfirmationDialog2, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.title = "Publish Status";
    // if (errorFlag)
    //   this.dialogRef.componentInstance.confirmMessage =
    //     "The table/s have NOT been uploaded, contact ADMIN";
    // else
    //   this.dialogRef.componentInstance.confirmMessage =
    //     "The table/s have been uploaded";
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //this.router.navigate(['/home']);
      }
      this.dialogRef = null;
    });
  }

  onPublishList(tableJSON: Element[]) {
    //const fd = new FormData();
    var errorFlag = true;
    var obj = null;
    try {
      obj = JSON.stringify(tableJSON);
      console.log("onPublishList.obj---->" + obj);
    } catch (objError) {
      if (objError instanceof SyntaxError) {
        console.log(objError.name);
        return;
      } else {
        console.log(objError.message);
      }
    }
    //fd.append("service", tableJSON);
    tableJSON.forEach((element) => {
      element.smartTable ? (element.objectType = "SMARTTABLE") : "SMARTVIEW";
    });
    this.http.post(this._publishTableList, tableJSON).subscribe(
      (res) => {
        //console.log("success--->" + res);
        this.dialogRef.componentInstance.confirmMessage =
          "The table/s have been uploaded";
      },
      (err) => {
        //console.log("error----->" + err);
        this.dialogRef.componentInstance.confirmMessage =
          "The table/s have NOT been uploaded, contact ADMIN";
      }
    );

    this.dialogRef = this.dialog.open(ConfirmationDialog2, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.title = "Publish Status";
    // if (errorFlag)
    //   this.dialogRef.componentInstance.confirmMessage =
    //     "The table/s have NOT been uploaded, contact ADMIN";
    // else
    //   this.dialogRef.componentInstance.confirmMessage =
    //     "The table/s have been uploaded";
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //this.router.navigate(['/home']);
      }
      this.dialogRef = null;
    });
  }

  private servicesModel: Observable<Servicesmodel[]> = this.http
    .get<Servicesmodel[]>(this._url)
    .catch(this.errorHandler)
    .publishReplay(1)
    .refCount();
  public getServiceModel(): Observable<Servicesmodel[]> {
    return this.servicesModel;
  }

  public serviceYear: Observable<ServiceYear[]> = this.http
    .get<ServiceYear[]>(this._url)
    .catch(this.errorHandler)
    .publishReplay(1)
    .refCount();
  public getServiceYear(): Observable<ServiceYear[]> {
    return this.serviceYear;
  }

  private reportType: Observable<ReportType[]> = this.http
    .get<ReportType[]>(this._url)
    .catch(this.errorHandler)
    .publishReplay(1)
    .refCount();
  public getReportType(): Observable<ReportType[]> {
    return this.reportType;
  }

  public getFileList(service, year, reportType) {
    let params = new HttpParams();
    params = params.append('service', service);
    params = params.append('year', year);
    params = params.append('reportType', reportType);
    return this.http.get(this.fileListUrl, { params })
  }
  public deleteFile(service, year, reportType, fileName) {
    const options = {
      service: service,
      year: year,
      reportType: reportType,
      filename: fileName
    }

    return this.http.post(this.deleteURL, options);
    
  }

  public getThefavtoshow(favupdate): Observable<any> {
    // let apiURL = `${this._url}favorite?userId=${this.getUserName()}`;
    return this.http.get(this._favoritesURL, favupdate);
  }

  updatefavoriteserv(favupdate): Observable<any> {
    return this.http.post(this._favoritesURL, favupdate);
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error");
  }
}
