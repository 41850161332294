<ng-container>
  <!-- <div *ngIf="visibility">
        <div class="centerloading">
          <mat-spinner class="spinner-margin" [color]="color" [mode]="modex" [value]="value"></mat-spinner>
        </div>
      </div> -->
  <mat-sidenav-container>
    <!-- <mat-sidenav [hidden]="openedvalue == false" (mouseleave)="sidenav.close()"
      *ngIf="!((getCaughtUp | async)  == '') || !((whatsNew | async) == '') || !((comingSoon | async) == '')"
      class="bar-only" #sidenav mode="over" opened="false" position="end">

      <div class="container">

        <br />

        <div class="row">
          <ng-container *ngIf="!((getCaughtUp | async) == '')">
            <mat-accordion>
              <mat-expansion-panel [expanded]="true" (opened)="panelOpenState1 = true"
                (closed)="panelOpenState1 = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h1 class="lightorange titlewide">
                      Get Caught Up
                    </h1>
                  </mat-panel-title>
                  <mat-panel-description>

                  </mat-panel-description>
                </mat-expansion-panel-header>


                <div class="row">


                  <div
                    *ngFor="let dashsearchmodel of getCaughtUp | FilterPipe: queryString | async | slice:0:1; let i = index">


                    <div class="row reportresult">

                      <div class="sidenavtwofixed columns">
                        <div class="moved_empty">
                          <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index">
                            <span *ngIf="htmlnoext(reportFile.filename) && (reportFile.html == false)">
                              <span *ngIf="(dashsearchmodel.abstractStr)">
                                <a class="nolink searchtitle" matTooltip="{{dashsearchmodel.abstractStr}}"
                                  [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg"
                                  [matTooltipPosition]="position.value">
                                  {{dashsearchmodel.reportTypeFullName}}
                                </a></span>
                              <span *ngIf="!(dashsearchmodel.abstractStr)">
                                <a class="nolink searchtitle">
                                  {{dashsearchmodel.reportTypeFullName}}
                                </a></span>

                            </span>
                          </span>

                          <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index">
                            <span *ngIf="htmlnoext(reportFile.filename) && (reportFile.html == true)">
                              <span *ngIf="(dashsearchmodel.abstractStr)">
                                <a class="searchtitle" matTooltip="{{dashsearchmodel.abstractStr}}"
                                  [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg"
                                  [matTooltipPosition]="position.value"
                                  href="{{baseUrl1}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename}}"
                                  target='_blank'>
                                  {{dashsearchmodel.reportTypeFullName}}
                                </a>
                              </span>
                              <span *ngIf="!(dashsearchmodel.abstractStr)">
                                <a class="searchtitle"
                                  href="{{baseUrl1}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename}}"
                                  target='_blank'>
                                  {{dashsearchmodel.reportTypeFullName}}
                                </a>
                              </span>
                            </span>
                          </span>

                          <div class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</div>
                        </div>
                      </div>


                      <ng-container *ngIf="((dashsearchmodel.description))">
                        <div class="searchdescription">{{dashsearchmodel.description}}</div>
                      </ng-container>

                      <ng-container *ngIf="((dashsearchmodel.description) == '')">
                        <div class="searchdescription2">{{dashsearchmodel.description}}</div>
                      </ng-container>
                    </div>
                    <div class="seemore" (click)="clickfortab(dashsearchmodel.reportTypeFullName)">More...</div>
                  </div>


                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <br />
          </ng-container>
          <ng-container *ngIf="!((whatsNew | async) == '')">
            <mat-accordion>
              <mat-expansion-panel [expanded]="true" (opened)="panelOpenState2 = true"
                (closed)="panelOpenState2 = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h1 class="lightorange titlewide">
                      What's New
                    </h1>
                  </mat-panel-title>
                  <mat-panel-description>

                  </mat-panel-description>
                </mat-expansion-panel-header>



                <div class="row">

                  <div
                    *ngFor="let dashsearchmodel of whatsNew | FilterPipe: queryString | async | slice:0:1; let i = index">

                    <div class="row reportresult">

                      <div class="sidenavtwofixed columns">
                        <div class="moved_empty">
                          <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index">
                            <span *ngIf="htmlnoext(reportFile.filename) && (reportFile.html == false)">
                              <span *ngIf="(dashsearchmodel.abstractStr)">
                                <a class="nolink searchtitle" matTooltip="{{dashsearchmodel.abstractStr}}"
                                  [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg"
                                  [matTooltipPosition]="position.value">
                                  {{dashsearchmodel.reportTypeFullName}}
                                </a></span>
                              <span *ngIf="!(dashsearchmodel.abstractStr)">
                                <a class="nolink searchtitle">
                                  {{dashsearchmodel.reportTypeFullName}}
                                </a></span>

                            </span>
                          </span>

                          <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index">
                            <span *ngIf="htmlnoext(reportFile.filename) && (reportFile.html == true)">
                              <span *ngIf="(dashsearchmodel.abstractStr)">
                                <a class="searchtitle" matTooltip="{{dashsearchmodel.abstractStr}}"
                                  [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg"
                                  [matTooltipPosition]="position.value"
                                  href="{{baseUrl1}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename}}"
                                  target='_blank'>
                                  {{dashsearchmodel.reportTypeFullName}}
                                </a>
                              </span>

                              <span *ngIf="!(dashsearchmodel.abstractStr)">

                                <a class="searchtitle"
                                  href="{{baseUrl1}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename}}"
                                  target='_blank'>
                                  {{dashsearchmodel.reportTypeFullName}}
                                </a>

                              </span>
                            </span>
                          </span>

                          <div class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</div>
                        </div>
                      </div>


                      <ng-container *ngIf="((dashsearchmodel.description))">
                        <div class="searchdescription">{{dashsearchmodel.description}}</div>
                      </ng-container>

                      <ng-container *ngIf="((dashsearchmodel.description) == '')">
                        <div class="searchdescription2">{{dashsearchmodel.description}}</div>
                      </ng-container>
                    </div>
                    <div class="seemore" (click)="clickfortab(dashsearchmodel.reportTypeFullName)">More...</div>
                  </div>

                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <br />
          </ng-container>
          <ng-container *ngIf="!((comingSoon | async) == '')">
            <mat-accordion>
              <mat-expansion-panel [expanded]="true" (opened)="panelOpenState3 = true"
                (closed)="panelOpenState3 = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h1 class="lightorange titlewide">
                      Coming Soon...
                    </h1>
                  </mat-panel-title>
                  <mat-panel-description>

                  </mat-panel-description>
                </mat-expansion-panel-header>


                <div class="row">


                  <div *ngFor="let zero of comingSoon | async | slice:0:1; let i = index">
                    <span *ngFor="let serviceYears of zero.serviceYears | slice:0:1; let i = index">
                      <span *ngFor="let reportFile of serviceYears.reportTypes | slice:0:1; let i = index">


                        <div class="row reportresult">


                          <div class="sidenavtwofixed columns">
                            <div class="moved_empty">
                              <span *ngIf="(reportFile.abstractStr)">
                                <a class="nolink searchtitle" matTooltip="{{reportFile.abstractStr}}"
                                  [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg"
                                  [matTooltipPosition]="position.value">
                                  {{reportFile.fullname}}
                                </a></span>
                              <span *ngIf="!(reportFile.abstractStr)">
                                <a class="nolink searchtitle">
                                  {{reportFile.fullname}}
                                </a></span>
                              <div class="smallfontagenda">{{reportFile.agendaMonth}} </div>

                            </div>

                            <div class="align">

                            </div>
                            <ng-container *ngIf="((reportFile.description))">
                              <div class="searchdescription">{{reportFile.description}}</div>
                            </ng-container>

                            <ng-container *ngIf="((reportFile.description) == '')">
                              <div class="searchdescription2">{{reportFile.description}}</div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="seemore" (click)="clickfortab(reportFile.fullname)">More...</div>

                      </span>

                    </span>

                  </div>


                </div>
              </mat-expansion-panel>
            </mat-accordion>


            <br /> <br />
          </ng-container>
        </div>

      </div>

    </mat-sidenav>-->

    <mat-sidenav-content>
      <!--<div *ngIf="((getCaughtUp | async) == null) || ((whatsNew | async) == null) || ((comingSoon | async) == null)"
        class="flex-top">
        <div (mouseenter)="showagain()">
          <div class="whatsnewlabel"> WHAT'S NEW </div>
        </div>
      </div> -->
      <!-- Cards here      -->

      <div class="container pt-4"  id="emptytext" *ngIf="emptyDashboard == true">
        <div class="row">
          <div class="col">
            <p class="h5 mrb-5 mat-card-title" style="margin-bottom: 12px;font-size:24px !important">Workspace</p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p>
              This page can be used to store your most frequently used items for
              quick access.<br /><br />Please use the
              <strong><i><a class="linkcolor" href="/#/search">search</a></i></strong>
              or
              <strong><i><a class="linkcolor" href="/#/myfavorites">favorites</a></i></strong>
              screen identify your most frequently used content<br /><br />and
              click the workspace icon to add it on this page.
            </p>
          </div>
        </div>
      </div>


      <div class="flex-container">
        <div>
          <div *ngIf="visibility">
            <mat-spinner class="spinner-margin" [color]="color" [mode]="mode" [value]="value"></mat-spinner>
          </div>
        </div>


        <!-- <div id="emptytext" *ngIf="emptyDashboard == true">
          <p>
            This page can be used to store your most frequently used items for
            quick access.<br /><br />Please use the
            <strong><i><a class="linkcolor" href="/#/search">search</a></i></strong>
            or
            <strong><i><a class="linkcolor" href="/#/myfavorites">favorites</a></i></strong>
            screen identify your most frequently used content<br /><br />and
            click the workspace icon to add it on this page.
          </p>
        </div> -->
        <ng-container *ngFor="
            let dashsearchmodel of (theSearch
              | FilterPipe: queryString
              | async);
            let i = index
          ">
          <ng-container *ngFor="
              let reportFile of dashsearchmodel.reportFiles;
              let i = index
            ">
            <div *ngIf="
                reportFile.html == true && dashsearchmodel.dashboard == true
              ">
              <button *ngIf="reportFile.html == true" class="cornerx" (click)="closedashboard(i, dashsearchmodel)">
                <!-- <span class="cornerlabel">Remove</span> -->
                <mat-icon aria-label="close" class="innercornerx">cancel</mat-icon>
              </button>
              <!-- <a  class='elev' href="{{baseUrl}}/download?userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{dashsearchmodel.name}}" target='_blank' > -->
              <a *ngIf="reportFile.html == true" class="elev" href="{{ baseUrl1 }}userId={{ getusename }}&service={{
                  dashsearchmodel.service
                }}&year={{ dashsearchmodel.year }}&reportType={{
                  dashsearchmodel.reportType
                }}&filename={{ reportFile.filename }}&czip={{reportFile.czip}}" target="_blank">
                <div class="box">
                  <mat-card (click)="isActive = !isActive" (clickOutside)="isActive = false" class="item1card"
                    [class.mat-elevation-z2]="!isActive" [class.mat-elevation-z20]="isActive">
                    <mat-card-header>
                      <div></div>

                      <mat-card-title class="textcolor1">{{
                        dashsearchmodel.reportTypeFullName
                      }}</mat-card-title>
                    </mat-card-header>
                    <img mat-card-image [src]="
                        '/portal/assets/' +
                        dashsearchmodel.service +
                        '/' +
                        dashsearchmodel.year +
                        '/' +
                        dashsearchmodel.reportType +
                        '/' +
                        'reportIcon.png'
                      " onError="this.src='/portal/assets/images/default3x2.png';" alt="Graphics" />
                    <mat-card-content>
                      <p class="abstractcard">
                        {{
                          getAndTruncateDescription(dashsearchmodel.description)
                        }}
                      </p>
                    </mat-card-content>
                  </mat-card>
                </div>
                <!-- </a>     -->
              </a>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <!-- </mat-drawer-content>

</mat-drawer-container> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
