import { Injectable, OnChanges } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Element } from "./element.model";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/publishReplay";
import { environment } from "../../environments/environment";
import { CookieService } from 'ngx-cookie-service';

export interface Everything {
  [key: string]: any;
}

export interface Everything2 {
  title?: string;
  data: [];
}

@Injectable()
export class DatapublishService {
  viewName: string;
  viewSchema: string;
  tokenparam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token")) ;
  private baseUrl: string = environment.product_service_baseUrl;
  private _dataviewsService: string =
    this.baseUrl + "/data/object?" + this.tokenparam;
  private _dataTableService: string = this.baseUrl + "/data/";

  constructor(private http: HttpClient, private cookie: CookieService) {
    // alert("dataviews called")
  }

  public getSmartViews(): Observable<Element[]> {
    return this.http.get<Element[]>(this._dataviewsService);
  }

  getSmartViews2() {
    return this.http.get(this._dataviewsService);
  }

  accessdatatable(smartObject): Observable<[Everything]> {
    this.viewSchema = smartObject.schema;
    this.viewName = smartObject.name;
    localStorage.setItem("smartobject", smartObject);
    localStorage.setItem("viewName", this.viewName);
    localStorage.setItem("viewSchema", this.viewSchema);
    sessionStorage.setItem("smartobject", smartObject);
    //localStorage.setItem("smartobject", smartObject);
    sessionStorage.setItem("viewName", this.viewName);
    //localStorage.setItem("viewName", this.viewName);
    sessionStorage.setItem("viewSchema", this.viewSchema);
    //localStorage.setItem("viewSchema", this.viewSchema);
    let viewName = sessionStorage.getItem("viewName") ? sessionStorage.getItem("viewName") : localStorage.getItem("viewName") ;
    let viewSchema = sessionStorage.getItem("viewSchema") ? sessionStorage.getItem("viewSchema") : localStorage.getItem("viewSchema") ;

    let dataTableService =
      this._dataTableService +
      viewSchema +
      "/" +
      viewName +
      "?" +
      this.tokenparam;

    console.log("smartObject=" + viewName);

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this.http.post<[Everything]>(dataTableService, smartObject, {
      headers: headers
    });
  }

  accessdatatable2(paramforurl): Observable<[Everything]> {
    let dataTableService =
      this._dataTableService + paramforurl + "?" + this.tokenparam;
    sessionStorage.removeItem("viewName");
    this.viewName = paramforurl;
    sessionStorage.setItem("viewName", this.viewName);
    localStorage.setItem("viewName", this.viewName);
    return this.http.get<[Everything]>(dataTableService);
  }
}
