import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from "../../environments/environment";
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {

  private baseUrl:string = environment.user_baseUrl;
  private baseUrlProduct:string = environment.product_service_baseUrl;
  constructor(private http: HttpClient, private cookie: CookieService) { }


  consentservice(username,consent): Observable<any> {
    let tokenparam = 'JWT=Bearer ' + (sessionStorage.getItem('token') ? sessionStorage.getItem('token') : localStorage.getItem('token')) ;
    let consentURL2 = `${this.baseUrl}/updateuserconsent?username=${username}&consent=${consent}&${tokenparam}`;
    return this.http.get(consentURL2,{responseType: 'text'});
  }
  
  public getMyServicesContact(): Observable<any> {
    let tokenparam = 'JWT=Bearer ' + (sessionStorage.getItem('token') ? sessionStorage.getItem('token') : localStorage.getItem('token')) ;
    let savedsearchURL1 = `${this.baseUrlProduct}/product?${tokenparam}`;
    return this.http.get(savedsearchURL1);
  }

}


