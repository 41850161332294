import { Injectable, OnChanges } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders
} from "@angular/common/http";
// import {Http, Headers, RequestOptions} from '@angular/http';
import { Observable } from "rxjs/Observable";
import { Idashsearchmodel } from "./dashsearchmodel";
import { newContent, comingSoon } from "./newcontentmodel";
// import { SaveSearchModel } from './savesearchmodel';
import { Ifilenames } from "./dashsearchmodel";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/publishReplay";
import { environment } from "../../environments/environment";
import { map, startWith, publishReplay } from "rxjs/operators";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { CookieService } from 'ngx-cookie-service';

class SaveSearchModel {
  constructor(
    public userId: string,
    public searchName: string,
    public searchText: string,
    public insertedBy: string,
    public insertDate: string,
    public modifiedBy: string,
    public modifiedDate: string
  ) {}
}

@Injectable()
export class DashSearchService {
  // private _xurl:string = '../../assets/savesearch.json';

  //  private _xurl:string = 'http://34.232.23.95/assets/savesearch.json';
  //  private _url:string = 'http://34.232.23.95:8181/product-service-0.1.0/';
  tokenparam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token") );

  private baseUrl: string = environment.product_service_baseUrl;

  private _url: string = this.baseUrl;

  private savedsearchURL: string =
    this.baseUrl + "/savedSearch?" + this.tokenparam;
  private _favoritesURL: string = this.baseUrl + "/favorite?" + this.tokenparam;
  private _dashboardURL: string =
    this.baseUrl + "/favorite/hasDashboard?" + this.tokenparam;
  private _newcontentURL: string =
    this.baseUrl + "/product/newContent?" + this.tokenparam;
  private xurl: string = "../../assets/whatsnew.json";
  private _yurl: string =
    this.baseUrl + "/search?text=" + this.getSearchTerm() + this.tokenparam;

  constructor(private http: HttpClient, private cookie: CookieService) {
    // alert("dashsearch called")
    this.results = [];
  }

  results: SaveSearchModel[];

  getSearchTerm(): string {
    let searchTerm: string = sessionStorage.getItem("searchterm") ? sessionStorage.getItem("searchterm") : localStorage.getItem("searchterm") ;
    let searchterm: string = searchTerm;
    return searchterm;
  }

  getUserName(): string {
    let userName: string = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username") ;
    const username: string = userName;
    return username;
  }

  private eventSubject = new BehaviorSubject<any>(undefined);

  triggerSomeEvent(param: any) {
    this.eventSubject.next(param);
  }
  getEventSubject(): BehaviorSubject<any> {
    return this.eventSubject;
  }

  //  getnewcontent() {
  //   return this.http.get(this._newcontentURL)
  //   .subscribe((res)=>{
  //     console.log(res);
  // });
  // }

  // getnewcontent() {
  //  this.http.get<newContent>(this._newcontentURL)
  //  .map((data:comingSoon)=> {console.log(data);
  // })

  // }

  getcomingSoon() {
    return this.http.get(this._newcontentURL).map((data: newContent) => {
      console.log(data.comingSoon);
      return data.comingSoon;
    });
  }

  getGetCaughtUp() {
    return this.http.get(this._newcontentURL).map((data: newContent) => {
      console.log(data.getCaughtUp);
      return data.getCaughtUp;
    });
  }

  getWhatsNew() {
    return this.http.get(this._newcontentURL).map((data: newContent) => {
      console.log(data.whatsNew);
      return data.whatsNew;
    });
  }

  public getTheSearch(searchTerm: string): Observable<any> {
    let apiURL = `${this._url}/search?text=${searchTerm}&${this.tokenparam}`;
    // let apiURL = `${this._url}/search`;
    return this.http.get(apiURL);
  }

  public hasDashboard(): Observable<boolean> {
    return this.http.get<boolean>(this._dashboardURL);
  }

  public getThefaves(): Observable<any> {
    return this.http.get(this._favoritesURL);
  }

  public getTheSavedSearch(): Observable<any> {
    let savedsearchURL1 = `${this._url}/savedSearch?${this.tokenparam}`;
    // let savedsearchURL1 = `${this._url}/savedSearch`;
    return this.http.get(savedsearchURL1).catch(this.errorHandler);
  }

  savesearchserv(savethis): Observable<any> {
    let savedsearchURL2 = `${this._url}/savedSearch?${this.tokenparam}`;
    // let savedsearchURL2 = `${this._url}/savedSearch`;
    return this.http.post(savedsearchURL2, savethis);
  }

  deleteSavesearch(searchName: string, searchText: string): Observable<any> {
    let savedsearchURL2 = `${
      this._url
    }/savedSearch?searchName=${searchName}&searchText=${searchText}&${
      this.tokenparam
    }`;
    return this.http.delete(savedsearchURL2);
  }

  public getThefavtoshow(favupdate): Observable<any> {
    let apiURL = `${this._url}/favorite`;
    return this.http.get(this._favoritesURL, favupdate);
  }

  public dashsearchmodel: Observable<Idashsearchmodel[]> = this.http
    .get<Idashsearchmodel[]>(this._yurl)
    .catch(this.errorHandler);
  public getTheFavorites(): Observable<Idashsearchmodel[]> {
    return this.dashsearchmodel;
  }

  public filenamesmodel: Observable<Ifilenames[]> = this.http
    .get<Ifilenames[]>(this._favoritesURL)
    .catch(this.errorHandler);
  public getfilenameFavorites(): Observable<Ifilenames[]> {
    return this.filenamesmodel;
  }

  updatefavoriteserv(favupdate): Observable<any> {
    return this.http.post(
      `${this._url}/favorite?${this.tokenparam}`,
      favupdate
    );
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error");
  }

  deletefavorite(str): Observable<any> {
    let body = str;
    const header: HttpHeaders = new HttpHeaders()
      .append("Content-Type", "application/json; charset=UTF-8")
      .append("JWT", "Bearer " + (sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : localStorage.getItem("accessToken")));
    const httpOptions = {
      headers: header,
      body: body
    };
    let favURL = `${this._url}/favorite?${this.tokenparam}`;
    return this.http.delete(favURL, httpOptions);
  }

  updatefavoriteservflat(favupdate): Observable<any> {
    return this.http.post(
      `${this._url}/favorite/flat?${this.tokenparam}`,
      favupdate
    );
  }

  deletefavoriteflat(str): Observable<any> {
    let body = str;
    const header: HttpHeaders = new HttpHeaders()
      .append("Content-Type", "application/json; charset=UTF-8")
      .append("JWT", "Bearer " + (sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : localStorage.getItem("accessToken")));
    const httpOptions = {
      headers: header,
      body: body
    };
    let favURL = `${this._url}/favorite/flat?${this.tokenparam}`;
    return this.http.delete(favURL, httpOptions);
  }

  onUpload(str): Observable<any> {
    let body = JSON.stringify(str);
    const header: HttpHeaders = new HttpHeaders()
      .append("Content-Type", "application/json; charset=UTF-8")
      .append("JWT", "Bearer " + (sessionStorage.getItem("accessToken") ? sessionStorage.getItem("accessToken") : localStorage.getItem("accessToken")));
    const httpOptions = {
      headers: header,
      body: body
    };
    let baseURL = `${this._url}/savedSearch`;
    return this.http.post(baseURL, httpOptions);
  }

  // onUpload(str):Observable<any>{
  //   let body = JSON.stringify(str);
  //   let headers = new Headers({ 'Content-Type': 'application/json' });
  //   let options = new RequestOptions({
  //     headers: headers,
  //     body : body
  //   });
  //   let baseURL = `${this._url}/savedSearch`;
  //   return this.http.post(baseURL, options)
  // }

  searchservice(): Observable<Idashsearchmodel[]> {
    //sessionStorage.removeItem('searchterm');
    let searchterm: string = sessionStorage.getItem("searchterm") ? sessionStorage.getItem("searchterm") : localStorage.getItem("searchterm");
    // let userId: string = sessionStorage.getItem('username');
    let params = new HttpParams().append("text", searchterm);
    return this.http.get<Idashsearchmodel[]>(
      this._url + "/search?" + this.tokenparam,
      { params }
    );
  }

  searchservicewithparam(searchterm): Observable<Idashsearchmodel[]> {
    //sessionStorage.removeItem('searchterm');
    sessionStorage.setItem("searchterm", searchterm);
    //localStorage.setItem("searchterm", searchterm);
    // let searchterm: string = sessionStorage.getItem('searchterm');
    // let userId: string = sessionStorage.getItem('username');
    let params = new HttpParams().append("text", searchterm);
    //Create new HttpParams
    return this.http.get<Idashsearchmodel[]>(
      this._url + "/search?" + this.tokenparam,
      { params }
    );
    // .subscribe(data=>this.theSearch = data);
    //   console.log('response from search');
    //   this.sentflag = true;
    //   sessionStorage.setItem('searchterm',searchterm);
    //  this.getTheSearch(searchterm)
  }

  
  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
}
