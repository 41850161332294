import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PublisherService } from '../contentpublisher/publisher.service';
import { environment } from "../../environments/environment";
import { NgForm } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { ConfirmationDialog2 } from "../confirmation-dialog2/confirmation-dialog";

@Component({
  selector: 'app-file-delete',
  templateUrl: './file-delete.component.html',
  styleUrls: ['./file-delete.component.scss']
})
export class FileDeleteComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmationDialog2>;
  selectedFile: File = null;
  errorMessage: string;
  pageTitle: string = "File Delete"
  service: string;
  year: string;
  reportType: string;
  fileToUpload: any;
  userFilter: string;
  Servicesmodel = []
  ServiceYears = []
  years = []
  reportTypes = []
  file: any;
  fileList: any;
  chosenService = {}
  fileTypes = ["HTML", "PPTX", "PDF", "DATA", "ZIP"];
  fileType: String = "";
  fileNameList: any = [];
  fileName: any;

  selectedService = {};
  private baseUrl: string = environment.product_service_baseUrl;
  constructor(public _publisherService: PublisherService,
    private http: HttpClient,
    private router: Router, public dialog: MatDialog,) {

  }

  resetForm(regForm: NgForm) {
    this.router.navigate(['/fileDelete']);
    regForm.resetForm();
  }


  ngOnInit() {
    this.servicesmodel();
    this.errorMessage = null;
  }

  servicesmodel() {
    this._publisherService.getServiceModel()
      .subscribe(data => {
        this.Servicesmodel = data;
        console.log('Service in Publish', this.Servicesmodel);
      });
  }

  onSelectedChange(value: string) {
    this._publisherService.getFileList(this.service, this.year, this.reportType).subscribe(res => {
      console.log('List of files', res);
      this.fileNameList = res;
    });
  }

  onFileDelete(regForm: NgForm) {
    this._publisherService.deleteFile(this.service, this.year, this.reportType, this.fileName)
    .subscribe(res =>{
      // location.reload();
    });
    this.dialogRef = this.dialog.open(ConfirmationDialog2, {
      disableClose: false,
    });
    this.dialogRef.componentInstance.title = "Delete Status";
    this.dialogRef.componentInstance.confirmMessage =
      "The file has been deleted";
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //this.fileNameList = [];
        //regForm.resetForm();
      }
      this.dialogRef = null;
    });
  }

}
