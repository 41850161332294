<h1 mat-dialog-title style="color: #565a5c;">Save This Search</h1>

<div mat-dialog-content style="color:#565a5c;"><b>{{confirmMessage}}</b></div>
<div mat-dialog-content>"{{confirmMessage2}}"</div><br>
<mat-form-field>
  <input matInput [(ngModel)]="data.searchName" placeholder="Enter Search Name" required>
</mat-form-field>

<div mat-dialog-actions>
  <button mat-button style="cursor: pointer;color: #fff;background-color: var(--color-primary);" [mat-dialog-close]="data.searchName" [disabled]="!data.searchName">Save</button>
  <button mat-button (click)="dialogRef2.close(false)">Cancel</button>
</div>