<div>
  <mat-slide-toggle labelPosition="" checked mat-raised-button (click)="toggleTreeClicked()">
  </mat-slide-toggle>
  <span class="togglelabelhighlight">
    Flat</span>
</div>
<div class="center">
  <mat-form-field style="align-items: center">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Service Filter">
  </mat-form-field>
</div>
<div class="content-container">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" fixed-header>

    <!-- Service Name Column -->
    <ng-container matColumnDef="addTo">
      <th mat-header-cell *matHeaderCellDef fixed-header class="customWidthaddTo"> Add to Fav/Dash </th>
      <td mat-cell *matCellDef="let products" class="customWidthaddTo">
        <div *ngIf="products.rtPurchased == true">
          <div class="moved">
            <div (click)="likeMe(dataSource.data.indexOf(products))" [class.favoritecolor2]="products.favorite != true"
              [class.favoritecolor]="products.favorite == true">
              <div class="topicon">
                <a class="dontchangeme" matTooltip="Remove from Favorite"><span *ngIf="products.favorite == true">
                    <mat-icon *ngIf="hasReport(products.reportFiles)" class="starpos">star</mat-icon>
                  </span> </a>
                <a class="dontchangeme" matTooltip="Add to Favorite"><span *ngIf="products.favorite != true">
                    <mat-icon *ngIf="hasReport(products.reportFiles)" class="starposdisabled">star_border</mat-icon>
                  </span></a>
              </div>
            </div>
            <div class="bottomicon">
              <div (click)="dashAdd(dataSource.data.indexOf(products))" [class.dashcolor]="products.dashboard != true"
                [class.dashcolor1]="products.dashboard == true">
                <a class="dontchangeme" matTooltip="Remove from Workspace"><span *ngIf="products.dashboard == true">
                    <mat-icon *ngIf="hasHtml(products.reportFiles)">dashboard</mat-icon>
                  </span> </a>
                <a class="dontchangeme" matTooltip="Add to Workspace"><span *ngIf="products.dashboard != true">
                    <mat-icon *ngIf="hasHtml(products.reportFiles)">dashboard</mat-icon>
                  </span></a>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Report name Column -->
    <ng-container matColumnDef="rtFullname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fixed-header class="customWidthreportname"> Report Name
      </th>
      <td mat-cell *matCellDef="let products" matTooltip="{{products.rtAbstractStr}}" class="customWidthreportname">
        <div *ngIf="!hasHtml(products.reportFiles)" class="textcolor"><i>
            {{products.rtFullname}}
          </i></div>
        <div *ngIf="products.reportFiles"><i>

            <div *ngFor="let reportFile of products.reportFiles" class="twofixedblue columns"><i>

                <div *ngIf="extensionhtmlt(reportFile.filename)">
                  <a class="linkcolor"
                    href="{{baseUrl1}}&userId={{getusename}}&service={{products.serviceFamily}}&year={{products.serviceYear}}&reportType={{products.rtName}}&filename={{reportFile.filename}}"
                    target='_blank'>{{products.rtFullname}}</a>
                </div>
              </i></div>
          </i></div>
      </td>
    </ng-container>

    <!-- Downloads Column -->
    <ng-container matColumnDef="downloads">
      <th mat-header-cell *matHeaderCellDef fixed-header mat-sort-header> Downloads </th>
      <td mat-cell *matCellDef="let products">
        <div *ngIf="products.rtPurchased == false">
          <span class="moreinfo">
            <button mat-raised-button
              (click)="forwardContactUs(products.serviceName, products.serviceYear, products.rtName)" type="submit"
              class="small" color="accent">LEARN MORE</button>
          </span>
        </div>
        <div *ngIf="!hasReport(products.reportFiles) && products.rtPurchased != false">
          <span class="moreinfo">
            <div>Report Not Available.</div>
          </span>
        </div>
        <div *ngIf="products.rtPurchased == true && (products.reportFiles)">
          <div *ngFor="let reportFile of products.reportFiles">
            <span><a class="linkcolor"
                href="{{baseUrl1}}&userId={{getusename}}&service={{products.serviceFamily}}&year={{products.serviceYear}}&reportType={{products.rtName}}&filename={{reportFile.filename}}"
                target='_blank'>{{extension(reportFile.filename)}}</a>

            </span>

            <!-- <div *ngIf="extensionhtmlt(reportFile.filename)">
              <a class="linkcolor"
                href="{{baseUrl1}}&userId={{getusename}}&service={{products.serviceFamily}}&year={{products.serviceyear}}&reportType={{products.rtFullname}}&filename={{reportFile.filename}}"
                target='_blank'>{{products.rtfullname}}</a>
            </div> -->
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Service year Column -->
    <ng-container matColumnDef="serviceYear" fixed-header>
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Service Year </th>
      <td mat-cell *matCellDef="let products"> {{products.serviceYear}} </td>
    </ng-container>

    <!-- Agenda month Column -->
    <ng-container matColumnDef="rtAgendaMonth" fixed-header>
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Month </th>
      <td mat-cell *matCellDef="let products"> {{products.rtAgendaMonth}} </td>
    </ng-container>

    <!-- Service Name Column -->
    <ng-container matColumnDef="serviceName" fixed-header>
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Service Name </th>
      <td mat-cell *matCellDef="let products" matTooltip="{{products.rtAbstractStr}}">
        {{products.serviceName}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="visibility">
    <div class="centerloading">
      <mat-spinner class="spinner-margin" [color]="color" [mode]="mode" [value]="value"></mat-spinner>
    </div>
  </div>
</div>
