<div *ngIf="visibility">
  <div class="toplayer">
    <div class="centerloading">
      <mat-spinner class="spinner-margin" [color]="color" [mode]="mode" [value]="value"></mat-spinner>
    </div>
  </div>
</div>


<div class="container" style="margin-bottom: 60px;padding: 20px 20px;">
  <div class="row">
    <div class="col-md-4 mrb-20">
      <div class="loginBox">

        <h3 class="text-primary mgb-30">Sign In</h3>
        <form novalidate method="POST" action="#" role="form" #loginForm="ngForm" class="mrb-30">
          <div class="form-group">
            

            <input matInput class="form-control" placeholder="Work Email" id="emailId" type="text" required [(ngModel)]="email" name="email"
              #emailVar="ngModel" />
          </div>

          <div class="form-group">
            
            <input matInput class="form-control" id="passwordId" placeholder="Password" type="password" required [(ngModel)]="password"
              name="password" #passwordVar="ngModel" />
          </div>

          <button class="btn btn-primary btn-block mrb-10" (click)="onLogin()" type="submit"
            [disabled]="!loginForm.form.valid">
            Sign In
          </button>


          <div class="loginlinks text-left">
            <a [routerLink]="['/forgotPassword']" style="color: #7f3f98;">Forgot Password?</a>
            <div class="halfspace">&nbsp;</div>
          </div>
        </form>

        <div class="has-error" style="
                text-align: center;
                font-weight: bold;
                color: red;
                padding-bottom: 20px;
              " *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </div>


      <a class="btn btn-primary login-contact-btn d-none d-sm-block" href="mailto:hsiclientservices@eversana.com?subject=HSI INTELLICENTER Question">contact us</a>



    </div>

    <!--Right Side of Page-->
    <div class="col-md-8">
      <p style="font-size: 22px; line-height: 24px;" class="d-none d-md-block">Welcome to <strong>INTELLI</strong>CENTER<sup style="font-size: 12px; top: -1em;">&reg;</sup></p>
      <p class="subTitle" class="d-none d-md-block" style="line-height: 24px; font-size: 16px;">The client portal for Health Strategies Insights by EVERSANA<sup>&trade;</sup></p>
      <p style="line-height: 24px; font-size: 16px;">
        Access, search, interact, and download the purchased insights from any location.
      </p>
      <p style="line-height: 24px; font-size: 16px;" class="text-bold d-none d-sm-block">Explore New Offerings</p>

      
      <p class="text-bold d-block d-sm-none">Check out our newly launched products!</p>
      <div class="row d-none d-sm-block">
        <div class="col-12 col-md-4 col-lg-4 mrb-10">
          <button class="btn btn-primary btn-block" (click)="onAdClick('accessExpress')">
            <strong style="font-weight: bolder;">ACCESS</strong><strong style="font-weight: lighter;">EXPRESS</strong><sup>&copy;</sup>
          </button>
        </div>
        <div class="col-12 col-md-8 col-lg-8">
          <p class="textRight">{{ acessExpressText }}</p>
        </div>
      </div>


      <div class="row d-none d-sm-block">
        <div class="col-12 col-md-4 col-lg-4 mrb-10">
          <button class="btn btn-primary btn-block" (click)="onAdClick('SPO')">
            <strong style="font-weight: bolder;">SP</strong><strong style="font-weight: lighter;">OPTIMIZER</strong>
          </button>
        </div>
        <div class="col-12 col-md-8 col-lg-8">
          <p class="textRight" style="margin-top: -3px; ! important">SPOPTIMIZER is the only objective online platform that ranks 90+ specialty pharmacy candidates targeted for current and future network needs based upon key service capabilities and organizational attributes.
          </p>
        </div>
      </div>

      
      <div class="row d-block d-sm-none">
        <div class="col-12">
          <button class="btn btn-secondary btn-block" routerLink="/loginAds">
            <strong style="font-weight: lighter;">Explore New Offerings</strong>
          </button>
        </div>
      </div>


      <div style="padding: 10px;border: 1px solid #dcdcdc;border-radius: 10px;" class="d-none d-md-block">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-4 mrb-10">
            <button class="btn btn-block" style="color: var(--color-primary);border-color: var(--color-primary);">
              <strong style="font-weight: 600; font-size: larger !important; text-transform: capitalize !important">Trends Suite</strong>
            </button>
          </div>
          <div class="col-12 col-md-8 col-lg-8">
            <p class="textRight">{{ oncologySuitsText1 }}</p>
            <p class="textRight">{{ oncologySuitsText2 }}</p>
          </div>
        </div>

        <div class="row justify-content-md-center">
          <div class="col col-6 mrb-10 col-lg-2">
            <button class="oncology-group" (click)="onAdClick('CPST')">
              <strong style="font-weight: 100;">Consumer Product Selection Trends</strong>
            </button>
          </div>
          <div class="col col-6 mrb-10 col-lg-2">
            <button class="oncology-group" (click)="onAdClick('ETMD')">
              <strong style="font-weight: 100;">Employer Trends and Management Decisions</strong>
            </button>
          </div>
          <div class="col col-6 mrb-10 col-lg-2">
            <button class="oncology-group" (click)="onAdClick('LPAT')">
              <strong style="font-weight: 100;">Leading Payer Account Trends</strong>
            </button>
          </div>

          <div class="col col-6 mrb-10 col-lg-2">
            <button class="oncology-group" (click)="onAdClick('MGT')">
              <strong style="font-weight: 100;">Medical <br> Group Trends</strong>
            </button>
          </div>

          <div class="col col-6 mrb-10 col-lg-2">
            <button class="oncology-group" (click)="onAdClick('OCAT')">
              <strong style="font-weight: 100;">Organized Customer Account Trends</strong>
            </button>
          </div>
          <div class="col col-6 mrb-10 col-lg-2">
            <button class="oncology-group" (click)="onAdClick('RAT')">
              <strong style="font-weight: 100;">Regional Account Trends</strong>
            </button>
          </div>
        </div>
      </div>

    </div>
    <!--End Right Side of Page-->

  </div>

</div>