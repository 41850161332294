<mat-card class="searchexpandfav">

  <mat-card-header>

    <mat-card-title>Favorites</mat-card-title>

  </mat-card-header>
</mat-card>
<div class="container fav">
  <div *ngIf="((theSearch | async) == '')" class="favoritesemptyarea">
    <p [innerHTML]=favoritesemptyarea></p>
  </div>

  <div class="bottomlayer2">
    <div class="row">
      <div class="topline "></div>
    </div>
    <div class="row">


      <div *ngFor="let dashsearchmodel of theSearch | FilterPipe: queryString | async; let i = index">
        <span *ngIf="dashsearchmodel.favorite == true">

          <div class="moved">
            <div (click)="likeMe(i,dashsearchmodel.favorite,dashsearchmodel)"
              [class.favoritecolor2]="dashsearchmodel.favorite == false"
              [class.favoritecolor]="dashsearchmodel.favorite == true">
              <div class="topicon">
                <a class="dontchangeme" matTooltip="Remove from Favorite" [matTooltipHideDelay]="hideDelay"
                  matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                    *ngIf="dashsearchmodel.favorite == true">
                    <mat-icon class="starpos">star</mat-icon>
                  </span></a>
                <a class="dontchangeme" matTooltip="Add to Favorite" [matTooltipHideDelay]="hideDelay"
                  matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                    *ngIf="dashsearchmodel.favorite == false">
                    <mat-icon class="starposdisabled">star_border</mat-icon>
                  </span></a>
              </div>
            </div>
            <div class="bottomicon" *ngIf="hasHtml(dashsearchmodel.reportFiles)">
              <div (click)="dashAdd(i,dashsearchmodel.dashboard,dashsearchmodel)"
                [class.dashcolor]="dashsearchmodel.dashboard == false"
                [class.dashcolor1]="dashsearchmodel.dashboard == true">
                <a class="dontchangeme" matTooltip="Remove from Workspace" [matTooltipHideDelay]="hideDelay"
                  matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                    *ngIf="dashsearchmodel.dashboard == true">
                    <mat-icon>dashboard</mat-icon>
                  </span> </a>
                <a class="dontchangeme" matTooltip="Add to Workspace" [matTooltipHideDelay]="hideDelay"
                  matTooltipClass="tooltipy" [matTooltipPosition]="position2.value"><span
                    *ngIf="dashsearchmodel.dashboard == false">
                    <mat-icon class="positx">dashboard</mat-icon>
                  </span></a>
              </div>
            </div>

          </div>

          <div class="row reportresult">

            <div class="twofixed columns">
              <span *ngIf="!hasHtml(dashsearchmodel.reportFiles)">
                <span *ngIf="(dashsearchmodel.abstractStr)">
                  <a class="nolink searchtitle" matTooltip="{{dashsearchmodel.abstractStr}}"
                    [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg" [matTooltipPosition]="position.value">
                    {{dashsearchmodel.reportTypeFullName}}
                  </a>
                  <span class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</span>
                </span>
                <span *ngIf="!(dashsearchmodel.abstractStr)">
                  <a class="nolink searchtitle">
                    {{dashsearchmodel.reportTypeFullName}}
                  </a>
                  <span class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</span>
                </span>

              </span>

              <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index">
                <span *ngIf="htmlnoext(reportFile.filename) && (reportFile.html == true)">
                  <span *ngIf="(dashsearchmodel.abstractStr)">
                    <a class="searchtitle linkcolor" matTooltip="{{dashsearchmodel.abstractStr}}"
                      [matTooltipHideDelay]="hideDelay" matTooltipClass="orangebkg"
                      [matTooltipPosition]="position.value"
                      href="{{baseUrl1}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename.replace('&','%26')}}&czip={{reportFile.czip}}"
                      target='_blank'>
                      {{dashsearchmodel.reportTypeFullName}}
                    </a>
                    <span class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</span>
                  </span>
                  <!-- </span> -->
                  <span *ngIf="!(dashsearchmodel.abstractStr)">
                    <!-- <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index"> -->
                    <!-- <span *ngIf="reportFile.html == true" > -->
                    <a class="searchtitle linkcolor"
                      href="{{baseUrl1}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename.replace('&','%26')}}&czip={{reportFile.czip}}"
                      target='_blank'>
                      {{dashsearchmodel.reportTypeFullName}}
                    </a>
                    <span class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</span>
                    <!-- </span> -->
                  </span>
                </span>
              </span>

              <!-- <div class="smallfontagenda">{{dashsearchmodel.agendaMonth}}</div>   -->
            </div>


            <div class="align">
              <span *ngIf="(dashsearchmodel.reportFiles.length == 1 && dashsearchmodel.reportFiles[0].html == false) ||
                                    (dashsearchmodel.reportFiles.length > 1)">
                <span class="graybordersy onefixed column center fsize comma "><b>Download:</b>
                  <span *ngFor="let reportFile of dashsearchmodel.reportFiles; let i = index">
                    <span *ngIf="reportFile">
                      <a class="linkcolor"
                        href="{{baseUrl1}}&userId={{getusename}}&service={{dashsearchmodel.service}}&year={{dashsearchmodel.year}}&reportType={{dashsearchmodel.reportType}}&filename={{reportFile.filename.replace('&','%26')}}&czip={{reportFile.czip}}"
                        target='_blank'>{{extension(reportFile.filename)}}</a>

                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div class="searchdescription">
              <!-- {{dashsearchmodel.description}} -->
            </div>

          </div>


        </span>
      </div>
      <br /> <br />
    </div>
  </div>
  <!-- <div *ngIf='(theSearch)?.length === 0'>No results</div> -->
</div>
