
<mat-sidenav-container fxFlexFill class="all-wrap" fullscreen>
  <mat-sidenav #sidenav fxLayout="column" text-align="right" [position]="'end'">
    <div fxLayout="column" *ngIf="isLoggedIn()">
      <div class="row" style="height: 40px;padding: 7px;">
        <div class="col-6 text-center">
          <img src="/portal/assets/images/msg.png" routerLink="/contactus" alt="Back" class="img-fluid"
            style="width: 30px;">
        </div>
        <div class="col-6 text-center">
          <img src="/portal/assets/images/House-outlined.png" routerLink="/menu" alt="Home" class="img-fluid"
            style="width: 30px;margin-left: 10px;">
        </div>
      </div>
      <a *ngIf="isLoggedIn()" routerLink="/products" routerLinkActive="active" (click)="sidenav.toggle()" mat-button
        class="leftbut">
        <!-- <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">collections_bookmark</mat-icon> -->
        Products
      </a>

      <a *ngIf="isLoggedIn()" routerLink="/justReleased" routerLinkActive="active" (click)="sidenav.toggle()" mat-button
        class="leftbut">
        Just Released
      </a>

      <a *ngIf="isLoggedIn()" routerLink="/comingSoon" routerLinkActive="active" (click)="sidenav.toggle()" mat-button
        class="leftbut">
        <!-- <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true"></mat-icon> -->
        Coming Soon
      </a>

      <a *ngIf="isLoggedIn()" routerLink="/loginAds" routerLinkActive="active" (click)="sidenav.toggle()" mat-button
        class="leftbut">
        <!-- <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true"></mat-icon> -->
        Explore New Offerings
      </a>

      <a *ngIf="isLoggedIn()" routerLink="/mydashboard" (click)="sidenav.toggle();spinnerx();" routerLinkActive="active"
        mat-button class="leftbut">
        <!-- <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">dashboard</mat-icon> -->
        Workspace
      </a>
      <a *ngIf="isLoggedIn()" routerLink="/myfavorites" routerLinkActive="active" mat-button class="leftbut"
        (click)="sidenav.toggle();">
        <!-- <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">star</mat-icon> -->
        Favorites
      </a>
      <a *ngIf="isLoggedIn()" routerLink="/search" routerLinkActive="active" mat-button class="leftbut"
        (click)="sidenav.toggle();">
        <!-- <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">search</mat-icon> -->
        Search
      </a>
      <a *ngIf="isLoggedIn() && cantsee(permissionToRegister)" routerLink="/register" routerLinkActive="active"
        (click)="sidenav.toggle()" mat-button class="leftbut">User Registration (Admin
        Only)</a>
      <a *ngIf="isLoggedIn() && cantsee(permissionToRegister) && getUserName()=='vsrinivasan@entech.com'"
        routerLink="/userBatch" routerLinkActive="active" (click)="sidenav.toggle()" mat-button class="leftbut">User
        Activity Batch (Admin
        Only)</a>
      <a *ngIf="isLoggedIn() && cantsee(permissionToRegister)" routerLink="/resetPassword" routerLinkActive="active"
        (click)="sidenav.toggle()" mat-button class="leftbut">User Management (Admin Only)</a>
      <a *ngIf="isLoggedIn() && cantsee(permissionToRegister)" routerLink="/contentpublisher" routerLinkActive="active"
        (click)="sidenav.toggle()" mat-button class="leftbut">Content Publisher (Admin
        Only)</a>
      <!-- <a *ngIf="authService.isLoggedIn() && !cantsee(permissionToRegister)"  [permission]='permissionToRegister' routerLink="/register" routerLinkActive="active" (click)="sidenav.toggle()" mat-button class="leftbut">Registration (Admin Only)</a>      -->
      <a routerLink="/contactus" routerLinkActive="active" (click)="sidenav.toggle()" mat-button class="leftbut">Contact
        Us</a>
      <a *ngIf="isLoggedIn()" routerLink="/faq" routerLinkActive="active" mat-button class="leftbut"
        (click)="sidenav.toggle();">FAQ</a>
      <!-- <a *ngIf="!authService.isLoggedIn()" routerLink="/login" routerLinkActive="active" (click)="sidenav.toggle()"
        mat-button class="leftbut">Login</a> -->
      <a *ngIf="isLoggedIn()" routerLink="/newPassword" routerLinkActive="active" mat-button class="leftbut"
        (click)="sidenav.toggle();">Change Your Password</a>
      <a *ngIf="isLoggedIn()" routerLinkActive="active" (click)="sidenav.toggle()" (click)="logOut()" mat-button
        class="leftbut">Log Out</a>
      <!-- <a *ngIf="authService.isLoggedIn()" href='./assets/docs/Health Strategies Group Web Portal User Guide.pdf' target='_blank'
        mat-button class="navmenucust">
        <mat-icon class="help">help_outline</mat-icon>
      </a> -->
    </div>
  </mat-sidenav>

  <div class="page-wrap">
    <header role="banner">
      <div class="middleheader">
        <img id="hsg_logo" joyrideStep="zeroStep" [stepContent]="welcomeContent" [src]=hsg_logo routerLink="/menu"
          alt="Graphics">
        <ng-template #welcomeContent>
          <strong>Welcome</strong>!<br>
          INTELLICENTER is a portal where clients can access their subscribed products and learn about additional
          offerings!
          Click on this logo to return to Home page.
        </ng-template>
        <div *ngIf="isLoggedIn()">
          <div fxShow="true" fxHide.gt-md="true"
            style="width: 30px;float: right;position: fixed;right: 14px;top: 20px;">
            <a (click)="sidenav.toggle()">
              <mat-icon style="font-size:24px; margin-top:5px; color:#565a5c">menu</mat-icon>
            </a>
          </div>
        </div>

        <div class="rightsidetop2" fxShow="true" fxHide.lt-lg="true">
          <div *ngIf="isLoggedIn()">
            <form class="headerform" role="form" #search="ngForm">
              <div class="inputWithIconwrapper2">

                <div class="inputWithIcon">
                  <a *ngIf="isLoggedIn()" routerLink="/faq" routerLinkActive="active" mat-button
                    class="navmenuheader">FAQ</a>
                  <a routerLink="/contactus" routerLinkActive="active" mat-button class="navmenuheader">Contact Us</a>
                  <input class="searchheader" joyrideStep="eigthStep" [stepContent]="searchContent"
                    [(NgModel)]="searchterm" [value]="searchterm" (input)="searchterm=$event.target.value">
                  <ng-template #searchContent>
                    Use the Search field to locate specific products. Enter a keyword in the search field then press the
                    Enter key. The results will display on the screen below. To the left of each product name are two
                    icons. Select the star icon to save the product to your Favorites page. Select the workspace icon to
                    save interactive products (displayed in a teal color) to your Workspace page.
                  </ng-template>
                  <i class="buttonarea2"><button class="hiddenx" type="submit" (click)="triggerAnEvent(searchterm)"
                      [routerLink]="['/search']">
                      <mat-icon class="mat-icon-small-search material-icons" role="img" aria-hidden="true"
                        color="accent" [routerLink]="['/search']">search</mat-icon>
                    </button>

                  </i>
                </div>

              </div>

              <div>
                <span class="loggeduser1" *ngIf="isLoggedIn()">
                  <button mat-button [matMenuTriggerFor]="menu" class="ngcontentcust">
                    <mat-icon inline=true>account_circle</mat-icon>&nbsp;Welcome
                    {{getTheUser()}}<mat-icon
                      class="arrow_drop_down mat-icon notranslate material-icons mat-icon-no-color" role="img"
                      aria-hidden="true">arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button *ngIf="cantsee(permissionToRegister)" mat-menu-item [routerLink]="['/register']">User
                      Registration</button>
                    <button *ngIf="cantsee(permissionToRegister)" mat-menu-item [routerLink]="['/resetPassword']">User
                      Management</button>
                    <button *ngIf="cantsee(permissionToRegister) && getUserName()=='vsrinivasan@entech.com'"
                      mat-menu-item [routerLink]="
                      ['/userBatch']">User Batch(You Only)</button>
                    <button *ngIf="cantsee(permissionToRegister)" mat-menu-item
                      [routerLink]="['/contentpublisher']">Content
                      Publisher </button>
                    <button *ngIf="cantsee(permissionToRegister)" mat-menu-item
                      [routerLink]="['/fileDelete']">File Delete
                    </button>
                    <div *ngIf="this.dataPromote"><button *ngIf="cantsee(permissionToRegister)" mat-menu-item
                        [routerLink]="['/datapublish']">Data Publisher </button></div>
                    <button mat-menu-item [routerLink]="['/newPassword']">Change Your Password</button>
                    <button mat-menu-item (click)="logOut()">
                      <span>Logout</span>
                    </button>
                  </mat-menu>
                </span>
              </div>

            </form>
          </div>

        </div>

      </div>

      <mat-toolbar *ngIf="isLoggedIn()" class="headerbar d-none d-sm-block" color="accent"
        style="width: 100%; height: 44px !important; margin: 0; padding: 0; border-bottom: 1px solid #e7e7e7;    border-top: 1px solid #e7e7e7;">
        <ng-container *ngIf="consent=='true'">
          <div fxShow="true" fxHide.lt-lg="true" style="width: 100%;">
            <a *ngIf="isLoggedIn()" joyrideStep="ninthStep" [stepContent]="favContent" routerLink="/myfavorites"
              routerLinkActive="active" mat-button style="font-size: 16px;" class="navmenucust">
              <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true" style="margin-right: 5px;">star
              </mat-icon>Favorites
            </a>
            <ng-template #favContent>
              Select Favorites in the status bar to view the products you have saved to your Favorites. Note: The
              Favorites page is unique to your login.
            </ng-template>
            <a *ngIf="isLoggedIn()" joyrideStep="tenthStep" [stepContent]="wspaceContent" routerLink="/mydashboard"
              (click)="spinnerx()" routerLinkActive="active" mat-button class="navmenucust" style="font-size: 16px;">
              <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true" style="margin-right: 5px;">
                dashboard</mat-icon>
              Workspace
            </a>
            <ng-template #wspaceContent>
              Select Workspace in the status bar to view the products you have saved to your Workspace. Note: The
              Workspace page is unique to your login.
            </ng-template>
            <!--<a *ngIf="isLoggedIn()" style="float: right;" (click)="btnTour_click()">Start Tour</a>-->


            <a joyrideStep="lastStep" [stepContent]="lastContent" *ngIf="isLoggedIn()" (click)="btnTour_click()"
              routerLinkActive="active" mat-button class="navmenucust FeatureBtn" style="color: var(--color-secondary) !important; float: right !important; margin-right: 31px;display: flex;
              justify-content: flex-end;">
              <img src="assets\images\Tour_icon.png" alt="" style="width: 20px;margin-right: 5px;">
              Quick Tour
            </a>
            <ng-template #lastContent>
              Thank you for taking a Quick Tour of INTELLICENTER.
            </ng-template>


          </div>
        </ng-container>
      </mat-toolbar>
    </header>

    <main class="content">
      <router-outlet></router-outlet>
    </main>
    <div class="clear"></div>
    <footer class="footer1">
      <div class="container" style="    padding-top: 10px;">
        <div class="row">
          <div class="col-6 col-lg-2 col-md-2 col-sm-4">
            <img src="/portal/assets/images/logo4.png" (click)="onEversanaLogoClick()" alt="EVERSANA" class="img-fluid">
            <span class="copyright d-block d-sm-none" style="font-size: 8px;margin-top: 6px;">
              &copy; {{currentYear}} EVERSANA. All Rights Reserved
            </span>
          </div>


          <div class="col-4 col-lg-8 col-md-8 text-center d-sm-block d-none col-sm-4"
            style="padding-top: 8px;font-size: 10px;">
            <!--<span>
              <mat-icon *ngIf="!(currentRoute == '/' || currentRoute == '/login')" aria-hidden="true" onclick="location.href='/'" class="back-icon mat-icon material-icons" role="img">arrow_back</mat-icon>
            </span>-->
            <span class="copyright">
              &copy; {{currentYear}} EVERSANA. All Rights Reserved
            </span>
          </div>

          <div class="col-6 col-lg-2 col-md-2 text-right col-sm-4">
            <img *ngIf="currentRoute!='/login' && currentRoute!='/'" src="/portal/assets/images/Back_Arrow.png" joyrideStep="fourthStep" [stepContent]="backContent"
              (click)="btnBack_click()" alt="Back" class="img-fluid" style="width: 30px;">
            <ng-template #backContent>
              Click on the back-arrow icon to return to the previous page.
            </ng-template>

            
            <img *ngIf="currentRoute!='/menu' && currentRoute!='/login' && currentRoute!='/' " src="/portal/assets/images/House-outlined.png" joyrideStep="fifthStep" [stepContent]="hmeContent"
              routerLink="/menu" alt="Home" class="img-fluid" style="width: 30px;margin-left: 10px;">
            <ng-template #hmeContent>
              Click on the home icon to return to the Home page.
            </ng-template>
           
          </div>
        </div>

      </div>
      <div class="footer-container2">
      </div>
    </footer>
  </div>
</mat-sidenav-container>