<div class="flex-container3">
  <mat-card class="item3card mat-elevation-z5">
    <mat-card-header>
      <mat-card-title class="textcolor1">{{pageTitle}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <form novalidate class="form-signin" method="POST" action="#" role="form" #regForm="ngForm" autocomplete="off">
        <fieldset>
          <div class="form-group" [ngClass]="{'has-error': (email.touched || email.dirty) && !email.valid }">
            <mat-form-field>
              <input matInput class="form-control" id="email" type="text" maxlength="50" minlength="3" placeholder="email (required)" required
                email [(ngModel)]="registrationUser.email" name="email" #email="ngModel" />
              <span class="bottom_error" *ngIf="(email.touched || email.dirty) && email.errors">
              </span>
            </mat-form-field>
          </div>
          <div class="form-group" [ngClass]="{'has-error': (firstnameInput.touched || firstnameInput.dirty) && !firstnameInput.valid }">
            <mat-form-field>
              <input matInput class="form-control" id="firstname" type="text" maxlength="50" minlength="1" placeholder="Firstname (required)" required
                [(ngModel)]="registrationUser.name" name="name" #firstnameInput="ngModel" />
              <span class="bottom_error" *ngIf="(firstnameInput.touched || firstnameInput.dirty) && firstnameInput.errors">
              </span>
            </mat-form-field>
          </div>
          <div class="form-group" [ngClass]="{'has-error': (family_name.touched || family_name.dirty) && !family_name.valid }">
            <mat-form-field>
              <input matInput class="form-control" id="family_name" type="text" maxlength="50" minlength="1" placeholder="Lastname (required)" required
                [(ngModel)]="registrationUser.family_name" name="family_name" #family_name="ngModel" />
              <span class="bottom_error" *ngIf="(family_name.touched || family_name.dirty) && family_name.errors">
              </span>
            </mat-form-field>
          </div>
          <mat-form-field>
          <mat-select class="form-control" placeholder="Groups" id="groupname" type="text" name="groupname" [(ngModel)]="registrationUser.groupname"  #groupname="ngModel" multiple>
            <mat-option *ngFor="let group of listGroups"  [value]="group">{{group}}</mat-option>
          </mat-select>             
          </mat-form-field>
          <div class="form-group" [ngClass]="{'has-error': (phone_number.touched || phone_number.dirty) && !phone_number.valid }">
            <mat-form-field>
              <input matInput class="form-control" id="phone_number" type="text" maxlength="50" minlength="3" placeholder="Phone Number (Optional)"
                [(ngModel)]="registrationUser.phone_number" name="phone_number" #phone_number="ngModel" />
              <span class="bottom_error" *ngIf="(phone_number.touched || phone_number.dirty) && phone_number.errors">
              </span>
            </mat-form-field>
          </div>
          <div class="form-group" [ngClass]="{'has-error': (companyName.touched || companyName.dirty) && !companyName.valid }">
            <mat-form-field>
              <input matInput class="form-control" id="companyname" type="text" maxlength="50" minlength="3" placeholder="Company Name (required)" required
                [(ngModel)]="registrationUser.companyName" name="companyname" #companyName="ngModel" />
              <span class="bottom_error" *ngIf="(companyName.touched || companyName.dirty) && companyName.errors">
              </span>
            </mat-form-field>
          </div>
          <div class="form-group" [ngClass]="{'has-error': password.invalid && (password.dirty || password.touched) }">
            <mat-form-field>
              <input matInput class="form-control" name="password" id="password" type="password" maxlength="25" placeholder="Password (required)"
                required [(ngModel)]=registrationUser.password #password="ngModel" />
              <span class="bottom_error" *ngIf="password.invalid && (password.dirty || password.touched)">
              </span>
            </mat-form-field>
          </div>
          <div class="form-group" [ngClass]="{'has-error': passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched) }">
            <mat-form-field>
              <input matInput class="form-control" name="passwordConfirm" id="passwordConfirm" type="password" maxlength="25" placeholder="Confirm Password (required)"
                required compare="password" [(ngModel)]=registrationUser.passwordConfirm #passwordConfirm="ngModel" />
              <span class="bottom_error" *ngIf="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)">
                <span class="bottom_error" *ngIf="passwordConfirm.errors['compare']"> Password Confirmation do Not Match</span>
              </span>
            </mat-form-field>
          </div>
          <div class="form-group">
            <div>
              <span>
                <button mat-raised-button color="accent" (click)="onRegister()" id="signupSubmit" type="submit" style="width:80px;margin-right:10px"
                  [disabled]="!regForm.valid">
                  Sign Up
                </button>
              </span>
              <span>
                <button mat-raised-button color="accent" (click)="resetForm(regForm)">
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </fieldset>
      </form>
      <div class="has-error" style="text-align: center; font-weight: bold;color: red; padding-bottom:20px;" *ngIf="errorMessage">{{errorMessage}}</div>
    </mat-card-content>
  </mat-card>
</div>

<!-- 
<pre>
    form status: {{regForm.status}}
</pre>
<pre>
    form values: {{regForm.value | json}}
</pre> -->