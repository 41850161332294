<script src="https://code.highcharts.com/highcharts.js"></script>
<script src="https://code.highcharts.com/modules/series-label.js"></script>
<script src="https://code.highcharts.com/modules/exporting.js"></script>
<script src="https://code.highcharts.com/modules/export-data.js"></script>
<script src="http://highcharts.github.io/export-csv/export-csv.js"></script>

<div class="data-full-container">
  <div class="data-container mat-elevation-z8">

    <div class="header">
      <mat-table class="height1" [dataSource]="dataSource" matSort>
        <!-- <ng-container matColumnDef="checked">
          <mat-header-cell class="moveleft" *matHeaderCellDef>Check</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox [(ngModel)]="element.checked"></mat-checkbox>
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="checked">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">To
              Publish
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation();doPublishNow(row)"
              (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="promoteNow">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">Promote Now
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
          <mat-header-cell class="moveleft" *matHeaderCellDef>
            <br>
            <mat-form-field>
              <input matInput class="dataviewsplaceholder" #filter1 placeholder="Title" [formControl]="titleFormFilter">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter1.value"
                (click)="filter1.value=''; resetFilter('','title');">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.title}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="service">
          <mat-header-cell class="moveleft" *matHeaderCellDef>
            <br>
            <mat-form-field>
              <input matInput class="dataviewsplaceholder" #filter2 placeholder="Service"
                [formControl]="serviceFormFilter">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter2.value"
                (click)="filter2.value=''; resetFilter('','service');">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.service}} </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="year">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>
            <mat-form-field>
              <mat-select class="dataviewsplaceholder" #filter3 placeholder="Year"
                (selectionChange)="yearFilter($event.value)" [formControl]="yearFormFilter">
                <mat-option (click)="filter3.value=''; resetFilter('','year');">Clear</mat-option>
                <mat-option class="trunc" *ngFor="let element of (dataSource.data | filterunique: 'year')"
                  [value]="element.year">{{ element.year }}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.year}} </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="year">
          <mat-header-cell class="moveleft" *matHeaderCellDef>
            <br>
            <mat-form-field>
              <input matInput class="dataviewsplaceholder" #filter3 placeholder="Year" [formControl]="yearFormFilter">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter3.value"
                (click)="filter3.value=''; resetFilter('','year');">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.year}} </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="reportType">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>

            <mat-form-field>
              <mat-select class="dataviewsplaceholder" #filter4 placeholder="Report Type"
                (selectionChange)="reportFilter($event.value)" [formControl]="reporttypeFormFilter">
                <mat-option (click)="filter4.value=''; resetFilter('','reportType');">Clear</mat-option>
                <mat-option class="trunc" *ngFor="let element of (dataSource.data | filterunique: 'reportType')"
                  [value]="element.reportType">{{ element.reportType }}</mat-option>
              </mat-select>
            </mat-form-field>

          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.reportType}}
          </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="reportType">
          <mat-header-cell class="moveleft" *matHeaderCellDef>
            <br>
            <mat-form-field>
              <input matInput class="dataviewsplaceholder" #filter4 placeholder="reportType"
                [formControl]="reporttypeFormFilter">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter4.value"
                (click)="filter4.value=''; resetFilter('','reportType');">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.reportType}}
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="reportNumber">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>

            <mat-form-field>
              <mat-select class="dataviewsplaceholder" #filter4 placeholder="Report Number"
                (selectionChange)="reportNumberFilter($event.value)" [formControl]="reportnumberFormFilter">
                <mat-option (click)="filter4.value=''; resetFilter('','reportNumber');">Clear</mat-option>
                <mat-option class="trunc" *ngFor="let element of (dataSource.data | filterunique: 'reportNumber')"
                  [value]="element.reportNumber">{{ element.reportNumber }}</mat-option>
              </mat-select>
            </mat-form-field>

          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.reportNumber}}
          </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="reportNumber">
          <mat-header-cell class="moveleft" *matHeaderCellDef>
            <br>
            <mat-form-field>
              <input matInput class="dataviewsplaceholder" #filter5 placeholder="reportNumber"
                [formControl]="reportnumberFormFilter">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter5.value"
                (click)="filter5.value=''; resetFilter('','reportNumber');">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.reportNumber}}
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="updatedBy">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>

            <mat-form-field>
              <mat-select class="dataviewsplaceholder" #filter5 placeholder="Updated By"
                (selectionChange)="updatedByFilter($event.value)">
                <mat-option (click)="filter5.value=''; resetFilter('','updatedBy');">Clear</mat-option>
                <mat-option class="trunc" *ngFor="let element of (dataSource.data | filterunique: 'updatedBy')"
                  [value]="element.updatedBy">{{ element.updatedBy }}</mat-option>
              </mat-select>
            </mat-form-field>

          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.updatedBy}}
          </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="updatedBy">
          <mat-header-cell class="moveleft" *matHeaderCellDef>
            <br>
            <mat-form-field>
              <input matInput class="dataviewsplaceholder" #filter6 placeholder="updatedBy"
                [formControl]="updatedByFormFilter">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter6.value"
                (click)="filter6.value=''; resetFilter('','updatedBy');">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.updatedBy}}
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="updateTimeStamp">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>

            <mat-form-field>
              <mat-select class="dataviewsplaceholder" #filter6 placeholder="Updated Date"
                (selectionChange)="updateDateFilter($event.value)">
                <mat-option (click)="filter6.value=''; resetFilter('','updateTimeStamp');">Clear</mat-option>
                <mat-option class="trunc" *ngFor="let element of (dataSource.data | filterunique: 'updateTimeStamp')"
                  [value]="element.updateTimeStamp">{{ element.updateTimeStamp }}</mat-option>
              </mat-select>
            </mat-form-field>

          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.updateTimeStamp}}
          </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="updateTimeStamp">
          <mat-header-cell class="moveleft" *matHeaderCellDef>
            <br>
            <mat-form-field>
              <input matInput class="dataviewsplaceholder" #filter7 placeholder="updateTimeStamp"
                [formControl]="updateTimeStampFormFilter">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter7.value"
                (click)="filter7.value=''; resetFilter('','updateTimeStamp');">
                <mat-icon>remove_circle</mat-icon>
              </button>
            </mat-form-field>
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.updateTimeStamp}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>
            Name
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.name}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="objectType">
          <mat-header-cell class="moveleft" *matHeaderCellDef mat-sort-header>
            <br>
            Type
          </mat-header-cell>
          <mat-cell class="clickableitem" (click)="selectItem(row)" *matCellDef="let row"> {{row.objectType}}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="selected(row)" [class.selected]="row==selectedRow"
          *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>

      <button type="button" (click)="onPublish()" value="Publish" name="Publish" class="buttonToPublish">Publish
        Table</button>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>

    </div>
  </div>
</div>
