import { Injectable, OnChanges } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Element } from "./element.model";
//import { Angular5Csv } from 'angular5-csv/Angular5-csv';

// import { SaveSearchModel } from './savesearchmodel';

import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/publishReplay";
import { environment } from "../../environments/environment";
import { map, startWith, publishReplay } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service';

export interface Everything {
  [key: string]: any;
}

export interface Everything2 {
  title?: string;
  data: [];
}

@Injectable()
export class DataviewsService {
  viewName: string;
  viewSchema: string;
  tokenparam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token"));
  private baseUrl: string = environment.product_service_baseUrl;
  private _dataviewsService: string =
    this.baseUrl + "/data/object?" + this.tokenparam;
  private _dataTableService: string = this.baseUrl + "/data/";
  private _downloadCSVService: string =
    this.baseUrl + "/download/convertJson?" + this.tokenparam;

  constructor(private http: HttpClient, private cookie: CookieService) {
    // alert("dataviews called")
  }

  public getSmartViews(): Observable<Element[]> {
    return this.http.get<Element[]>(this._dataviewsService);
  }

  getSmartViews2() {
    return this.http.get(this._dataviewsService);
  }

  accessdatatable(smartObject): Observable<[Everything]> {
    this.viewSchema = smartObject.schema;
    this.viewName = smartObject.name;
    localStorage.setItem("smartobject", smartObject);
    localStorage.setItem("viewName", this.viewName);
    localStorage.setItem("viewSchema", this.viewSchema);
    sessionStorage.setItem("smartobject", smartObject);
    localStorage.setItem("smartobject", smartObject);
    sessionStorage.setItem("viewName", this.viewName);
    localStorage.setItem("viewName", this.viewName);
    sessionStorage.setItem("viewSchema", this.viewSchema);
    localStorage.setItem("viewName", this.viewName);
    let viewName = sessionStorage.getItem("viewName");
    let viewSchema = sessionStorage.getItem("viewSchema");

    let dataTableService =
      this._dataTableService +
      viewSchema +
      "/" +
      viewName +
      "?" +
      this.tokenparam;

    console.log("smartObject=" + viewName);

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this.http.post<[Everything]>(dataTableService, smartObject, {
      headers: headers
    });
  }

  accessdatatable2(paramforurl): Observable<[Everything]> {
    let dataTableService =
      this._dataTableService + paramforurl + "?" + this.tokenparam;
    sessionStorage.removeItem("viewName");
    this.viewName = paramforurl;
    sessionStorage.setItem("viewName", this.viewName);
    localStorage.setItem("viewName", this.viewName);
    return this.http.get<[Everything]>(dataTableService);
  }

  csvdownload1(dataTablesx) {
    // let csvurl = `${this.baseUrl}/data/${this.viewName}/download?${this.tokenparam}`;
    // let csvurl1 = `${this.baseUrl}/data/${this.viewName}/download?${this.tokenparam}`;
    //   let headers = new HttpHeaders({'Content-Type': 'text/csv'});
    // let options = { headers: headers };
    // return this.http.post(csvurl,dataTablesx, options)
    //new Angular5Csv(dataTablesx, 'My Report');
  }

  public getCSVFile(filename, dataTablesx): Observable<any> {
    let convertToCSVURL = `${
      this._downloadCSVService
    }&filename=${filename}.csv&jsonToConvert=${dataTablesx}`;
    // let convertedString = this.http
    //   .get(convertToCSVURL)
    //   .catch(this.errorHandler);
    console.log("convertToCSVURL--->" + convertToCSVURL);
    return this.http.post(convertToCSVURL, dataTablesx);
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error");
  }

  savefilters(savethis): Observable<any> {
    this.viewName = sessionStorage.getItem("viewName");
    let savedsearchURL2 = `${this.baseUrl}/data/${this.viewName}/user/filter?${
      this.tokenparam
    }`;
    // let savedsearchURL2 = `${this._url}/savedSearch`;
    return this.http.post(savedsearchURL2, savethis);
  }

  retrievefilters(): Observable<any> {
    let savedsearchURL2 = `${this.baseUrl}/data/view/user/filter?${
      this.tokenparam
    }`;
    // let savedsearchURL2 = `${this._url}/savedSearch`;
    return this.http.get(savedsearchURL2);
  }

  deletefilters(searchName: string, searchText: string): Observable<any> {
    let savedsearchURL2 = `${
      this.baseUrl
    }/savedSearch?searchName=${searchName}&searchText=${searchText}&${
      this.tokenparam
    }`;
    return this.http.delete(savedsearchURL2);
  }
}
