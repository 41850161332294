import { Component, OnInit } from "@angular/core";
import { PublisherService } from "../contentpublisher/publisher.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  NgForm,
} from "@angular/forms";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { SearchyearfilterPipe } from "./searchyearfilter.pipe";
import { SearchreportfilterPipe } from "./searchreportfilter.pipe";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-userbatch",
  templateUrl: "./userbatch.component.html",
  styleUrls: ["./userbatch.component.scss"],
})
export class UserbatchComponent implements OnInit {
  selectedFile: File = null;
  errorMessage: string;
  pageTitle: string = "User Batch Operations";
  appId: string;
  operation: string;
  fileToUpload: any;
  userFilter: string;
  file: any;
  fileList: any;

  private baseUrl: string = environment.product_service_baseUrl;

  private _url: string = this.baseUrl;

  constructor(
    public _publisherService: PublisherService,
    private http: HttpClient,
    private router: Router
  ) {}

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  onUpload() {
    this.verifyUpload(
      this.selectedFile,
      this.selectedFile.name,
      this.appId,
      this.operation
    );
  }

  verifyUpload(selectedFile, selectedFilename, appId, operation) {
    const fd = new FormData();

    fd.append("fileToUpload", selectedFile, selectedFile.name);
    fd.append("appId", appId);
    fd.append("operation", operation);

    this._publisherService.uploadBatchFile(fd);
  }

  resetForm(regForm: NgForm) {
    this.router.navigate(["/products"]);
    regForm.resetForm();
  }
  // ReportTypes = []

  ngOnInit() {
    // this._publisherService.getPublisher()
    // .subscribe(data=>this.IServicesmodel = data);
    this.errorMessage = null;
  }
}
