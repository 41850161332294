import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserRegistrationService} from "../auth-servicecognito/user-registration.service";
import {UserLoginService} from "../auth-servicecognito/user-login.service";
import {LoggedInCallback} from "../auth-servicecognito/cognito.service";
import { RegistrationUser } from "../auth-register/registration.component";

@Component({
    selector: 'app-RegistrationConfirmation',
    templateUrl: './confirmRegistration.html',
    styleUrls: ['./confirmRegistration.scss']
})
export class RegistrationConfirmationComponent implements OnInit, OnDestroy {
    confirmationCode: string;
    email: string;
    errorMessage: string;
    private sub: any;

    constructor(public regService: UserRegistrationService, public router: Router, public route: ActivatedRoute) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params['username'];
 
        });

        let email = this.email;

        this.errorMessage = null;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onConfirmRegistration() {
        this.errorMessage = null;
        this.regService.confirmRegistration(this.email, this.confirmationCode, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { //error
            this.errorMessage = message;
            console.log("message: " + this.errorMessage);
        } else { //success
            //move to the next step
            console.log("Moving to securehome");
            // this.configs.curUser = result.user;
            // this.router.navigate(['/securehome']);
        }
    }
}





