export class newContent {
    getCaughtUp:getCaughtUp[];
    whatsNew:whatsNew[];
    comingSoon:comingSoon[];
  }
  export class getCaughtUp {
    id:string;
    reportFiles:reportFiles[];
    filenames:filenames[];
    lastModified:string;
    service:string;
    agendaMonth:string;
    searchRank:number;
    year:string;
    reportType:string;
    htmlDir:string;
    category:string;
    keywords:string;
    description:string;
    favorite:boolean;
    serviceFullName:string;
    reportTypeFullName:string;
    abstractStr:string;
}
export class filenames {
    key:string;
    value:string;
}
export class reportFiles {
    filename:string;
    html:boolean;
    executiveSummary:boolean;
}
export class whatsNew {
    id:string;
    reportFiles:reportFiles[];
    filenames:filenames[];
    lastModified:string;
    service:string;
    agendaMonth:string;
    searchRank:number;
    year:string;
    reportType:string;
    htmlDir:string;
    category:string;
    keywords:string;
    description:string;
    favorite:boolean;
    serviceFullName:string;
    reportTypeFullName:string;
    abstractStr:string;
}
export class comingSoon {
    family:string;
    name:string;
    serviceYears:serviceYears[]
}
export class serviceYears {
    year:string;
    current:string;
    reportTypes: reportTypes[]
}
export class reportTypes {
    name:string;
    fullname:string;
    description:string;
    agendaMonth:string;
    abstractStr:string;
    shipDate:string;
    categories: categories[]
}
export class categories {
    name:string;
}