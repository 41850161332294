import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/material.module';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';
import { AuthorizationService } from "../shared/authorization.service";
import { LoginComponent } from '../login/login.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { UserLoginService } from '../auth-servicecognito/user-login.service';
import { CognitoUtil } from '../auth-servicecognito/cognito.service';
import { AllgroupsserviceService } from '../auth-servicecognito/allgroupsservice.service';


@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild([
      { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' } },
      { path: '', redirectTo: '/login', pathMatch: 'full' }
    ])
  ],
  declarations: [
    LoginComponent,
  ],  
  providers: [
    AuthService, AuthGuard, AuthorizationService, UserLoginService, CognitoUtil, AllgroupsserviceService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA] 

})
export class UserModule { }
