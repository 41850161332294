import * as Highcharts from "highcharts";
import ExportingModule from "highcharts/modules/exporting";
import SunsetTheme from "highcharts/themes/sunset";
import * as HC_customEvents from "highcharts-custom-events";
import { PublisherService } from "../contentpublisher/publisher.service";
HC_customEvents(Highcharts);
ExportingModule(Highcharts);
SunsetTheme(Highcharts);
import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  Input
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  SortDirection,
  MAT_BUTTON_TOGGLE_GROUP_VALUE_ACCESSOR,
  MatSortable
} from "@angular/material";
import {
  MatDialogModule,
  MatDialog,
  MatDialogRef
} from "@angular/material/dialog";
import "rxjs/add/observable/of";
import {
  DatapublishService,
  Everything,
  Everything2
} from "./datapublish.service";
import { Element } from "./element.model";
import { environment } from "../../environments/environment";
//import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { SpinnerService } from "../spinner.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatTable } from "@angular/material/table";
import { JsonPipe } from "@angular/common";
import { SavefilterDialog } from "../savefilter-dialog/savefilter-dialog";
import { LoadFiltersDialog } from "../loadfilters-dialog/loadfilters-dialog";
import "rxjs/add/operator/map";
import { CookieService } from 'ngx-cookie-service';

export const CONDITIONS_LIST = [
  { value: "is-like", label: "Is like" },
  { value: "is-empty", label: "Is empty" },
  { value: "is-not-empty", label: "Is not empty" },
  { value: "is-equal", label: "Is equal" },
  { value: "is-not-equal", label: "Is not equal" }
];

@Component({
  selector: "app-datapublishertable",
  templateUrl: "./datapublishertable.component.html",
  styleUrls: ["./datapublishertable.component.scss"]
})
export class DatapublishertableComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts; // Highcharts, it's Highcharts
  public visibility: boolean = false;
  color = "accent";
  mode = "determinate";
  value = 30;

  public baseUrl: string = environment.product_service_baseUrl;
  displayedColumns = [
    "checked",
    "promoteNow",
    "title",
    "service",
    "year",
    "reportType",
    "reportNumber",
    "updatedBy",
    "updateTimeStamp",
    "name",
    "objectType"
  ];
  listFilter;
  selectedValue: string;
  selectedValue4: string;
  selectedValue5: string;
  selectedValue6: string;
  smartViews = null;
  smartViews2 = null;
  dataTables2;
  dataTables3;
  dataTables4;
  dataSource = new MatTableDataSource<Element>(this.dataTables2);
  menu;
  smartViewHeaders = [];
  selectedRow: string;
  dataTables = new MatTableDataSource<[Everything]>(this.dataTables3);
  datas = [];
  datassourcer = new MatTableDataSource<[Everything2]>(this.datas);
  tablearray;
  tablearraystr;
  tablearraystr1st;
  dialogRef2: MatDialogRef<SavefilterDialog>;
  dialogRef3: MatDialogRef<LoadFiltersDialog>;
  filterName;
  filtertree;
  filter: [];

  headarray = [];

  sortValue;

  tablearray1st;

  headarray1st = [];

  //  headarray: String[];
  displayedColumns2;
  displayedColumns3;
  // displayedColumns = [];
  columnsToDisplay;

  updateDemo2: boolean = false;
  usedIndex: number = 0;
  chartTitle: string = "asdfasdfasd"; // for init - change through titleChange
  data1 = [11, 2, 3];
  data2 = [5, 6, 7];

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild("table") table: MatTable<Element>;
  @Input("matSortDirection") direction: SortDirection;
  @Input("Matsortables") sortables: Map<string, MatSortable>;

  titleFormFilter = new FormControl("");
  serviceFormFilter = new FormControl("");
  yearFormFilter = new FormControl("");
  reporttypeFormFilter = new FormControl("");
  reportnumberFormFilter = new FormControl("");
  updatedByFormFilter = new FormControl("");
  updatedTimeStampFormFilter = new FormControl("");

  filterValues = {
    title: "",
    service: "",
    year: "",
    reportType: "",
    reportNumber: "",
    updatedBy: "",
    updatedTimeStamp: ""
  };

  public CONDITIONS_FUNCTIONS = {
    "is-like": function(value, filterdValue) {
      value = value.toLowerCase().trim();
      filterdValue = filterdValue.toLowerCase().trim();
      return typeof value == "string" && value.indexOf(filterdValue) > -1;
    },
    "is-empty": function(value, filterdValue) {
      return value === "";
    },
    "is-not-empty": function(value, filterdValue) {
      return value !== "";
    },
    "is-equal": function(value, filterdValue) {
      return value == filterdValue;
    },
    "is-not-equal": function(value, filterdValue) {
      return value != filterdValue;
    }
  };

  public conditionsList = CONDITIONS_LIST;
  public searchValue: any = {};
  public searchCondition: any = {};
  public _filterMethods = this.CONDITIONS_FUNCTIONS;

  constructor(
    public _datapublishService: DatapublishService,
    private spinnerService: SpinnerService,
    public _publisherService: PublisherService,
    public dialog: MatDialog,
    private cookie: CookieService
  ) {
    this._datapublishService.getSmartViews().subscribe(data => {
      this.smartViews = data;
      this.dataSource.data = this.smartViews;
      for (let datax of this.dataSource.data) {
        this.headarray1st = Object.keys(datax);
        this.tablearray1st = JSON.stringify(datax);
        this.tablearraystr1st = JSON.stringify(datax);
      }
    });
    this.dataTables2 = this._datapublishService.getSmartViews2;
    this.dataSource.filterPredicate = this.tableFilter();
  }
  public baseUrl1: string = this.baseUrl + "/data";
  public tokenstring: string = this._datapublishService.tokenparam;

  selection = new SelectionModel<Element>(true, []);

  tableFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      return (
        data.title.toLowerCase().indexOf(searchTerms.title) !== -1 &&
        data.service
          .toString()
          .toLowerCase()
          .indexOf(searchTerms.service) !== -1 &&
        data.year.toLowerCase().indexOf(searchTerms.year) !== -1 &&
        data.reportType.toLowerCase().indexOf(searchTerms.reportType) !== -1 &&
        data.reportNumber.toLowerCase().indexOf(searchTerms.reportNumber) !== -1
      );
    };
    return filterFunction;
  }

  onPublish() {
    this.selection.selected.forEach(row => this.selection.select(row));
    const numSelected = this.selection.selected.length;
    //public rowString: string = this.selection.selected.every.toString();
    this._publisherService.onPublishList(this.selection.selected);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Element): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    //return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  selected(row): void {
    this.selectedRow = row;
  }

  doPublishNow(row): void {
    row.promoteAllObjects = false;
    row.promoteNow != true ? (row.promoteNow = true) : (row.promoteNow = false);
    this.selectedRow = row;
  }

  selectItem(smartObject) {
    sessionStorage.setItem("smartobject", smartObject);
    localStorage.setItem("smartobject", smartObject);
    this._datapublishService.accessdatatable(smartObject).subscribe(data => {
      this.smartViews2 = data;
      this.dataTables.data = this.smartViews2;
      for (let datax of this.dataTables.data) {
        this.headarray1st = Object.keys(datax);
        this.tablearray1st = JSON.stringify(datax);
        this.tablearraystr1st = JSON.stringify(datax);
      }
    });

    this.displayedColumns3 = this.header(smartObject);

    this.datasf();
  }

  getCol(matrix, col) {
    var column = [];
    for (var i = 0; i < matrix.length; i++) {
      column.push(matrix[i][col]);
    }
    return column;
  }

  header(smartObject) {
    this._datapublishService
      .accessdatatable(smartObject)
      .map(res => res)
      .subscribe(data => {
        this.displayedColumns2 = data;
        for (let datax of this.displayedColumns2) {
          this.headarray = Object.keys(datax);
          this.tablearray = datax;

          for (let col of this.headarray) {
            let array = this.getCol(this.displayedColumns2, col);
          }

          for (let col of this.headarray) {
            sessionStorage.removeItem("sort-" + col);
            localStorage.removeItem("sort-" + col);
            sessionStorage.removeItem("currentfilter");
            localStorage.removeItem("currentfilter");
            this.clearColumn(col);
          }
          sessionStorage.removeItem("currentdisplayedColumns");
          localStorage.removeItem("currentdisplayedColumns");
          sessionStorage.removeItem("currentfilter");
          localStorage.removeItem("currentfilter");
          sessionStorage.removeItem("currenttopten");
          localStorage.removeItem("currenttopten");
          localStorage.removeItem("currentbottomten");
          sessionStorage.removeItem("currentbottomten");

          this.tablearraystr = JSON.stringify(datax);
        }
      });
  }

  removedcolumns = [];

  arrayRemove(fromarray, col) {
    return fromarray.filter(function(ele) {
      return ele.title != col;
    });
  }

  removeColumn(col?) {
    if (this.headarray.length) {
      this.removedcolumns.push(col);
      sessionStorage.setItem(
        "removedcolumns",
        JSON.stringify(this.removedcolumns)
      );

      localStorage.setItem("removedcolumns", JSON.stringify(this.removedcolumns));
      let index = this.headarray.indexOf(col, 0);

      let a = this.headarray.slice(0, index);
      let b = this.headarray.slice(index + 1);
      this.headarray = a.concat(b);
      console.log("data[0]:" + JSON.stringify(this.datas));
      this.datas = this.arrayRemove(this.datas, col);

      sessionStorage.removeItem("currentdisplayedColumns");
      sessionStorage.removeItem("col");
      sessionStorage.setItem(
        "currentdisplayedColumns",
        JSON.stringify(this.datas)
      );
      localStorage.setItem("currentdisplayedColumns",
      JSON.stringify(this.datas));
      sessionStorage.setItem("col", col);
      localStorage.setItem("col", col);
      return this.displayedColumns2;
    }
  }

  removeColumn2(col, dataTablesy) {
    if (dataTablesy.length) {
      let index = dataTablesy.indexOf(col, 0);
      let a = dataTablesy.slice(0, index);
      let b = dataTablesy.slice(index + 1);
      // dataTablesy = a.concat(b)
      this.datas = dataTablesy;
      this.datas = this.arrayRemove(this.datas, col);
      sessionStorage.removeItem("currentdisplayedColumns");
      sessionStorage.removeItem("col");
      sessionStorage.setItem(
        "currentdisplayedColumns",
        JSON.stringify(this.displayedColumns2)
      );
      localStorage.setItem("currentdisplayedColumns",
      JSON.stringify(this.displayedColumns2));
      sessionStorage.setItem("col", col);
      localStorage.setItem("col", col);
      return this.displayedColumns2;
    }
  }

  csvdownload(header, dataTablesx) {
    let data = this.dataTables.data;
    for (let col of this.removedcolumns) {
      this.removeColumn2(col, data);
    }

    sessionStorage.removeItem("removedcolumns");

    // const headerarray = []
    // alert(header)
    function headerx(dataFromServer) {}
    var options = {
      showLabels: true,
      headers: header
    };

    //new Angular5Csv(this.dataTables.data, this._dataviewsService.viewName, options);
  }

  titleFilter(filterValue: string) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.title.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  serviceFilter(filterValue: string) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.service.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  yearFilter(filterValue: string) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.year.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  reportFilter(filterValue) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.reportType.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  reportNumberFilter(filterValue) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.reportNumber.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  updatedByFilter(filterValue) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.updatedBy.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  updateDateFilter(filterValue) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.updateTimeStamp.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  resetFilter(filterValue, keyType) {
    if (keyType == "title") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.title.toLowerCase().includes(filter);
      };
    } else if (keyType == "service") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.service.toLowerCase().includes(filter);
      };
    } else if (keyType == "year") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.year.toLowerCase().includes(filter);
      };
    } else if (keyType == "reportType") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.reportType.toLowerCase().includes(filter);
      };
    } else if (keyType == "reportNumber") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.reportNumber.toLowerCase().includes(filter);
      };
    } else if (keyType == "updatedBy") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.reportNumber.toLowerCase().includes(filter);
      };
    } else if (keyType == "updatedTimeStamp") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.reportNumber.toLowerCase().includes(filter);
      };
    }
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort.toArray()[0];
    this.dataSource.paginator = this.paginator.toArray()[0];

    this.dataTables.sort = this.sort.toArray()[1];
    this.dataTables.paginator = this.paginator.toArray()[1];

    //   this.datas.sort = this.sort.toArray()[2];
    //  this.datas.paginator = this.paginator.toArray()[2];
  }

  ngOnInit() {
    this.dataTables.filterPredicate = (p: Everything, filtre: any) => {
      let result = true;
      let keys = Object.keys(p); // keys of the object data

      for (const key of keys) {
        let searchCondition = filtre.conditions[key]; // get search filter method
        if (searchCondition && searchCondition !== "none") {
          if (
            filtre.methods[searchCondition](p[key], filtre.values[key]) ===
            false
          ) {
            // invoke search filter
            result = false; // if one of the filters method not succeed the row will be remove from the filter result
            break;
          }
        }
      }

      return result;
    };

    this.spinnerService.visibility.subscribe(state => {
      this.visibility = state;
      // console.log('visibility', state);
    });

    this.spinnerService.notVisibility.subscribe(state => {
      this.visibility = state;
      // console.log('notVisibility', state);
    });
    // this.dataTables.sort = this.sort.toArray()[1];

    this.titleFormFilter.valueChanges.subscribe(title => {
      this.filterValues.title = title;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.serviceFormFilter.valueChanges.subscribe(service => {
      this.filterValues.service = service;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.yearFormFilter.valueChanges.subscribe(year => {
      this.filterValues.year = year;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.reporttypeFormFilter.valueChanges.subscribe(reportType => {
      this.filterValues.reportType = reportType;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.reportnumberFormFilter.valueChanges.subscribe(reportNumber => {
      this.filterValues.reportNumber = reportNumber;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.updatedByFormFilter.valueChanges.subscribe(updatedBy => {
      this.filterValues.updatedBy = updatedBy;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.updatedTimeStampFormFilter.valueChanges.subscribe(updatedTimeStamp => {
      this.filterValues.updatedTimeStamp = updatedTimeStamp;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }
  asc;
  datatosort = [];
  savesort(col) {
    let elem = document.getElementById(col).getAttribute("aria-sort");
    const getCellValue = (tr, idx) =>
      tr.children[idx].innerText || tr.children[idx].textContent;
    const comparer = (idx, asc) => (a, b) =>
      ((v1, v2) =>
        v1 !== "" && v2 !== "" && !isNaN(v1) && !isNaN(v2)
          ? v1 - v2
          : v1.toString().localeCompare(v2))(
        getCellValue(asc ? a : b, idx),
        getCellValue(asc ? b : a, idx)
      );

    // grabs the original tbody
    document.querySelectorAll("th").forEach(th =>
      th.addEventListener("click", () => {
        const tbody = th.closest("tbody");
        Array.from(tbody.querySelectorAll("tr:nth-child(n+2)"))
          .sort(
            comparer(
              Array.from(th.parentNode.children).indexOf(th),
              (this.asc = !this.asc)
            )
          )
          .forEach(tr => tbody.appendChild(tr));
      })
    );
    sessionStorage.removeItem("sort-" + col);
    localStorage.removeItem("sort-" + col);
    if (elem == null) {
      sessionStorage.setItem("sort-" + col, "");
      localStorage.setItem("sort-" + col, "");
    } else {
      sessionStorage.setItem("sort-" + col, elem);
      localStorage.setItem("sort-" + col, elem);
    }
  }

  applyFilter(col?) {
    let searchFilter: any = {
      values: this.searchValue,
      conditions: this.searchCondition,
      methods: this._filterMethods
    };
    // console.log(this.datas.data)
    // console.log(this.datas.data[0])
    // console.log(this.datas.data[0][0]['data'])
    // console.log(this.dataSource)
    // console.log(this.dataTables)
    this.dataTables.filter = searchFilter;

    sessionStorage.removeItem("currentfilter");
    localStorage.removeItem("currentfilter");
    sessionStorage.setItem("currentfilter", JSON.stringify(searchFilter));
    localStorage.setItem("currentfilter", JSON.stringify(searchFilter));
  }

  loadapplyFilter(values, conditions) {
    let searchFilter: any = {
      values: values,
      conditions: conditions,
      methods: this._filterMethods
    };

    this.dataTables.filter = searchFilter;
    sessionStorage.removeItem("currentfilter");
    localStorage.removeItem("currentfilter");
    sessionStorage.setItem("currentfilter", JSON.stringify(searchFilter));
    localStorage.setItem("currentfilter", JSON.stringify(searchFilter));
  }

  clearColumn(columnKey: string): void {
    this.searchValue[columnKey] = null;
    this.searchCondition[columnKey] = "none";
    localStorage.removeItem("currentfilter");
    this.displayedColumns2;
    this.applyFilter();
  }

  clearAllColumns(): void {
    for (let col of this.headarray) {
      sessionStorage.removeItem("sort-" + col);
      localStorage.removeItem("sort-" + col);
      sessionStorage.removeItem("currentfilter");
      localStorage.removeItem("currentfilter");
      sessionStorage.removeItem("istopten");
      localStorage.removeItem("istopten");

      sessionStorage.removeItem("currentdisplayedColumns");
      localStorage.removeItem("currentdisplayedColumns");
      sessionStorage.removeItem("removedcolumns");
      localStorage.removeItem("removedcolumns");
      this.removedcolumns = [];
      this.searchValue[col] = null;
      this.searchCondition[col] = "none";
      sessionStorage.setItem("toptensent", "false");
      localStorage.setItem("toptensent", "false");
      this.toptenactive = sessionStorage.getItem("toptensent") ? sessionStorage.getItem("toptensent") : localStorage.getItem("toptensent");
      // localStorage.removeItem('currentfilter'+ JSON.stringify(this.searchValue))
      this.displayedColumns2;
      this.datasf();
      this.applyFilter();
      this.unsubscribeheader();
      this.unsubscribeheadernew();
      this.topten2();
      this.numbervalue = "";
      this.directionbut = "";
    }
  }

  tasks = [{ toggle: false, status: "Top" }];
  enableDisableRule(button) {
    button.toggle = !button.toggle;
    button.status = button.toggle ? "Bottom" : "Top";
  }

  directionbut;
  numbervalue;
  toptenactive;
  datacolumn;
  dataobj = [];

  topten(numbervalue?, directionbut?) {
    // this.datas = []
    if (numbervalue == undefined) {
      return "";
    } else {
      let valuedata: any = {
        direction: directionbut,
        value: numbervalue,
        selected: true
      };
      let data = this.datas;
      console.log("data:" + JSON.stringify(data));
      if (valuedata.direction == "Top") {
        this.dataobj = [];
        for (let part of data) {
          this.datacolumn = part.data;
          this.datacolumn = this.datacolumn.slice(0, valuedata.value);
          this.datas = [];
          this.dataobj.push({ title: part.title, data: this.datacolumn });
          this.displayedColumns2 = this.displayedColumns2.slice(
            0,
            valuedata.value
          );
        }
        this.datas = this.dataobj;
        sessionStorage.setItem("istopten", JSON.stringify(valuedata));
        localStorage.setItem("istopten", JSON.stringify(valuedata));
        sessionStorage.setItem("toptensent", "true");
        localStorage.setItem("toptensent", "true");
        this.toptenactive = sessionStorage.getItem("toptensent") ? sessionStorage.getItem("toptensent") : localStorage.getItem("toptensent");
        sessionStorage.removeItem("currenttopten");
        localStorage.removeItem("currenttopten");
        localStorage.removeItem("currenttopten");
        sessionStorage.setItem("currenttopten", JSON.stringify(this.datas));
        localStorage.setItem("currenttopten", JSON.stringify(this.datas));
        // alert(JSON.stringify(this.datas))
        // return this.displayedColumns2;
      } else if (valuedata.direction == "Bottom") {
        this.dataobj = [];
        for (let part of data) {
          let datacolumnx = part.data;
          this.datacolumn = datacolumnx.slice(0, valuedata.value);

          // this.datas = []
          this.dataobj.push({ title: part.title, data: this.datacolumn });
          this.displayedColumns2 = this.displayedColumns2.slice(
            this.displayedColumns2.length - valuedata.value,
            this.displayedColumns2.length
          );
        }
        this.datas = this.dataobj;
        sessionStorage.setItem("istopten", JSON.stringify(valuedata));
        localStorage.setItem("istopten", JSON.stringify(valuedata));
        sessionStorage.setItem("toptensent", "true");
        localStorage.setItem("toptensent", "true");
        this.toptenactive = sessionStorage.getItem("toptensent") ? sessionStorage.getItem("toptensent") : localStorage.getItem(sessionStorage.getItem("toptensent"));
        localStorage.removeItem("currentbottomten");
        sessionStorage.removeItem("currentbottomten");
        localStorage.removeItem("currentbottomten");
        sessionStorage.setItem("currentbottomten", JSON.stringify(this.datas));
        localStorage.setItem("currentbottomten", JSON.stringify(this.datas));
        // alert(JSON.stringify(this.datas))
        // return this.displayedColumns2;
      }
    }
  }
  smartobject;
  unsubscribeheader() {
    this.smartobject = sessionStorage.getItem("smartobject") ? sessionStorage.getItem("smartobject") : localStorage.getItem("smartobject");
    alert(this.smartobject);
    this._datapublishService
      .accessdatatable(this.smartobject)
      .map(res => res)
      .subscribe(data => {
        this.displayedColumns2 = data;
        for (let datax of this.displayedColumns2) {
          this.headarray = Object.keys(datax);
        }

        for (let datax of this.displayedColumns2) {
          this.tablearray = datax;
          //  alert(JSON.stringify(datax))
        }
      });
  }

  unsubscribeheadernew() {
    this.datas = [];

    this.smartobject = sessionStorage.getItem("smartobject") ? sessionStorage.getItem("smartobject") : localStorage.getItem("smartobject");
    console.log("the object:" + this.smartobject);
    this._datapublishService
      .accessdatatable(this.smartobject)
      .map(res => res)
      .subscribe(data => {
        this.displayedColumns2 = data;
        for (let datax of this.displayedColumns2) {
          this.headarray = Object.keys(datax);
        }

        for (let datax of this.displayedColumns2) {
          this.tablearray = datax;
          //  alert(JSON.stringify(datax))
        }

        for (let col of this.headarray) {
          let array = this.getCol(this.displayedColumns2, col);
          this.datas.push({ title: col, data: array });
        }
      });
  }

  topten2() {
    let smartobject = sessionStorage.getItem("smartobject") ? sessionStorage.getItem("smartobject") : localStorage.getItem("smartobject");
    this._datapublishService.accessdatatable(smartobject).subscribe(data => {
      this.smartViews2 = data;
      this.dataTables.data = this.smartViews2;
    });
    return this.dataTables;
  }

  public sorts = [];
  public filteredarra;
  public filteredarrax;
  public currentdisplayedColumns;

  savefilters() {
    this.currentdisplayedColumns = sessionStorage.getItem(
      "currentdisplayedColumns"
    ) ?  sessionStorage.getItem("currentdisplayedColumns") : localStorage.getItem("currentdisplayedColumns");
    // let currentbottomten = sessionStorage.getItem('currentbottomten')
    // let currenttopten = sessionStorage.getItem('currenttopten')
    let istopten = sessionStorage.getItem("istopten") ? sessionStorage.getItem("istopten") : localStorage.getItem("istopten") ;
    let isbottomten = sessionStorage.getItem("isbottomten") ? sessionStorage.getItem("isbottomten") : localStorage.getItem("isbottomten") ;
    let currentfilter = sessionStorage.getItem("currentfilter") ? sessionStorage.getItem("currentfilter") : localStorage.getItem("currentfilter") ;
    let col = sessionStorage.getItem("col") ? sessionStorage.getItem("col") : localStorage.getItem("col");

    // console.log("this.displayedColumns2 stringify:"+JSON.stringify(this.displayedColumns2))

    if (istopten == "true") {
      if (this.headarray.length) {
        let data = this.dataTables.data;
        this.dataTables.data = data.slice(0, 5);

        for (var i = 0; i < this.dataTables.data.length; i++) {
          delete this.dataTables.data[i][col];
        }

        return this.dataTables.data;
      }
    }

    if (isbottomten == "true") {
    }

    if (sessionStorage.length != 0) {
      for (var keyxx in sessionStorage) {
        var j = 0;
        if (keyxx.includes("sort-")) {
          for (var keyxx in sessionStorage) {
            if (keyxx.includes("sort-")) {
              this.sorts.push(
                '"' + keyxx + '":"' + sessionStorage.getItem(keyxx) + '"'
              );
            }
          }
          break;
        }
      }
    }
  }

  conditionName;
  conditionValue;
  columnList;
  condition;
  currentfilter;
  id;
  istopten;
  isbottomten;

  previousIndex = 0;
  currentIndex = 0;

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.datas, event.previousIndex, event.currentIndex);

    this.previousIndex = event.previousIndex;
    this.currentIndex = event.currentIndex;
  }

  datasx;
  datasf() {
    this.datas = [];
    for (let col of this.headarray) {
      let array = this.getCol(this.displayedColumns2, col);

      this.datas.push({ title: col, data: array });
    }
    var i = 0;
    for (let col of this.datas) {
      console.log(
        "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx "
      );

      console.log(
        "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx "
      );
    }
    // return this.datas
  }

  look(para) {
    alert(para);
  }

  // dropTable(event: CdkDragDrop<Element[]>) {
  //   const prevIndex = this.headarray.findIndex((d) => d === event.item.data);
  //   moveItemInArray(this.headarray, prevIndex, event.currentIndex);
  //   this.table.renderRows();
  // }

  charts = [
    {
      hcOptions: {
        title: {
          text: "Solar Employment Growth by Sector, 2010-2016"
        },

        subtitle: {
          text: "Source: thesolarfoundation.com"
        },

        yAxis: {
          title: {
            text: "Number of Employees"
          }
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle"
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false
            },
            pointStart: 2010
          }
        },

        series: [
          {
            name: "AbbVie",
            data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
          },
          {
            name: "Boehringer Ingelheim",
            data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
          },
          {
            name: "GlaxoSmithKline",
            data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
          },
          {
            name: "Janssen (J&J HCS)",
            data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
          },
          {
            name: "Merck",
            data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
          },
          {
            name: "Novartis",
            data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
          },
          {
            name: "Industry average",
            data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
          }
        ],

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom"
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      } as Highcharts.Options
    }
  ];
}
