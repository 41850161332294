

<div class="container">
  <div>
    <p style="color: #9d9689; text-align: center; font-size: 1.25rem ! important ; margin-top: 10px; " >Home</p>
  </div>
  <div class="row justify-content-md-center align-items-center" style="height: 40vh;">
    <div class="col col-md-4 col-lg-4">
      <div class="col mrb-15">
        <button joyrideStep="firstStep" [stepContent]="releasedContent" routerLink="/justReleased"
          class="btn btn-primary btn-block">Just
          Released</button>
      </div>
      <ng-template #releasedContent>
        Displays a list of research that has Just Released in the past month.
      </ng-template>
      <div class="col mrb-15">
        <button joyrideStep="secondStep" [stepContent]="prodContent" routerLink="/products"
          class="btn btn-primary btn-block">Products</button>
      </div>
      <ng-template #prodContent>
        Displays a list of all research Products available.
      </ng-template>

      <div class="col mrb-15">
        <button joyrideStep="sixthStep" [stepContent]="csoonContent" routerLink="/comingSoon"
          class="btn btn-primary btn-block">Coming Soon</button>
      </div>
      <ng-template #csoonContent>
        Displays a list of products scheduled to release in the following month.
      </ng-template>
      <div class="col mrb-15">
        <button joyrideStep="seventhStep" [stepContent]="eofferContent" routerLink="/loginAds"
          class="btn btn-primary btn-block">Explore New Offerings</button>
      </div>
      <ng-template #eofferContent>
        Displays the Explore New Offerings page, featuring new products available for purchase.
      </ng-template>
      <div class="d-lg-none">
        <a class="btn btn-primary login-contact-btn" routerLink="/contactus">contact us</a>
      </div>

    </div>
  </div>




</div>