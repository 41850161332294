<div mat-dialog-content style="color:#565a5c;"><b>{{confirmMessage}}</b></div>
<div mat-dialog-content><span style="color:#565a5c;">term: </span>"{{confirmMessage2}}"</div>
<div mat-dialog-content><span style="color:#565a5c;">saved as: </span>"{{confirmMessage3}}"</div><br>

<!-- <div *ngFor="let datanames of savedSearch">

<div mat-dialog-content>"{{datanames.searchName}}"</div><br>

</div> -->

<div mat-dialog-actions>
  <button mat-button style="color: #fff;background-color: var(--color-primary);" [mat-dialog-close]="data.searchName">Delete</button>
  <button mat-button (click)="dialogRef3.close(false)">Cancel</button>
</div>
