export class SaveFiltersModel {
  viewName: string;
  filters: [];
  }
export class filters {
  id: string;
  filterName: string;
  filter: []
  }
  export class filter {
    currentfilter: string;
    columnList: [];
    removedcolumns: [];
    sorts: [];
    topvalues: string;
    }
