import { Component, ViewChild } from '@angular/core';
import { DashsearchComponent } from './dashsearch/dashsearch.component';
import { HeaderComponent } from './header/header.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(DashsearchComponent)  private dashsearchComponent: DashsearchComponent;
  @ViewChild(HeaderComponent)  private headerComponent: HeaderComponent;

  public clickedEvent: Event;


  childEventClicked(event: Event) {
    this.clickedEvent = event;
    alert(this.clickedEvent)
  }




}
