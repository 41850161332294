import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
//import {Http, Response} from "@angular/http";
import { Observable } from "rxjs/Observable";
import { IUserDatamodel, Iattributessmodel } from "./resetmodel";
import "rxjs/add/operator/map";
import "rxjs/add/operator/publishReplay";
import { map, startWith, publishReplay } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ResetpasswordService {
  _baseUrl: string = "";

  // private _url:string = 'http://34.232.23.95:8181/user-service-0.1.0/users';

  tokenparam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token"));

  private user_baseUrl: string = environment.user_baseUrl;

  private _url: string = this.user_baseUrl + "/users?" + this.tokenparam;

  constructor(private http: HttpClient, private cookie: CookieService) {
    this._baseUrl = environment.user_baseUrl;
  }

  private userData: Observable<IUserDatamodel[]> = this.http
    .get<IUserDatamodel[]>(this._url)
    .publishReplay(1)
    .refCount();

  public getuserData(): Observable<IUserDatamodel[]> {
    return this.userData;
  }

  //   updateUser(user: IUserDatamodel): void {
  //     let attributeName = {
  //       Name: 'name',
  //       Value: user.attributes[2].value
  //   }
  //   let attributeCompanyName = {
  //     Name: 'custom:companyName',
  //     Value: user.attributes[3].value
  //   }
  //   let attributeLastname = {
  //     Name: 'family_name',
  //     Value: user.attributes[4].value
  // }

  //     let params = new HttpParams().append("userId",user.username).append("name", attributeName.Value).append("family_name", attributeLastname.Value).append("companyname", attributeCompanyName.Value); //Create new HttpParams
  //     this.http.get(this._baseUrl + 'updateuser?', { params }).map((response:Response) => {
  //         console.log('response from updateUser' + response.json());
  //         response.json();
  //     }).subscribe();

  // }
  // .append("name",user.name).append("familyname",user.family_name).append("companyname",user.companyName);
  // deleteUser(user: RegistrationUser): void {
  //     let params = new HttpParams().append("userId",user.email); //Create new HttpParams
  //     this.http.get(this._baseUrl + 'deleteuser?', { params }).map((response:Response) => {
  //         console.log('response from deleteUser' + response.json());
  //         response.json();
  //     }).subscribe();

  // }
}
