import { Component, Input, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl ,FormsModule,NgForm } from '@angular/forms';
import { SaveFiltersModel } from '../dataviews/savefiltersmodel';

@Component({
  selector: 'app-savefilter-dialog',
  templateUrl: 'savefilter-dialog.html'
})
export class SavefilterDialog {
  Settings: string;


  constructor(public dialogRef2: MatDialogRef<SavefilterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SaveFiltersModel) {}

  onNoClick(): void {
    this.dialogRef2.close();
  }

  public confirmMessage:string;
  public confirmMessage2:string;

}
