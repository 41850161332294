import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Subject } from 'rxjs/Subject';
//import {Http, Response} from "@angular/http";
import { Observable } from 'rxjs/Observable';
import { IDashboardmodel } from '../models/dashboardmodel';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/publishReplay';
import { map, startWith, publishReplay } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class DashboardservicesService {

  getUserName(): string {
    let userName: string = sessionStorage.getItem('username') ? sessionStorage.getItem('username') : localStorage.getItem('username') ;
    const username: string = userName;
    return username;
  }  
  // private _url:string = '../../assets/dashboardsample.json';
  tokenparam = 'JWT=Bearer ' + (sessionStorage.getItem('token') ? sessionStorage.getItem('token') : localStorage.getItem('token'));
   private baseUrl:string = environment.product_service_baseUrl;
   private _url:string = this.baseUrl+'/defaultDashboard?'+ this.tokenparam
  //  private _myurl:string = '../../assets/dashboardsample.json?userId='+this.getUserName();
  // private _url:string = '../../assets/salesforce-sample.json';
 //   private _url:string = '../../assets/newprod2.json';
  //  private _url:string = '../../assets/data.json';
//  private _url:string = 'http://34.232.23.95:8181/product-service-0.1.0/product';
  // private _myurl:string = 'http://34.232.23.95:8181/product-service-0.1.0/product?userId=jklein@entech.com';
 // private _myurl:string = 'http://34.232.23.95:8181/product-service-0.1.0/product?userId='+this.getUserName();
  // private _myurl:string = '../../assets/myservicestest.json?userId='+this.getUserName();
    // private _url:string = 'https://54.165.144.45:8443/product-service-0.1.0/product';

  // whatsnewval: boolean = false;
  // opentab: number = 0;    

  _whatsnewval: Subject<boolean> = new Subject();
  get whatsnewvalSource() : Subject<boolean> {
    return this._whatsnewval;
  }
  set whatsnewvalSource(src: Subject<boolean>) {
    this._whatsnewval = src;
  }

  changeWhatsnewval(n: boolean) {
    this.whatsnewvalSource.next(n);
  }  

  //opentab
  _opentabval: Subject<number> = new Subject();
  get opentabvalSource() : Subject<number> {
    return this._opentabval;
  }
  set opentabvalSource(src: Subject<number>) {
    this._opentabval = src;
  }

  changeopentabval(n: number) {
    this.opentabvalSource.next(n);
  }  

  visibility;
  test1(){
    this.visibility = false
  }

// private messageSource = new BehaviorSubject<boolean>(true);
// currentMessage = this.messageSource.asObservable();


// changeWhatsNewFunc(whatsnewval: true) {
//   this.messageSource.next(whatsnewval)
//   }

  constructor(private http: HttpClient, private cookie: CookieService) { }

  private dashServ: Observable<IDashboardmodel[]> = 
    this.http.get<IDashboardmodel[]>(this._url)
    .catch(this.errorHandler)
    .publishReplay(1) 
    .refCount();
  public getdashServ(): Observable<IDashboardmodel[]> {
    return this.dashServ;
  }

  errorHandler(error:HttpErrorResponse){
    return Observable.throw(error.message || "Server Error")
  }

  private myServicesContact: Observable<IDashboardmodel[]> = 
  this.http.get<IDashboardmodel[]>(this._url)
  .publishReplay(1) 
  .refCount();
  public getMyServicesContact(): Observable<IDashboardmodel[]> {
    return this.myServicesContact;
  }


}