<div class="flex-container3">
        <mat-card class="item3card mat-elevation-z5">
          <mat-card-header>
            
            <mat-card-title class="textcolor1">Registration Confirmed</mat-card-title>
          </mat-card-header>
          <mat-card-content> 

<form class="form-signin" method="POST" action="#" role="form" >
        <div class='indent3 paddingup'>
    <div class="form-group">
      
    </div>
    <div *ngIf="errorMessage!=null" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div class="form-group" *ngIf="email">
        <label class="control-label" for="confirmationCode">Email:</label>
        <span>{{email}}</span>
    </div>
    <p>
            <!-- Can't find your code? <a [routerLink]="['/resendCode']"> <i class="fa fa-fw fa-group"></i> Resend
            Code</a> -->
            <!-- <a [routerLink]="['/login']">Log In<i class="fa fa-fw fa-group"></i> Register</a>  -->
            <a [routerLink]="['/login']">Go to Login</a> 
            <!-- <button  mat-raised-button color="accent" style="width:80px;margin-right:10px" [routerLink]="['/login']">Log In</button> -->
            <!-- <button [routerLink]="['/login']"> <i class="fa fa-fw fa-user"></i> Login</button> -->
        </p>
    <!-- <div class="form-group">
        <label class="control-label" for="confirmationCode">Confirmation Code</label>
        <input id="confirmationCode" type="text" maxlength="50" class="form-control"
               [(ngModel)]="confirmationCode" [ngModelOptions]="{standalone: true}">
    </div> -->
    <!-- <div class="form-group">
        <button (click)="onConfirmRegistration()" id="confirmRegistration" type="submit"
                class="btn btn-info btn-block">Confirm
        </button>
    </div> -->
</div>
</form>

</mat-card-content>
</mat-card>
</div>