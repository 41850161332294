import { Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { PublisherService } from "../contentpublisher/publisher.service";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-datapublisher",
  templateUrl: "./datapublisher.component.html",
  styleUrls: ["./datapublisher.component.scss"]
})
export class DatapublisherComponent implements OnInit {
  errorMessage: string;
  pageTitle: string = "Data Publisher";
  tableTaxonomy: string;

  private baseUrl: string = environment.product_service_baseUrl;

  private _url: string = this.baseUrl;

  constructor(
    public _publisherService: PublisherService,
    private http: HttpClient,
    private router: Router
  ) {}

  onPublish() {
    this._publisherService.onPublish(this.tableTaxonomy);
  }

  resetForm(regForm: NgForm) {
    this.router.navigate(["/datapublisher"]);
    regForm.resetForm();
  }
  // ReportTypes = []

  ngOnInit() {
    this.errorMessage = null;
    this.tableTaxonomy = `For All tables
    {"objectType": "SMARTTABLE",
      "promoteNow": true,
      "promoteAllObjects": true,
      "service": "TEST",
      "year": "2019",
      "reportType": "Asthma",
      "schema": "hsgdevdatabase"}

    For Specific Table
    {"objectType": "SMARTTABLE",
    "promoteNow": true,
    "service": "TEST",
    "year": "2019",
    "reportType": "Rare",
    "schema": "hsgdevdatabase",
    "objectName": "Q10_HPS_PSB_Rating_Attributes_Patient_Services_Implementing_Providers_RARE"}`;
    // this.tableTaxonomy = "";
  }
}
