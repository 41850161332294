<div class="flex-containerabout" _ngcontent-c6="">
    <mat-card _ngcontent-c6="" class="disclaimer mat-card mat-card mat-elevation-z0">
        <mat-card-content _ngcontent-c4="" class="mat-card-content mat-card-content"> 
        </mat-card-content>
    </mat-card></div>


<div class="flex-container3">
    <mat-card class="item3card mat-elevation-z5">
      <mat-card-header>
  
        <mat-card-title class="textcolor1">Set New Password</mat-card-title>
      </mat-card-header>
      <mat-card-content>
    
        <!--    <form novalidate (ngSubmit)="login(loginForm)" #loginForm="ngForm" autocomplete="off" > -->
            <!-- <form novalidate method="POST" action="#" role="form" #loginForm="ngForm" autocomplete="off" >     -->
                <form class="form-signin" method="POST" action="#" #loginForm="ngForm" role="form">
                    <div *ngIf="errorMessage!=null" class="alert alert-danger">
                        <!-- {{ errorMessage }} -->
                    </div>
  
                    <fieldset>


                    <!-- <div class="form-group" [ngClass]="{'has-error': (emailVar.touched || emailVar.dirty) && !emailVar.valid }">
               <mat-form-field>
    <input matInput class="form-control" id="emailId" type="text" placeholder="Username / Email" required [(ngModel)] = "registrationUser.username"  name="email" #emailVar="ngModel" />
                            <span class="help-block" *ngIf="(emailVar.touched || emailVar.dirty) && emailVar.errors"></span>
                </mat-form-field> 
                    </div> -->

                    <div class="form-group"
                    [ngClass]="{'has-error': existingPassword.invalid && (existingPassword.dirty || existingPassword.touched) }">
                            <mat-form-field>
                 <input matInput 
                        class="form-control" 
                        name="existingPassword" 
                        id="existingPassword" 
                        type="password" 
                        placeholder="Existing Password" 
                        required 
                        [(ngModel)] = "existingPassworda" 
                        #existingPassword="ngModel" />
                        <span class="bottom_error" *ngIf="existingPassword.invalid && (existingPassword.dirty || existingPassword.touched)">  
                                         </span>
                             </mat-form-field> 
                                 </div>


                                             <div class="form-group" 
                                             [ngClass]="{'has-error': password.invalid && (password.dirty || password.touched) }">
                                                    <mat-form-field>
                                                <input matInput 
                                                        class="form-control" 
                                                        name="password"
                                                        id="password" 
                                                        type="password"
                                                        maxlength="12"
                                                        [pattern]="selectedPattern"
                                                        placeholder="Password (required)"
                                                        required
                                                        [(ngModel)] = passworda
                                                        #password="ngModel" />
                                                <span class="bottom_error" *ngIf="password.invalid && (password.dirty || password.touched)">
                                                  
                                                </span>
                                                </mat-form-field>
                                           
                                        </div>   
                                        
                                        <div class="form-group" 
                                        [ngClass]="{'has-error': passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched) }">
                                               <mat-form-field>
                                           <input matInput 
                                                   class="form-control" 
                                                   name="passwordConfirm"
                                                   id="passwordConfirm" 
                                                   type="password"
                                                   maxlength="12"
                                                   [pattern]="selectedPattern"
                                                   placeholder="Confirm Password (required)"
                                                   compare="password"
                                                   [(ngModel)] = passwordConfirma
                                                   #passwordConfirm="ngModel" />
                                                   <span class="bottom_error" *ngIf="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)">
                                                       <span class="bottom_error" *ngIf="passwordConfirm.errors['pattern']">{{errorMgs}}</span>
                                                       <span class="bottom_error" *ngIf="passwordConfirm.errors['required']">   Password Confirmation is required</span>
                                                       <span class="bottom_error" *ngIf="passwordConfirm.errors['compare']">   Password Confirmation do Not Match.</span>
                                                      </span>      
                                           </mat-form-field>
                       
                                      
                                   </div>                   


                    
                    <!-- <div class="form-group" [ngClass]="{'has-error': (passwordVar.touched || 
                                                   passwordVar.dirty) && 
                                                   !passwordVar.valid }">
    
    
                      
                                <mat-form-field>
                            <input matInput class="form-control" 
                                    id="passwordId" 
                                    type="password" 
                                    placeholder="Password (required)"
                                    required
                                    [(ngModel)] = "password"
                                    name="password"
                                    #passwordVar="ngModel" />
                            <span class="help-block" *ngIf="(passwordVar.touched ||
                                                             passwordVar.dirty) &&
                                                             passwordVar.errors">

                            </span>
                            </mat-form-field>
                       
                    </div> -->
                    
                    <div class="form-group">
                        <div>
                            <span>
                                <button  mat-raised-button color="accent"
                                       (click)= "onLogin2()"
                                        id="signupSubmit"
                                        type="submit"
                                        style="width:80px;margin-right:10px"
                                        [disabled]="!loginForm.valid">
                                    Change Password
                                </button>
                            </span>
                            <span>
                                <button  mat-raised-button color="accent" 
                                (click)="resetForm(loginForm)">
                                    Cancel
                            </button>
                            </span>
                         </div>
                    </div>
              </fieldset>
   
                     
            </form>
            <div class="has-error" style="text-align: center; font-weight: bold;color: red; padding-bottom:20px;" *ngIf="errorMessage">{{errorMessage}}</div>
         <!--   <a href="" class="pass-forgot">Forgot your password?</a> -->
      </mat-card-content>
    </mat-card>
    
    </div>




















<!-- 
<form class="form-signin" method="POST" action="#" role="form">
    <div class="form-group">
        <h2>Set new password</h2>
    </div>
    <div *ngIf="errorMessage!=null" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div class="form-group">
    
        <input id="signupEmail" type="email" maxlength="50" class="form-control"
               [(ngModel)]="registrationUser.username" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <label class="control-label" for="existingPassword">Temporary Password</label>
        <input id="existingPassword" type="password" maxlength="25" class="form-control"
               placeholder="at least 8 characters" length="40" [(ngModel)]="registrationUser.existingPassword"
               [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <label class="control-label" for="signupPassword">New Password</label>
        <input id="signupPassword" type="password" maxlength="25" class="form-control"
               placeholder="at least 8 characters" length="40" [(ngModel)]="registrationUser.password"
               [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <button (click)="onRegister()" id="signupSubmit" type="submit" class="btn btn-info btn-block">
            Set your password
        </button>
    </div>
    <hr>
    <p>Already have an account? <a [routerLink]="['/home/login']"> <i class="fa fa-fw fa-lock"></i>Login</a></p>
</form> -->
