import { Inject, Injectable } from "@angular/core";
import { CognitoCallback, CognitoUtil } from "./cognito.service";
import { environment } from "../../environments/environment";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool
} from "amazon-cognito-identity-js";
import { RegistrationUser } from "../auth-register/registration.component";
import { NewPasswordUser } from "../auth-newpassword/newpassword.component";
import * as AWS from "aws-sdk/global";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
var aws = require("aws-sdk");
aws.config.update({ accessKeyId: "akid", secretAccessKey: "secret" });
var headers = new Headers();
headers.append("Content-Type", "application/json");
// headers.append('projectid', this.id);

@Injectable()
export class UserRegistrationService {
  _baseUrl: string = "";
  tokenparam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token")) + "&";

  constructor(
    @Inject(CognitoUtil) public cognitoUtil: CognitoUtil,
    public http: HttpClient,
    private cookie: CookieService
  ) {
    this._baseUrl = environment.user_baseUrl;
  }

  register(user: RegistrationUser, callback: CognitoCallback): void {
    console.log("UserRegistrationService: user is " + user);

    let attributeList = [];

    let dataEmail = {
      Name: "email",
      Value: user.email.toLowerCase()
    };
    let dataNickname = {
      Name: "name",
      Value: user.name
    };
    let dataLastname = {
      Name: "family_name",
      Value: user.family_name
    };
    let dataCompanyName = {
      Name: "custom:companyName",
      Value: user.companyName
    };

    attributeList.push(new CognitoUserAttribute(dataEmail));
    attributeList.push(new CognitoUserAttribute(dataNickname));
    attributeList.push(new CognitoUserAttribute(dataLastname));
    attributeList.push(new CognitoUserAttribute(dataCompanyName));
    attributeList.push(
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: user.phone_number
      })
    );

    this.cognitoUtil
      .getUserPool()
      .signUp(
        user.email.toLowerCase(),
        user.password,
        attributeList,
        null,
        function(err, result) {
          if (err) {
            callback.cognitoCallback(err.message, null);
          } else {
            console.log(
              "UserRegistrationService: registered user is " + result
            );
            callback.cognitoCallback(null, result);
          }
        }
      );
  }

  groupCallback(user: RegistrationUser): void {
    let addGroups = {
      Name: "groupname",
      Value: user.groupname
    };
    let user_service_baseUrlPlus =
      this._baseUrl + "/usertogroups?" + this.tokenparam;
    let params = new HttpParams()
      .append("userId", user.email.toLowerCase())
      .append("groups", addGroups.Value.join(",")); //Create new HttpParams
    this.http
      .get(user_service_baseUrlPlus, { params })
      .map((response: Response) => {
        console.log("response from groupCallback" + response.json());
        response.json();
      })
      .subscribe();
  }

  updateUser(user: RegistrationUser): void {
    let params = new HttpParams()
      .append("userId", user.email.toLowerCase())
      .append("name", user.name)
      .append("familyname", user.family_name)
      .append("companyname", user.companyName); //Create new HttpParams
    this.http
      .get(this._baseUrl + "updateuser?", { params })
      .map((response: Response) => {
        console.log("response from updateUser" + response.json());
        response.json();
      })
      .subscribe();
  }

  deleteUser(user: RegistrationUser): void {
    let params = new HttpParams().append("userId", user.email.toLowerCase()); //Create new HttpParams
    this.http
      .get(this._baseUrl + "deleteuser?", { params })
      .map((response: Response) => {
        console.log("response from deleteUser" + response.json());
        response.json();
      })
      .subscribe();
  }

  changePassword(userpass: NewPasswordUser): void {
    let accesstoken: string = sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token");
    let params = new HttpParams()
      .append("accesstoken", accesstoken)
      .append("previouspassword", userpass.existingPassword)
      .append("proposedpassword", userpass.password); //Create new HttpParams
    this.http
      .get(this._baseUrl + "changeuserpassword?", { params })
      .map((response: Response) => {
        console.log("response from Change Password" + response.json());
        response.json();
      })
      .subscribe();
  }

  confirmRegistration(
    username: string,
    confirmationCode: string,
    callback: CognitoCallback
  ): void {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true, function(
      err,
      result
    ) {
      if (err) {
        callback.cognitoCallback(err.message, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });
  }

  resendCode(username: string, callback: CognitoCallback): void {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode(function(err, result) {
      if (err) {
        callback.cognitoCallback(err.message, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });
  }

  newPassword(
    newPasswordUser: NewPasswordUser,
    callback: CognitoCallback
  ): void {
    console.log(newPasswordUser);
    // Get these details and call
    //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
    let authenticationData = {
      Username: newPasswordUser.username,
      Password: newPasswordUser.existingPassword
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: newPasswordUser.username,
      Pool: this.cognitoUtil.getUserPool()
    };

    console.log("UserLoginService: Params set...Authenticating the user");
    let cognitoUser = new CognitoUser(userData);
    console.log("UserLoginService: config is " + AWS.config);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        cognitoUser.completeNewPasswordChallenge(
          newPasswordUser.password,
          requiredAttributes,
          {
            onSuccess: function() {
              callback.cognitoCallback(null, userAttributes);
            },
            onFailure: function(err) {
              callback.cognitoCallback(err, null);
            }
          }
        );
      },
      onSuccess: function(result) {
        callback.cognitoCallback(null, result);
      },
      onFailure: function(err) {
        callback.cognitoCallback(err, null);
      }
    });
  }
}
