<div class="flex-container3">
  <mat-card class="item3card mat-elevation-z5">
    <mat-card-header>
      <mat-card-title class="textcolor1">{{pageTitle}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <form novalidate class="form-signin" method="POST" role="form" #regForm="ngForm" autocomplete="off"
        enctype="multipart/form-data">

        <div class="form-group">
          <fieldset>

            <mat-form-field>
              <mat-select class="form-control" placeholder="Application" id="appId" type="text" name="appId"
                [(ngModel)]="appId" #groupname="ngModel" required>
                <mat-option value="htatest">HTA Test</mat-option>
                <mat-option value="htaprod">HTA Prod</mat-option>
                <mat-option value="intel">Intellicenter</mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field *ngIf="appId">
              <mat-select placeholder="Operation" type="text" id="operation" name="operation" [(ngModel)]="operation"
                #groupname="ngModel" required>
                <mat-option value="create">Add Users</mat-option>
                <mat-option value="disable">Soft Delete Users</mat-option>
                <mat-option style="font-weight: bold;color: red;" value="delete">Delete Users
                </mat-option>
              </mat-select>
            </mat-form-field>

          </fieldset>

          <div><br /><br />
            <div style="text-align: left; font-weight: bold;color: grey; padding-left:20px;padding-bottom:20px;">Please
              upload lesser than 50 Users at a time</div>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;

              <input type="file" (change)="onFileSelected($event)" name="fileToUpload" [(ngModel)]="fileToUpload"
                id="fileToUpload" [disabled]="!regForm.valid">
              &nbsp;&nbsp;&nbsp;&nbsp;

              <button type="button" (click)="onUpload()" value="Upload File" name="submit"
                [disabled]="!regForm.valid">Upload</button>
              &nbsp;&nbsp;&nbsp;&nbsp;

              &nbsp;&nbsp;&nbsp;&nbsp;
              <button (click)="resetForm(regForm)"> Cancel </button>
              <br /><br />
            </span>
          </div>
        </div>
      </form>

      <div class="has-error"
        style="text-align: left; font-weight: bold;color: red; padding-bottom:20px; padding-left:20px;"
        *ngIf="errorMessage">{{errorMessage}}</div>
    </mat-card-content>
  </mat-card>
</div>
