import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";

@Component({
  selector: "consent-dialog",
  templateUrl: "consent-dialog.html"
})
export class ConsentDialog {
  constructor(public dialogRef: MatDialogRef<ConsentDialog>) {}
  firstLoad: boolean = true;
  ngOnInit() {
    window.scrollTo()
  }

  public confirmMessage: string;
  public confirmMessage2: string;
  public confirmMessage3: string;
  public confirmMessage4: string;
  public confirmMessage5: string;
  public confirmMessage6: string;
  public confirmMessage7: string;
  public confirmMessage8: string;
  public confirmMessage9: string;
  public confirmMessage10: string;
  public confirmMessage11: string;
  public confirmMessage12: string;
  public confirmMessage13: string;
  public confirmMessage14: string;
  public confirmMessage15: string;
  public confirmMessage16: string;
  public confirmMessage17: string;
  public confirmMessage18: string;
  public confirmMessage19: string;
  public confirmMessage20: string;
  public confirmMessage21: string;
  public confirmMessage22: string;
  public confirmMessage23: string;
  public confirmMessage24: string;
  public confirmMessage25: string;
  public confirmMessage26: string;
  public confirmMessage27: string;
  public confirmMessage28: string;
  public confirmMessage29: string;
  public confirmMessage30: string;
  public confirmMessage31: string;
  public confirmMessage32: string;
  public confirmMessage33: string;
  public confirmMessage34: string;
}
