import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatSort,
  MatTableDataSource,
  MatDialog,
  MatDialogRef
} from "@angular/material";
import { ProductService } from "./product.service";
import { SpinnerService } from "../spinner.service";
import { environment } from "../../environments/environment";
import { AuthorizationService } from "../shared/authorization.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog";
import { SavesearchDialog } from "../savesearch-dialog/savesearch-dialog";
import { DeleteconfirmationDialog } from "../deleteconfirmation-dialog/deleteconfirmation-dialog";
import { DashSearchService } from "../dashsearch/dashsearch.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-myservices",
  templateUrl: "./myservices.component.html",
  styleUrls: ["./myservices.component.scss"]
})
export class MyservicesComponent implements OnInit {
  public baseUrl: string = environment.product_service_baseUrl;
  public visibility: boolean = false;
  dialogRef: MatDialogRef<ConfirmationDialog>;

  color = "accent";
  mode = "determinate";
  value = 30;

  position = new FormControl("after");
  position2 = new FormControl("after");
  hideDelay = new FormControl(0);

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public _dashSearchService: DashSearchService,
    private productService: ProductService,
    private spinnerService: SpinnerService,
    private authorizationService: AuthorizationService,
    private route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog
  ) {}

  public baseUrl1: string =
    this.baseUrl + "/download?" + this.productService.tokenparam;

  getusename = this.productService.getUserName();

  public products = [];
  dataSource = new MatTableDataSource(this.products);

  displayedColumns: string[] = [
    "addTo",
    "rtFullname",
    "downloads",
    "serviceName",
    "serviceYear",
    "rtAgendaMonth"
  ];

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.spinnerService.visibility.subscribe(state => {
      this.visibility = state;
      // console.log('visibility', state);
    });

    this.spinnerService.notVisibility.subscribe(state => {
      this.visibility = state;
      // console.log('notVisibility', state);
    });

    this.productService
      .getMyServicesContact()
      .subscribe(data => (this.dataSource.data = data));
    console.log("dataSource--->" + this.dataSource.data);

    this.dataSource.sort = this.sort;
  }

  extension(str) {
    let ext = str.lastIndexOf(".");
    if (ext == -1) {
      return null;
    } else {
      if (str.slice(ext + 1, str.length) === "zip") return "data";
      else return " " + str.slice(++ext, str.length);
    }
  }

  extensionhtml(str) {
    let ext = str.split(".");
    if (ext[1] == null) {
      return str;
    } else {
      return ext[0];
    }
    // if (ext.length < 2)
    //   return str;
  }

  extensionhtmlt(str) {
    if (str) {
      let ext = str.split(".");
      if (ext[1] == null) {
        return true;
      } else {
        return false;
      }
      // if (ext.length < 2)
      //   return str;
    } else {
      return false;
    }
  }

  htmlfolderlink(str) {
    if (str == null) {
      return false;
    } else {
      return true;
    }
  }

  hasHtml(reportFiles) {
    let hasHtml = false;
    if (reportFiles === undefined) {
    } else {
      for (let i = 0; i < reportFiles.length; i++) {
        if (this.extensionhtmlt(reportFiles[i].filename)) {
          hasHtml = true;
          break;
        }
      }
    }
    return hasHtml;
  }

  hasReport(reportFiles) {
    let hasReport = false;
    if (reportFiles === undefined) {
    } else {
      for (let i = 0; i < reportFiles.length; i++) {
        hasReport = true;
        break;
      }
    }
    return hasReport;
  }

  subscribeButton = [{ toggle: false, name: "All Products" }];

  toggleSubscribeClicked(button) {
    button.toggle = !button.toggle;
    button.name = button.toggle ? "Subscribed Products" : "All Products";
  }

  toggleTreeClicked() {
    this.router.navigateByUrl("/products");
  }

  isAdmin() {
    return this.authorizationService.isAdmin();
  }

  forwardContactUs(service, year, report) {
    this.router.navigateByUrl(
      "/contactus?message=I would like more information about the report: " +
        service +
        " " +
        year +
        " " +
        report
    );
  }

  likeMe(i) {
    if (this.dataSource.data[i].favorite == false) {
      this.dataSource.data[i].favorite = true;
      this._dashSearchService
        .updatefavoriteservflat(this.dataSource.data[i])
        .subscribe();
    } else {
      if (this.dataSource.data[i].favorite == true) {
        this.dialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: false
        });
        this.dialogRef.componentInstance.confirmMessage =
          "Are you sure you want to remove this item from favorites?";
        this.dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._dashSearchService
              .deletefavoriteflat(this.dataSource.data[i])
              .subscribe();
            this.dataSource.data[i].favorite = false;
            this.dataSource.data[i].dashboard = false;
          }
          this.dialogRef = null;
        });
      }
    }
    if ("scrollRestoration" in history) {
      //Back off, browser, I got this...
      history.scrollRestoration = "manual";
    }
  }

  dashAdd(i) {
    if (this.dataSource.data[i].dashboard == false) {
      this.dataSource.data[i].dashboard = true;
      this.dataSource.data[i].favorite = true;
      this._dashSearchService
        .updatefavoriteservflat(this.dataSource.data[i])
        .subscribe();
    } else {
      if (this.dataSource.data[i].dashboard == true) {
        this.dialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: false
        });
        this.dialogRef.componentInstance.confirmMessage =
          "Are you sure you want to remove this item from the dashboard?";
        this.dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.dataSource.data[i].dashboard = false;
            this._dashSearchService
              .updatefavoriteservflat(this.dataSource.data[i])
              .subscribe();
            this.dataSource.data[i].dashboard = false;
          }
          this.dialogRef = null;
        });
      }
    }
  }
}
