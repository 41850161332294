import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserLoginService } from "../auth-servicecognito/user-login.service";
import { CognitoCallback } from "../auth-servicecognito/cognito.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"]
})
// export class ForgotComponent implements OnInit {
//   pageTitle = 'Forgot Password';
//   verificationCode: string;
//   email: string;
//   password: string;
//   errorMessage: string;
//   private sub: any;

//   constructor(private _router: Router) { }

//   ngOnInit() {
//   }
//   forgotPasswordForm(loginForm: NgForm) {
//     this._router.navigate(['/forgotPassword']);
//     loginForm.resetForm();
//  }

// }
export class ForgotComponent implements CognitoCallback {
  pageTitle = "Forgot Password";
  verificationCode: string;
  email: string;
  password: string;
  errorMessage: string;
  private sub: any;

  constructor(private router: Router, public userService: UserLoginService) {
    this.errorMessage = null;
  }

  onNext() {
    this.errorMessage = null;
    this.userService.forgotPassword(this.email.toLowerCase(), this);
  }

  cognitoCallback(message: string, result: any) {
    if (message == null && result == null) {
      //error
      this.router.navigate(["/forgotPassword", this.email.toLowerCase()]);
    } else {
      //success
      this.errorMessage = message;
    }
  }
}
