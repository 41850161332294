<h1 mat-dialog-title>Load Filter Settings</h1>


  <ng-container *ngFor="let filternamespace of loadFilters">
    
    <div *ngIf="filternamespace.viewName==viewName" mat-dialog-content>

  <div><span style="color:#153961;">Available settings for view <b>"{{filternamespace.viewName}}" </b></span><span style="color:#153961;font-size:10px;">(This View)</span></div>


  <!-- <input matInput [(ngModel)]="viewName" [value]="viewName" hidden> -->

    <mat-form-field>
        <mat-select class="dataviewsplaceholder" [(ngModel)]="filterName" #filters placeholder="Select Setting" required>
           
          <mat-option class="trunc" *ngFor="let filters of filternamespace.filters" [value]="filters.filterName" >{{ filters.filterName }}</mat-option>
        </mat-select> 
      </mat-form-field>

  </div>


</ng-container>

<div mat-dialog-actions>
    <button mat-button style="cursor: pointer;color: #fff;background-color: #153961;" [mat-dialog-close]="filterName" [disabled]="!filterName">Load</button>
    <button mat-button (click)="dialogRef3.close(false)">Cancel</button>
  </div>







