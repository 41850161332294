import {
  Component,
  ViewEncapsulation,
  OnInit,
  ViewChildren,
  QueryList,
  AfterViewInit
} from "@angular/core";
import { TooltipPosition, MatExpansionPanel } from "@angular/material";
import { FormControl } from "@angular/forms";
import { DashSearchService } from "../dashsearch/dashsearch.service";
import { ServicesService } from "../services/services.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GroupByPipe } from "../pipes/groupby.pipe";
// import {Http, Headers, RequestOptions} from '@angular/http';
import { environment } from "../../environments/environment";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog";
import { Idashsearchmodel } from "../dashsearch/dashsearchmodel";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SpinnerService } from "../spinner.service";

@Component({
  selector: "app-myfavorites",
  templateUrl: "./myfavorites.component.html",
  styleUrls: ["./myfavorites.component.scss"],
  providers: [DashSearchService, DashSearchService],
  encapsulation: ViewEncapsulation.None
})
export class MyfavoritesComponent implements OnInit {
  public visibility: boolean = false;
  color = "accent";
  mode = "determinate";
  value = 30;
  dialogRef: MatDialogRef<ConfirmationDialog>;
  dataRefresher: any;
  setPage: any;
  public show: boolean = true;
  public buttonName: any = "Show";
  public mobile: boolean = true;
  message = new FormControl("market");
  searchterm: string = "";
  position = new FormControl("after");
  position2 = new FormControl("after");
  hideDelay = new FormControl(0);
  queryString: any;
  panelOpenState = false;
  favorite: boolean;
  favo: boolean = true;
  baseURL: string;
  empty$: boolean;
  times = 0;
  theSearch: Observable<Idashsearchmodel[]>;
  favoritesemptyarea =
    'This page can be used to bookmark your favorite content.<br><br>Please use the <strong><i><a class="linkcolor" href="/#/search">search</a></i></strong> screen to find your favorite content and click the star icon to bookmark it on this page.';
  public baseUrl: string = environment.product_service_baseUrl;

  _allExpandState = false;
  public set allExpandState(value: boolean) {
    this._allExpandState = value;
    this.togglePanels(value);
  }

  public get allExpandState(): boolean {
    return this._allExpandState;
  }

  @ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;

  public togglePanels(value: boolean) {
    this.viewPanels.forEach(p => (value ? p.open() : p.close()));
  }

  constructor(
    public _dashSearchService: DashSearchService,
    private httpclient: HttpClient,
    public dialog: MatDialog,
    private spinnerService: SpinnerService
  ) {
    this.theSearch = this._dashSearchService.getThefaves();
    sessionStorage.removeItem("searchterm");
  }
  public baseUrl1: string =
    this.baseUrl + "/download?" + this._dashSearchService.tokenparam;

  getusename = this._dashSearchService.getUserName();
  public data: any;

  ngOnInit() {
    this.spinnerService.visibility.subscribe(state => {
      this.visibility = state;
    });

    this.spinnerService.notVisibility.subscribe(state => {
      this.visibility = state;
    });
  }

  reloadf() {
    this.theSearch = this._dashSearchService.getThefaves();
  }

  likeMe(i, favorite, therow) {
    if (favorite == false) {
      therow["favorite"] = true;
    } else {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: false
      });
      this.dialogRef.componentInstance.confirmMessage =
        "Are you sure you want to remove this item from favorites?";
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._dashSearchService.deletefavorite(therow).subscribe();
          therow.favorite = false;
          therow.dashboard = false;
        }
        this.dialogRef = null;
      });
    }
  }

  dashAdd(i, dashboard, therow) {
    if (dashboard == false) {
      therow["dashboard"] = true;
      this._dashSearchService.updatefavoriteserv(therow).subscribe();
    } else {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: false
      });
      this.dialogRef.componentInstance.confirmMessage =
        "Are you sure you want to remove this item from the dashboard?";
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          therow.dashboard = false;
          this._dashSearchService.updatefavoriteserv(therow).subscribe();
          therow.dashboard = false;
        }
        this.dialogRef = null;
      });
    }
  }

  htmlfolderlink(str) {
    if (str == null) {
      return false;
    } else {
      return true;
    }
  }

  htmlnoext(str) {
    let ext = str.split(".");
    if (ext[1] == null) {
      return true;
    } else {
      return false;
    }
  }

  extension(str) {
    let ext = str.lastIndexOf(".");
    if (ext == -1) {
      return null;
    } else {
      if (str.slice(ext + 1, str.length) === "zip") return " data";
      else return " " + str.slice(++ext, str.length);
    }
  }

  encript(str) {
    let str1 = btoa(str);
    return str1;
  }

  clear() {
    this.searchterm = "";
  }

  extensionhtmlt(str) {
    let ext = str.split(".");
    if (ext[1] == null) {
      return true;
    } else {
      return false;
    }
  }

  hasHtml(reportFiles) {
    let hasHtml = false;
    for (let i = 0; i < reportFiles.length; i++) {
      if (this.extensionhtmlt(reportFiles[i].filename)) {
        hasHtml = true;
        break;
      }
    }
    return hasHtml;
  }
}
