export class IUserDatamodel {
    username: string;
    attributes: Iattributessmodel[];
    userCreateDate: string;
    userLastModifiedDate: string;
    enabled: string;
    userStatus: string;
}
export class Iattributessmodel {
    name:string;
    value:string;
}