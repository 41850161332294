import { Component, Input, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl ,FormsModule,NgForm } from '@angular/forms';
import { SaveFiltersModel, filters } from '../dataviews/savefiltersmodel';
import { DataviewsService } from '../dataviews/dataviews.service';

@Component({
  selector: 'app-limit-dialog',
  templateUrl: 'loadfilters-dialog.html'
})
export class LoadFiltersDialog {
  Settings: string;
  filterName;
  constructor(public dialogRef3: MatDialogRef<LoadFiltersDialog>,
    @Inject(MAT_DIALOG_DATA) public filters: filters, public _dataviewsService: DataviewsService) {
      this.loadSaveSearches()
    }
    viewName = this._dataviewsService.viewName;
    
    public loadFilters = []

    loadSaveSearches(){
      
      this._dataviewsService.retrievefilters()
      .subscribe(data=>this.loadFilters = data);
      
      
    }  


  onNoClick(): void {
    this.dialogRef3.close();
  }

  public confirmMessage:string;
  public confirmMessage2:string;

}
