import {
  Component,
  ViewEncapsulation,
  OnInit,
  ViewChildren,
  QueryList,
  Inject,
  Input
} from "@angular/core";
import {
  TooltipPosition,
  MatExpansionPanel,
  MatMenuTrigger
} from "@angular/material";
import { FormControl } from "@angular/forms";
import { DashSearchService } from "./dashsearch.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GroupByPipe } from "../pipes/groupby.pipe";
// import {Http, Headers, RequestOptions} from '@angular/http';
import { environment } from "../../environments/environment";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs";
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
  MAT_DIALOG_DATA
} from "@angular/material";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog";
import { SavesearchDialog } from "../savesearch-dialog/savesearch-dialog";
import { DeleteconfirmationDialog } from "../deleteconfirmation-dialog/deleteconfirmation-dialog";
import { SaveSearchModel } from "./savesearchmodel";
import { SpinnerService } from "../spinner.service";
import { AuthorizationService } from "../shared/authorization.service";
import { HeaderserviceService } from "../header/headerservice.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-dashsearch",
  templateUrl: "./dashsearch.component.html",
  styleUrls: ["./dashsearch.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [DashSearchService]
  //providers:[GroupByPipe]
})
export class DashsearchComponent implements OnInit {
  // saveSearchModel: SaveSearchModel;
  dialogRef: MatDialogRef<ConfirmationDialog>;
  dialogRef2: MatDialogRef<SavesearchDialog>;
  dialogRef3: MatDialogRef<DeleteconfirmationDialog>;
  public show: boolean = true;
  public buttonName: any = "Show";
  public mobile: boolean = true;
  public visibility: boolean = false;
  color = "accent";
  mode = "determinate";
  value = 30;
  message = new FormControl("market");
  @Input() searchtermheader: string;
  searchterm: string = "";
  searchName1: string = "";
  position = new FormControl("after");
  position2 = new FormControl("after");
  hideDelay = new FormControl(0);
  queryString: any;
  panelOpenState = false;
  favorite: boolean = false;
  baseURL: string;
  empty$: boolean;
  searchText: string;
  searchName: string;
  userId: string;
  sentflag: boolean = false;
  isActive = false;
  items: Array<any> = [];
  searchinput: string;
  dataRefresher: any;
  public baseUrl: string = environment.product_service_baseUrl;
  xmessage: string;
  displayText = "I havent got an event yet";
  _allExpandState = false;
  public set allExpandState(value: boolean) {
    this._allExpandState = value;
    this.togglePanels(value);
  }

  public get allExpandState(): boolean {
    return this._allExpandState;
  }

  @ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;

  public togglePanels(value: boolean) {
    this.viewPanels.forEach(p => (value ? p.open() : p.close()));
  }

  constructor(
    public _dashSearchService: DashSearchService,
    private httpclient: HttpClient,
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private authorizationService: AuthorizationService,
    private _headerserviceService: HeaderserviceService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    // sessionStorage.removeItem('searchterm');
    this.items = [{}];

    // if (this.searchtermheader != undefined){
    //   this.searchterm = this.searchtermheader
    // }
    // sessionStorage.setItem("searchterm", this.searchterm)
  }

  public baseUrl1: string =
    this.baseUrl + "/download?";

  public baseUrl2: string =
  this.baseUrl + "/download?" + this._dashSearchService.tokenparam;  

  getusename = this._dashSearchService.getUserName();
  public theSearch = [];
  public savedSearch: Observable<SaveSearchModel[]>;

  ngOnInit() {
    this._headerserviceService.getEventSubject().subscribe((param: any) => {
      // alert(param)
      if (param !== undefined) {
        this.searchservicewithparam(param);
      }
    });

    this.spinnerService.visibility.subscribe(state => {
      this.visibility = state;
      // console.log('visibility', state);
    });

    this.spinnerService.notVisibility.subscribe(state => {
      this.visibility = state;
      // console.log('notVisibility', state);
    });

    // this.searchservice()
    sessionStorage.removeItem("searchterm");
    this.loadSaveSearches();
  }

  objectchange($event) {
    this.savedSearch = $event.target.value;
    let obj1 = JSON.stringify(this.savedSearch);
  }

  onUpload(searchinput) {
    this._dashSearchService.onUpload(searchinput).subscribe();
  }

  openDialog() {
    if (this.searchterm == "") {
    } else {
      this.dialogRef2 = this.dialog.open(SavesearchDialog, {
        disableClose: false,
        data: {
          userId: this.userId,
          searchName: this.searchName,
          searchText: this.searchText
        }
      });
      this.dialogRef2.componentInstance.confirmMessage =
        "Please Name your Search:";
      this.dialogRef2.componentInstance.confirmMessage2 = this.searchterm;

      this.dialogRef2.afterClosed().subscribe(result => {
        this.searchName = result;
        if (result) {
          this._dashSearchService
            .savesearchserv({
              userId: this.getusename,
              searchName: this.searchName,
              searchText: this.searchterm
            })
            .subscribe(
              res => {
                console.log(res);
              },
              err => {
                console.log("an error occured");
              }
            );
          if (this.searchName == "") {
          }
        }
        this.loadSaveSearches();
        this.dialogRef2 = null;
      });
    }
  }
  openedmenu: boolean;
  oversearch() {
    this.loadSaveSearches();
    this.openedmenu = true;
  }

  loadSaveSearches() {
    this.savedSearch = this._dashSearchService.getTheSavedSearch();
    // .subscribe(data=>this.savedSearch = data);
  }

  reloadf() {
    this._dashSearchService
      .getTheSearch(this.searchterm)
      .subscribe(data => (this.theSearch = data));
  }

  likeMe(i) {
    if (this.theSearch[i].favorite == false) {
      this.theSearch[i].favorite = true;
      this._dashSearchService.updatefavoriteserv(this.theSearch[i]).subscribe();
    } else {
      if (this.theSearch[i].favorite == true) {
        this.dialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: false
        });
        this.dialogRef.componentInstance.confirmMessage =
          "Are you sure you want to remove this item from favorites?";
        this.dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._dashSearchService
              .deletefavorite(this.theSearch[i])
              .subscribe();
            this.theSearch[i].favorite = false;
            this.theSearch[i].dashboard = false;
          }
          this.dialogRef = null;
        });
      }
    }
    if ("scrollRestoration" in history) {
      //Back off, browser, I got this...
      history.scrollRestoration = "manual";
    }
  }

  dashAdd(i) {
    if (this.theSearch[i].dashboard == false) {
      this.theSearch[i].dashboard = true;
      this.theSearch[i].favorite = true;
      this._dashSearchService.updatefavoriteserv(this.theSearch[i]).subscribe();
    } else {
      if (this.theSearch[i].dashboard == true) {
        this.dialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: false
        });
        this.dialogRef.componentInstance.confirmMessage =
          "Are you sure you want to remove this item from the dashboard?";
        this.dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.theSearch[i].dashboard = false;
            this._dashSearchService
              .updatefavoriteserv(this.theSearch[i])
              .subscribe();
            this.theSearch[i].dashboard = false;
          }
          this.dialogRef = null;
        });
      }
    }
  }

  htmlfolderlink(str) {
    if (str == null) {
      return false;
    } else {
      return true;
    }
  }

  htmlnoext(str) {
    let ext = str.split(".");
    if (ext[1] == null) {
      return true;
    } else {
      return false;
    }
  }

  extension(str) {
    let ext = str.lastIndexOf(".");
    if (ext == -1) {
      return null;
    } else {
      if (str.slice(ext + 1, str.length) === "zip") return " data";
      else return " " + str.slice(++ext, str.length);
    }
  }

  encript(str) {
    let str1 = btoa(str);
    return str1;
  }

  clearsearchterm() {
    this.searchterm = "";
    this.sentflag = false;
    this.theSearch = null;
  }

  clear_delete(searchName, searchText) {
    // this.searchterm = '';

    if (searchName == "") {
    } else {
      this.dialogRef3 = this.dialog.open(DeleteconfirmationDialog, {
        disableClose: false,
        data: { searchName: searchName, searchText: searchText }
      });
      this.dialogRef3.componentInstance.confirmMessage =
        "Are you sure you want to delete?:";
      this.dialogRef3.componentInstance.confirmMessage2 = searchText;
      this.dialogRef3.componentInstance.confirmMessage3 = searchName;

      this.dialogRef3.afterClosed().subscribe(result => {
        this.searchName = result;

        if (result) {
          this._dashSearchService
            .deleteSavesearch(searchName, searchText)
            .subscribe(
              res => {
                this.loadSaveSearches();
                console.log(res);
              },
              err => {
                console.log("an error occured");
              }
            );
          if (this.searchName == "") {
          }
        }

        this.searchText = "";
        this.theSearch = [];
        this.dialogRef3 = null;
      });
    }
  }

  // menuTrigger;
  searchservice() {
    this._dashSearchService
      .searchservicewithparam(this.searchterm)
      .subscribe(theSearch => (this.theSearch = theSearch));
    this.sentflag = true;
    // sessionStorage.setItem('searchterm', this.searchterm);
  }
  searchservicewithparam(searchterm) {
    this.searchterm = searchterm;
    this._dashSearchService
      .searchservicewithparam(searchterm)
      .subscribe(theSearch => (this.theSearch = theSearch));
    this.sentflag = true;
    // sessionStorage.setItem('searchterm', this.searchterm);
    this._dashSearchService.getTheSearch(searchterm);
    // .subscribe(data=>this.theSearch = data);
    this.openedmenu = false;
  }

  subscribeButton = [{ toggle: false, name: "All Products" }];

  toggleSubscribeClicked(button) {
    button.toggle = !button.toggle;
    button.name = button.toggle ? "Subscribed Products" : "All Products";
  }

  isAdmin() {
    return this.authorizationService.isAdmin();
  }

  getSearchResultCount() {
    let count = 0;
    if (typeof this.theSearch == "undefined") {
      count = 0;
    } else if (this.subscribeButton[0].toggle == false) {
      count = this.theSearch.length;
    } else {
      this.theSearch.forEach(search => {
        if (search.purchased == true) {
          count++;
        }
      });
    }
    return count;
  }

  extensionhtmlt(str) {
    let ext = str.split(".");
    if (ext[1] == null) {
      return true;
    } else {
      return false;
    }
  }

  hasHtml(reportFiles) {
    let hasHtml = false;
    for (let i = 0; i < reportFiles.length; i++) {
      if (this.extensionhtmlt(reportFiles[i].filename)) {
        hasHtml = true;
        break;
      }
    }
    return hasHtml;
  }

  forwardContactUs(service, year, report) {
    this.router.navigateByUrl(
      "/contactus?message=I would like more information about the report: " +
        service +
        " " +
        year +
        " " +
        report
    );
  }

  downloadFile(userId: string, year: string ,service: string, reportType: string, fileName: string, czip: boolean): void {
    var encodedFileName = fileName.replace('&','%26');
    this._dashSearchService.downloadFile(this.baseUrl1+"userId="+userId+"&service="+service+"&year="+year+"&reportType="+reportType+"&filename="+encodedFileName+"&czip="+czip+"")
      .subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        if(!fileName.includes("pdf")){
        a.href = objectUrl
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
        }else{
          window.open(objectUrl, '_blank');
        }
      })
  }
}
