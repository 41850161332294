<div class="container coming" style="margin-bottom: 80px;padding: 0% 4% 0% 4%;">

  <div class="text-center pd-10">
    <p joyrideStep="thirdStep" [stepContent]="customContent1" class="h5 mrb-0" style="color: #9d9689;">Coming Soon</p>
    <ng-template #customContent1>
      ... Insert whatever you'd like to ...
  </ng-template>
  </div>

  <div *ngIf="visibility">
    <div class="centerloading">
      <mat-spinner class="spinner-margin" [color]="color" [mode]="mode" [value]="value"></mat-spinner>
    </div>
  </div>
  <div *ngIf="servicesContact.length != 0">

    <div *ngFor="let toggleButton of subscribeButton">

      <mat-card class="hsgservicescard">

        <mat-card-title class="textcolorabout">
          <span *ngIf="!isAdmin()">
            <span joyrideStep="fourthStep"  [class.togglelabel]="toggleBehav" [class.togglelabelhighlight]="!toggleBehav">
              Subscribed Products </span>
            <mat-slide-toggle labelPosition="" [(ngModel)]="toggleBehav" mat-raised-button
              (click)="toggleSubscribeClicked(toggleButton)">
            </mat-slide-toggle>
            <span [class.togglelabel]="!toggleBehav" [class.togglelabelhighlight]="toggleBehav">
              All Products</span>
          </span>
          <span *ngIf="isAdmin()">
            <span>All Products</span>
          </span>
        </mat-card-title>
      </mat-card>
      <mat-accordion #secondAccordion="matAccordion">
        <div class="row rowborder1">
          <div class="indent1 twofixed1 columns productlinetopstyle"> Product Line</div>
        </div>

        <div class="row rowborder2">
          <div class="indent2 twofixed1 columns productlinetopstyle"> Service Name</div>
        </div>

        <div class="row rowborder">
          <div class="indent3 twofixed1 columns topstyle4"><i>Product Name</i></div>
          <div class="indent twofixedyear columns topstyle4">Year</div>
          <!--          <div class="twofixed2hides columns topstyle4">Categories</div> -->
          <div class="indent twofixed2hides columns topstyle4">Month</div>
          <div class="twofixed2hides columns topstyle4">Downloads</div>
        </div>
        <!-- Start to pull data from API -->
        <div *ngFor="let serviceandcontact of servicesContact  | FilterPipe: queryString">
          <div *ngFor="let productLine of serviceandcontact.productLines">
            <span *ngIf="toggleBehav || productLine.purchased == true">
              <mat-expansion-panel class="productline rowborder">
                <mat-expansion-panel-header>
                  <div class="productlinelowstyle indent11">{{productLine.name }}<span
                      *ngIf="productLine.name == null || productLine.name == ''">Non-Associated
                      Product Line</span></div>
                </mat-expansion-panel-header>

                <div *ngFor="let service of productLine.services">
                  <span *ngIf="toggleBehav || service.purchased == true">
                    <!-- servicetitles -->
                    <mat-accordion>
                      <mat-expansion-panel class="rowborder">
                        <mat-expansion-panel-header>
                          <div class="row" matTooltip="{{service.abstractStr}}">
                            <div class="topstyle2 indent21">{{service.name}} </div>
                          </div>
                        </mat-expansion-panel-header>
                        <!-- servicetitles -->
                        <div class="servicenames">
                          <!-- SERVICE UNIT starts -->
                          <div *ngFor="let years of service.serviceYears">
                            <div *ngFor="let reportType of years.reportTypes">

                              <div *ngIf="toggleBehav || reportType.purchased == true" class="indent4 row report">

                                <div *ngIf="reportType.reportFiles.length == 0 || !hasHtml(reportType.reportFiles)"
                                  class="twofixed1 columns indent31 textcolor"><i>
                                    {{reportType.fullname}}
                                  </i></div>
                                <div *ngIf="reportType.reportFiles.length > 0"><i>

                                    <div *ngFor="let reportFile of reportType.reportFiles"
                                      (contextmenu)="onContextMenu($event, domainUrl + '#/login/?source=eAnnouncement&service=' + service.family + '&year=' + years.year + '&reportType=' + reportType.name + '&filename=' + reportFile.filename)"
                                      class="twofixedblue columns indent31"><i>

                                        <!-- for template engine-->
                                        <div
                                          *ngIf="extensionhtmlt(reportFile.filename) && !(reportType.name.includes('_Patients_') || reportType.name.includes('_Providers_'))">
                                          <a class="linkcolor"
                                            href="{{baseUrl1}}&userId={{getusename}}&service={{service.family}}&year={{years.year}}&reportType={{reportType.name}}&filename={{reportFile.filename}}"
                                            target='_blank'>{{reportType.fullname}}</a>
                                        </div>
                                        <div
                                          *ngIf="extensionhtmlt(reportFile.filename) && (reportType.name.includes('_Patients_') || reportType.name.includes('_Providers_'))">
                                          <a class="linkcolor"
                                            href="{{baseTemplateUrl}}&userId={{getusename}}&service={{service.family}}&year={{years.year}}&reportType={{reportType.name}}&filename={{reportFile.filename}}"
                                            target='_blank'>{{reportType.fullname}}</a>
                                        </div>
                                      </i></div>
                                  </i></div>

                                <span class="twofixedyearstay columns yearstyle"><span
                                    class="categoryhides textcolor">Year:
                                  </span>{{years.year}}</span>
                                <span
                                  *ngIf="reportType.agendaMonth != null || reportType.agendaMonth != '' || reportType.agendaMonth != ' '"
                                  class="twofixedyearstay columns yearstyle"><span class="categoryhides textcolor">
                                    AgendaMonth:
                                  </span>{{reportType.agendaMonth}}</span>
                                <span *ngIf="!reportType.agendaMonth" class="twofixedyearstay columns yearstyle"><span
                                    class="categoryhides textcolor">
                                  </span>-</span>
                                <!--
                                <span class="twofixed2 columns">
                                  <div *ngIf="reportType.categories.length == 0">
                                    &nbsp;
                                  </div>
                                  <div *ngFor="let category of reportType.categories">

                                    <div class="categoryhides textcolor">Categories:</div>
                                    <span class="categoriesall onefix column">

                                      {{category.name}}

                                    </span>
                                  </div>
                                </span>
-->
                                <span>
                                  <div class="twofixed2blue columns rowed">
                                    <ul class="paralel" *ngFor="let reportFile of reportType.reportFiles"
                                      (contextmenu)="onContextMenu($event, domainUrl + '#/login/?source=eAnnouncement&service=' + service.family + '&year=' + years.year + '&reportType=' + reportType.name + '&filename=' + reportFile.filename)">

                                      <span><a class="linkcolor"
                                          href="{{baseUrl1}}&userId={{getusename}}&service={{service.family}}&year={{years.year}}&reportType={{reportType.name}}&filename={{reportFile.filename}}"
                                          target='_blank'>{{extension(reportFile.filename)}}</a>

                                      </span>
                                    </ul>
                                  </div>
                                  <div *ngIf="reportType.purchased == false">
                                    <span class="moreinfo">
                                      <button mat-raised-button
                                        (click)="forwardContactUs(service.name, years.year, reportType.fullname)"
                                        type="submit" class="small" color="accent">LEARN MORE</button>
                                    </span>
                                  </div>
                                  <div *ngIf="reportType.purchased == true && reportType.reportFiles.length == 0">
                                    <span class="moreinfo">
                                      <div>Coming Soon</div>
                                    </span>
                                  </div>
                                </span>

                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>


                    <!-- SERVICE UNIT ends-->
                  </span>
                </div>

              </mat-expansion-panel>


            </span>
          </div>

        </div>
        <!----------hhheeerrreee-->

      </mat-accordion>
      <div *ngIf="isAdmin()" style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
        [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
          <button class="menu" style="border: none" (click)="onContextMenuAction(item)">Copy Link</button>
        </ng-template>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="(visibility==false)&&(!servicesContact)">
    <mat-card-title class="textcolorabout">User has no subscribed Products</mat-card-title>
  </div>
</div>