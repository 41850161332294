<div class="container">
  <mat-card class="hsgservicescard">
    <mat-card-title class="textcolor1">{{pageTitle}}</mat-card-title>
    <mat-card-content class="topentries">

    </mat-card-content>
  </mat-card>
  <!-- <div class="row">
      <div class="twofixed1 columns topstyle"> User Name</div>
      <div class="twofixed columns topstyle4"></div>    
    </div> -->

  <div class="row">

    <div class="indent twofixedyear typetext columns bkggray topstyleyear">User Name</div>
    <div class="indent twofixedyear typetext2 columns bkggray topstyleyear">First Name</div>
    <div class="indent twofixedyear typetext2 columns bkggray topstyleyear">Last Name</div>
    <div class="indent twofixedyear typetext2 columns bkggray topstyleyear2">Company Name</div>
    <div class="indent twofixedyear typetext columns bkggray topstyleyear">Phone Number</div>
    <div class="twofixed2hides columns bkggray topstyle4">&nbsp;</div>
  </div>

  <div class="row">
    <div class="twofixed1 columns topstylereset2"> </div>
  </div>

  <div class="row tan border">

    
    <!-- Start to pull data from API -->
    
    <div *ngFor="let userDataItems of userData  | FilterPipe: queryString">
      <form novalidate class="form-signin" method="POST" action="#" role="form" #regForm="ngForm" autocomplete="off">
      <span class="twofixedyearstay typetext columns midelemstyle">
        <span class="categoryhides">
          <div class="labelhides">Username:</div>
        </span>
        <input matInput class="dashboard username form-control" [(ngModel)]="userDataItems.username" type="text" maxlength="50" minlength="3"
          placeholder="Username" name="username" disabled />
      </span>

      <div *ngFor="let attributes of userDataItems.attributes">
        <span *ngIf="(attributes.name === 'name')" class="twofixedyearstay typetext2 columns midelemstyle">
          <span class="categoryhides">
            <div class="labelhides">Firstname:</div>
          </span>
          <input matInput class="dashboard firstname form-control" [(ngModel)]="attributes.value" type="text" maxlength="20" minlength="3"
            placeholder="Firstname" name="name" />
        </span>

      </div>
      <div *ngFor="let attributes of userDataItems.attributes">
        <span *ngIf="(attributes.name === 'family_name')" class="twofixedyearstay typetext2 columns midelemstyle">
          <span class="categoryhides">
            <div class="labelhides">Lastname:</div>
          </span>
          <input matInput class="dashboard lastname form-control" [(ngModel)]="attributes.value" type="text" maxlength="20" minlength="3"
            placeholder="Lastname" name="family_name" />
        </span>

      </div>
      <div *ngFor="let attributes of userDataItems.attributes">
        <span *ngIf="(attributes.name == 'custom:companyName')" class="twofixedyearstay typetext2 columns midelemstyle">
          <span class="categoryhides">
            <div class="labelhides">Company Name:</div>
          </span>
          <input matInput class="dashboard company form-control" [(ngModel)]="attributes.value" type="text" maxlength="50" minlength="3"
            placeholder="Company Name" name="custom:companyName" />
        </span>

      </div>
      <!-- <div *ngFor="let attributes of userDataItems.attributes">
        <span *ngIf="(attributes.name === 'email')" class="twofixedyearstay typetext columns midelemstyle">
          <span class="categoryhides">
            <div class="labelhides">email:</div>
          </span>
          <input matInput class="dashboard email form-control" [(ngModel)]="attributes.value" type="text" maxlength="50" minlength="3"
            placeholder="email" name="email" />
        </span>
      </div> -->
      <div *ngFor="let attributes of userDataItems.attributes" >             
          <span *ngIf="(attributes.name === 'phone_number')" class="twofixedyearstay typetext columns midelemstyle">
             <span class="categoryhides">
               <div class="labelhides">Phone Number:</div></span>
           <input matInput class="dashboard form-control" [(ngModel)]="attributes.value" type="text" maxlength="16" minlength="3" placeholder="Phone Number" name="phone_number"  />
          </span>            
         </div>            

      <span class="twofixedyearstay columns midelemstyle">
          <span *ngIf="resetverify[userDataItems.username]"><div class='updateresult'>{{resetnote}}</div></span>
        <button class='dashboard' (click)="resetUser(userDataItems); resetverify[userDataItems.username] = !resetverify[userDataItems.username]" mat-raised-button color="accent">Reset Password</button>
      </span>
      <span class="twofixedyearstay typetext2 columns midelemstyle">
          <span *ngIf="updateverify[userDataItems.username]"><div class='updateresult'>{{updatenote}}</div></span>
        <button class='dashboard'  (click)="updateUser(userDataItems); updateverify[userDataItems.username] = !updateverify[userDataItems.username]" mat-raised-button color="accent">Update User</button>
      </span>
      <span class="twofixedyearstay typetext2 columns midelemstyle">
          <span *ngIf="deleteverify[userDataItems.username]"><div class='updateresult'>{{deletenote}}</div></span>
        <button class='dashboard' (click)="deleteUser(userDataItems); deleteverify[userDataItems.username] = !deleteverify[userDataItems.username]" mat-raised-button color="accent">Delete User</button>
      </span>
    </form>

      <div class="division"></div>

    </div>

  

  </div>



</div>
