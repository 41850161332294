import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/publishReplay";
import { environment } from "../../environments/environment";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ProductService {
  tokenparam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token")) ;

  getUserName(): string {
    let userName: string = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username")  ;
    const username: string = userName;
    return username;
  }

  private baseUrl: string = environment.product_service_baseUrl;
  private _url: string = this.baseUrl + "/product";
  private _myurl: string = this.baseUrl + "/product?" + this.tokenparam;

  constructor(private http: HttpClient, private cookie: CookieService) {}

  public getMyServicesContact(): Observable<any> {
    let savedsearchURL1 = `${this.baseUrl}/productFlat?${this.tokenparam}`;
    return this.http.get(savedsearchURL1).catch(this.errorHandler);
  }

  public getServicesContact(): Observable<any> {
    let savedsearchURL2 = `${this.baseUrl}/productFlat`;
    return this.http.get(savedsearchURL2).catch(this.errorHandler);
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error");
  }
}
