<form class="form-signin" method="POST" action="#" role="form">
    <div class="form-group">
        <h2>Resend Code</h2>
    </div>
    <div *ngIf="errorMessage!=null" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div class="form-group">
        <label class="control-label" for="signupEmail">Email</label>
        <input id="signupEmail" type="email" maxlength="50" class="form-control" [(ngModel)]="email"
               [ngModelOptions]="{standalone: true}">
    </div>
    <div class="form-group">
        <button (click)="resendCode()" id="signupSubmit" type="submit" class="btn btn-info btn-block">
            Resend Code
        </button>
    </div>

    <hr>
    <p><a [routerLink]="['/register']">Register</a> <a
            [routerLink]="['/login']">Login</a>
    </p>
</form>
