import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash'; 
// declare var _: any; // lodash, not strictly typed
@Pipe({
  name: 'filterunique',
  pure: false
})
export class FilteruniquePipe implements PipeTransform {
  transform(items: any[], args: any): any {

    // lodash uniqBy function
    return _.uniqBy(items, args);
}
}