import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class HeaderserviceService {

  constructor(private cookie: CookieService) { }
  consent = sessionStorage.getItem('consent')  ? sessionStorage.getItem('consent') : localStorage.getItem('consent');

  private eventSubject = new BehaviorSubject<any>(undefined);

  triggerSomeEvent(param: any) {
      this.eventSubject.next(param);
  }
  getEventSubject(): BehaviorSubject<any> {
    
    return this.eventSubject;
  }

  private consentSubject = new BehaviorSubject<any>(undefined);  

triggerConsent(param: any) {
  // alert("consent trig:"+param)
    this.consentSubject.next(param);
}
getConsentSubject(): BehaviorSubject<any> {
  // alert("consent retrieved:"+ JSON.stringify(this.consentSubject))
  return this.consentSubject;
}

}

