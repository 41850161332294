<div class="welcome-title">
  Trends Suite
</div>

<div class="flex-container-oncology">
  <div class="oncology-suite">

    <button class="oncology-btn" (click)="onAdClick('CPST')">
      Consumer Product Selection Trends 
    </button>

    <button class="oncology-btn" (click)="onAdClick('ETMD')">
      Employer Trends and Management Decisions
    </button>

    <button class="oncology-btn" (click)="onAdClick('LPAT')">
      Leading Payer Account Trends 
    </button>

    <button class="oncology-btn" (click)="onAdClick('MGT')">
      Medical Group Trends
    </button>

    <button class="oncology-btn" (click)="onAdClick('OCAT')">
      Organized Customer Account Trends
    </button>

    <button class="oncology-btn" (click)="onAdClick('RAT')">
      Regional Account Trends
    </button>

  </div>
</div>
