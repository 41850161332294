import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Location } from '@angular/common';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  ActivatedRoute,
  RoutesRecognized,
} from "@angular/router";
import { AuthService } from "../auth-user/auth.service";
import { AuthorizationService } from "../shared/authorization.service";
import { SharedDirective } from "../shared/shared.directive";
import {
  CognitoUser,
  CognitoUserSession,
  CognitoAccessToken,
  CognitoUserPool,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import { UserLoginService } from "../auth-servicecognito/user-login.service";
import {
  ChallengeParameters,
  CognitoCallback,
  LoggedInCallback,
  CognitoUtil,
} from "../auth-servicecognito/cognito.service";
import { Observable, Observer } from "rxjs";
import { Compiler } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfirmationTimeoutDialog } from "../confirmation-timeout-dialog/confirmation-dialog";
// import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import { DashboardservicesService } from "../services/dashboardservices.service";
// import {Keepalive} from '@ng-idle/keepalive';
// import { CognitoHelper } from '../shared/helpers/cognito.helper';

// External modules
import { Subscription } from "rxjs";
import { Idle } from "@ng-idle/core";
import { DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { callbackify } from "util";
import { MydashboardComponent } from "../mydashboard/mydashboard.component";
import { DashsearchComponent } from "../dashsearch/dashsearch.component";
import { DashSearchService } from "../dashsearch/dashsearch.service";
import { SaveSearchModel } from "../dashsearch/savesearchmodel";
import { SavesearchDialog } from "../savesearch-dialog/savesearch-dialog";
import { DeleteconfirmationDialog } from "../deleteconfirmation-dialog/deleteconfirmation-dialog";
import { HttpClient } from "@angular/common/http";
import { HeaderserviceService } from "./headerservice.service";
import { environment } from "../../environments/environment";
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { JoyrideService } from 'ngx-joyride';
// Helpers

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements LoggedInCallback, OnInit, OnDestroy {
  public isAuthenticated: boolean = false;
  public dataPromote: boolean =
    environment.data_promote.toLowerCase() == "true" ? true : false;
  searchterm: string = "";
  dialogRef2: MatDialogRef<SavesearchDialog>;
  dialogRef3: MatDialogRef<DeleteconfirmationDialog>;
  searchText: string;
  searchName: string;
  userId: string;
  sentflag: boolean = false;
  // Session with : @ng-idle/core - @ng-idle/keepalive - @caliatys/cognito-service
  public idleState: string = "Not started.";
  public timedOut: boolean = false;
  public lastPing?: Date = null;
  xmessage: string;
  displayText = "I havent created any events yet.";
  currentRoute: string;

  // @ViewChild(MydashboardComponent) private  mydashboardComponent: MydashboardComponent;
  // @ViewChild(DashsearchComponent)  private dashsearchComponent: DashsearchComponent;

  // Subscriptions
  private signInSub: Subscription;
  private signOutSub: Subscription;

  hsg_logo = "/portal/assets/images/IntelliCenter_logo_300.png";
  // hsg_logo = "/assets/images/Intellicenter_logo.png";
  dialogRef: MatDialogRef<ConfirmationTimeoutDialog>;
  currentYear = new Date().getFullYear();
  color = "accent";
  loading: boolean = true;
  AuthService: string;
  permissionToRegister: string[] = ["admin"];

  constructor(
    public authService: AuthService,
    private auth: AuthorizationService,
    public userService: UserLoginService,
    private router: Router,
    private _SharedDirective: SharedDirective,
    private _compiler: Compiler,
    private idle: Idle,
    private keepalive: Keepalive,
    public dialog: MatDialog,
    public dashboardservicesService: DashboardservicesService,
    public _dashSearchService: DashSearchService,
    private http: HttpClient,
    public cognitoUtil: CognitoUtil,
    private _headerserviceService: HeaderserviceService,
    private cookie: CookieService,
    private _location: Location,
    private readonly joyrideService: JoyrideService
  ) {

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event['url'];
      }
    });

    this.setIdledata();
    this.consent = sessionStorage.getItem("consent") ? sessionStorage.getItem("consent") : localStorage.getItem("consent");
    this.consent = localStorage.getItem("consent") ? localStorage.getItem("consent") : localStorage.getItem("consent");
    // if(this.isAuthenticated == true) {
    //   console.log('User is Authenticated isAuthenticated == true')
    //   this.setIdledata()
    //     } else if(this.isAuthenticated == false) {
    //   console.log('User is not Authenticated if')
    //     // this.logOut()
    //    this.idleState = 'Not started.';
    //     }
  }
  getusename = this._dashSearchService.getUserName();
  theSearch = [];
  public savedSearch: Observable<SaveSearchModel[]>;

  btnTour_click() {
    this.joyrideService.startTour(
      {
        steps: ['zeroStep','firstStep@menu', 'secondStep@menu', 'thirdStep@products', 'fourthStep', 'fifthStep',
         'sixthStep@menu', 'seventhStep@menu', 'eigthStep', 'ninthStep@myfavorites', 'tenthStep@mydashboard', 'lastStep@menu'],
        themeColor: '#212f23',
        customTexts: {
          next: '>>',
          prev: '<<',
          done: 'OK'
        },
      },
    );
  }

  triggerAnEvent(searchterm) {
    this._headerserviceService.triggerSomeEvent(searchterm);
    this.displayText = "I fired an event.";
    this.searchterm = "";
    //this.router.navigate(["search", searchterm]);
  }

  onUpload(searchinput) {
    this._dashSearchService.onUpload(searchinput).subscribe();
  }

  searchservice(searchterm) {
    this._dashSearchService
      .searchservicewithparam(searchterm)
      .subscribe((theSearch) => (this.theSearch = theSearch));
    this.sentflag = true;
    sessionStorage.setItem("searchterm", searchterm);
    localStorage.setItem("searchterm", searchterm);
  }

  btnBack_click() {
    this._location.back();
  }

  objectchange($event) {
    this.savedSearch = $event.target.value;
    let obj1 = JSON.stringify(this.savedSearch);
    // alert(obj1)
  }

  loadSaveSearches() {
    this.savedSearch = this._dashSearchService.getTheSavedSearch();
    // .subscribe(data=>this.savedSearch = data);
  }

  searchservicewithparam(searchterm) {
    // this.searchterm = searchterm;
    this._dashSearchService
      .searchservicewithparam(searchterm)
      .subscribe((theSearch) => (this.theSearch = theSearch));
    this.sentflag = true;
    sessionStorage.setItem("searchterm", searchterm);
    localStorage.setItem("searchterm", searchterm);
    this._dashSearchService.getTheSearch(searchterm);
    // .subscribe(data=>this.theSearch = data);
  }

  oversearch() {
    this.loadSaveSearches();
  }

  openDialog() {
    if (this.searchterm == "") {
    } else {
      this.dialogRef2 = this.dialog.open(SavesearchDialog, {
        disableClose: false,
        data: {
          userId: this.userId,
          searchName: this.searchName,
          searchText: this.searchText,
        },
      });
      this.dialogRef2.componentInstance.confirmMessage =
        "Please Name your Search:";
      this.dialogRef2.componentInstance.confirmMessage2 = this.searchterm;

      this.dialogRef2.afterClosed().subscribe((result) => {
        this.searchName = result;
        if (result) {
          this._dashSearchService
            .savesearchserv({
              userId: this.getusename,
              searchName: this.searchName,
              searchText: this.searchterm,
            })
            .subscribe(
              (res) => {
                console.log(res);
              },
              (err) => {
                console.log("an error occured");
              }
            );
          if (this.searchName == "") {
          }
        }
        this.loadSaveSearches();
        this.dialogRef2 = null;
      });
    }
  }

  clear_delete(searchName, searchText) {
    // this.searchterm = '';

    if (searchName == "") {
    } else {
      this.dialogRef3 = this.dialog.open(DeleteconfirmationDialog, {
        disableClose: false,
        data: { searchName: searchName, searchText: searchText },
      });
      this.dialogRef3.componentInstance.confirmMessage =
        "Are you sure you want to delete?:";
      this.dialogRef3.componentInstance.confirmMessage2 = searchText;
      this.dialogRef3.componentInstance.confirmMessage3 = searchName;

      this.dialogRef3.afterClosed().subscribe((result) => {
        this.searchName = result;

        if (result) {
          this._dashSearchService
            .deleteSavesearch(searchName, searchText)
            .subscribe(
              (res) => {
                this.loadSaveSearches();
                console.log(res);
              },
              (err) => {
                console.log("an error occured");
              }
            );
          if (this.searchName == "") {
          }
        }

        this.searchText = "";
        this.theSearch = [];
        this.dialogRef3 = null;
      });
    }
  }

  spinnerx() {
    this.dashboardservicesService.test1();
    // this.mydashboardComponent.visibility = true;
  }

  getUserPool() {
    return new CognitoUserPool(CognitoUtil._POOL_DATA);
  }
  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  public getAccessToken(): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      if (this.getCurrentUser() != null) {
        this.getCurrentUser().getSession((err, session) => {
          if (err) {
            console.log("CognitoUtil: Can't set the credentials:" + err);
            observer.error(err);
          } else {
            if (session.isValid()) {
              console.log("CognitoUtil: User Is Logged In" + session.isValid());
              observer.next(session.getAccessToken().getJwtToken());
              observer.complete();
            }
          }
        });
      } else {
        observer.error("CognitoUtil: User Is Not Logged In");
      }
    });
  }

  cantsee(a) {
    let string = sessionStorage.getItem("groups") ? sessionStorage.getItem("groups") : localStorage.getItem("groups");
    if (typeof string != "undefined" && string) {
      var groupdata = JSON.parse(string);
      for (var i = 0; i < groupdata.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (groupdata[i] == a[j]) {
            return groupdata[i];
          }
        }
      }
    }
  }

  // cantsee(a){
  //   this._SharedDirective.cantsee(a);
  // }

  checkRouterEvent(routerEvent: Event): void {
    this.isLoggedIn();
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  getTheUser(): string {
    let firstname: string = sessionStorage.getItem("firstname") ? sessionStorage.getItem("firstname") : localStorage.getItem("firstname");
    let lastname: string = sessionStorage.getItem("lastname") ? sessionStorage.getItem("lastname") : localStorage.getItem("lastname");
    const fullname: string = firstname + " " + lastname;
    return fullname;
  }

  getUserName() {
    return sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username");
  }

  isLoggedIn() {
    this.getAccessToken();
    if (!this.getAccessToken()) {
      // alert("logout!");
      // this.router.navigate(['/login']);
      this.logOut();
      this.cognitoUtil.getCurrentUser().signOut();
    } else {
      this.isAuthenticated = this.authService.isLoggedIn();
      return this.isAuthenticated;
    }
  }


  logOut(): void {
    this.authService.logout();
    this.router.navigateByUrl("/login");
    // sessionStorage.removeItem('token');
    // sessionStorage.removeItem('firstname');
    // sessionStorage.removeItem('lastname');
    // sessionStorage.removeItem('groups');
    // sessionStorage.removeItem('currentUser');
    // sessionStorage.removeItem('username');
    localStorage.clear();
    this._compiler.clearCache();
    sessionStorage.clear();
    localStorage.clear();
    location.reload();
    // location.replace("/login");
    this.router.navigate(["/login"]);
    this.loggedoutIdle2();
  }
  consent;
  ngOnInit() {

    this._headerserviceService.getConsentSubject().subscribe((param: any) => {
      if (param !== undefined) {
        this.consent = param;
        // alert("trigered3: "+param)
      }
    });
    this.storageSwap();
  }

  storageSwap() {
    window.onbeforeunload = function () {
      var i = 0;
      var keyy = [];
      for (var keyx in localStorage) {
        keyy.push(keyx);
      }
      for (var len = localStorage.length; i < len; i++) {
        sessionStorage.setItem(
          keyy[i],
          localStorage.getItem(localStorage.key(i))
        );
      }
      localStorage.clear();
      // location.reload();
      if (sessionStorage.length != 0) {
        var j = 0;
        var keyyy = [];
        for (var keyxx in sessionStorage) {
          keyyy.push(keyxx);
        }
        for (var leng = sessionStorage.length; j < leng; j++) {
          localStorage.setItem(
            keyyy[j],
            sessionStorage.getItem(sessionStorage.key(j))
          );
        }
      }
    };
  }

  storageSwapwithoutrefresh() {
    var i = 0;
    var keyy = [];
    for (var keyx in localStorage) {
      keyy.push(keyx);
    }
    for (var len = localStorage.length; i < len; i++) {
      sessionStorage.setItem(
        keyy[i],
        localStorage.getItem(localStorage.key(i))
      );
    }
    localStorage.clear();
    // location.reload();
    if (sessionStorage.length != 0) {
      var j = 0;
      var keyyy = [];
      for (var keyxx in sessionStorage) {
        keyyy.push(keyxx);
      }
      for (var leng = sessionStorage.length; j < leng; j++) {
        localStorage.setItem(
          keyyy[j],
          sessionStorage.getItem(sessionStorage.key(j))
        );
      }
    }
  }

  refreshtoken() {
    var CognitoRefreshToken = require("amazon-cognito-identity-js")
      .CognitoRefreshToken;
    const userData = {
      Username: sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username"),
      Pool: this.auth.userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log("the error:" + err);
        return "invalidsession";
      } else {
        // return session.isValid()
        // const callback = session.isValid()
        const REFRESH_TOKEN = session.getRefreshToken().getToken();
        var token = new CognitoRefreshToken({ RefreshToken: REFRESH_TOKEN });
        // cognitoUser.refreshSession(token, callback);
        /* Session Refresh */
        cognitoUser.refreshSession(token, (err, session) => {
          if (err) {
            //throw err;
            console.log("In the err" + err);
          } else {
            var regsmar_apiKey = session.idToken.jwtToken; // will this provide new IdToken?
            localStorage.setItem("api_key", regsmar_apiKey);
          }
        });
      }
    });
    this.storageSwapwithoutrefresh();
  }

  // Session management

  setIdledata(): void {
    // this.timedOut = false;
    // location.reload();
    this.storageSwap();
    // this.refreshtoken();
    if (this.authService.token != null) {
      // alert(this.authService.token)
      this.setIdleevents();
      this.resetIdle();
    }
  }

  public setIdleevents(): void {
    // this.timedOut = false;

    this.idle.setIdle(this.auth.idleTime); // Sets an idle timeout of 5 seconds
    this.idle.setTimeout(this.auth.sessionTime); // After X seconds (+ 5 idle seconds) of inactivity, the user will be considered timed out
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // Sets the default interrupts, in this case, things like clicks, scrolls, touches to the document

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      // alert(this.authService.token)
      this.refreshtoken();
    });

    this.idle.onTimeout.subscribe(() => {
      this.loggedoutIdle();
      // this.idleState = 'Timed out!';
      // this.timedOut  = true;
      // this.resetIdle();
      //  this.logOut();
    });

    this.idle.onIdleStart.subscribe(
      () =>
        (this.dialogRef = this.dialog.open(ConfirmationTimeoutDialog, {
          disableClose: true,
        }))
    );
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.dialogRef.componentInstance.confirmMessage =
        "Your session will time out in " + countdown + " seconds!";
      this.dialogRef.componentInstance.showbutton = true;
      this.idle.clearInterrupts();
    });
    this.idle.onTimeout.subscribe(() => {
      this.dialogRef.componentInstance.confirmMessage =
        "Your session has timed out, please log in again!";
      this.dialogRef.componentInstance.showbutton = false;
    });

    this.idle.onIdleStart.subscribe(() =>
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.refreshtoken();
          console.log("it refreshed");
          this.idle.watch();
        } else {
          this.loggedoutIdle();
        }
        this.dialog.closeAll();
        //  this.dialogRef = null;
      })
    );

    // this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    // this.idle.onTimeoutWarning.subscribe((countdown) =>
    // {

    //   this.idleState = 'You will time out in ' + countdown + ' seconds!'

    // }
    // this.idleState = 'You will time out in ' + countdown + ' seconds!'

    // );

    this.keepalive.interval(60); // Sets the ping interval to 5 seconds

    this.keepalive.onPing.subscribe(() => {
      this.refreshtoken();
      this.lastPing = new Date();
    });
  }

  resetIdle(): void {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
    this.refreshtoken();
    this.lastPing = new Date();
  }
  resetIdle2(): void {
    // this.idle.watch();
    // this.idleState = 'Started.';
    // location.reload()
  }

  private loggedoutIdle(): void {
    this.logOut();
    this.idle.stop();
    this.idleState = "Timed out and Logged Out.";
    this.timedOut = true;
  }

  private loggedoutIdle2(): void {
    this.idle.stop();
    this.idleState = "Timed out and Logged Out.";
    this.timedOut = true;
  }

  public ngOnDestroy(): void { }

  onEversanaLogoClick() {
    window.open("https://www.eversana.com/");
  }
}
