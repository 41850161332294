import { Component, OnInit, OnDestroy } from "@angular/core";
import { ResetpasswordService } from "../auth-reset/resetpassword.service";
import { FilterPipe } from "./../shared/pipes";
import {
  CognitoCallback,
  CognitoUtil
} from "../auth-servicecognito/cognito.service";
import { IUserDatamodel } from "./resetmodel";
import { HttpParams, HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Subscription } from "rxjs";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-resend",
  templateUrl: "./reset.component.html",
  styleUrls: ["./reset.component.scss"]
})
export class ResetComponent implements OnInit, OnDestroy {
  tokenparam = "Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token")) ;
  iUserDatamodel: IUserDatamodel;
  pageTitle = "User Management";
  updatenote = " ";
  deletenote = " ";
  resetnote = " ";
  queryString: any;
  deleteverify: any = {};
  updateverify: any = {};
  resetverify: any = {};
  errorMessage: string;

  constructor(
    public cognitoUtil: CognitoUtil,
    private _resetpasswordService: ResetpasswordService,
    private http: HttpClient,
    private cookie: CookieService
  ) {
    this._resetpasswordService
      .getuserData()
      .subscribe(data => (this.userData = data));
  }
  public userData = [];

  ngOnInit() {
    this._resetpasswordService
      .getuserData()

      .subscribe(data => (this.userData = data));
    console.log(
      "In the userdata componentservice--->" + JSON.stringify(this.userData)
    );
  }

  updateUser(userDataItems: IUserDatamodel): void {
    let attributeName = "";
    let attributeCompanyName = "";
    let attributeLastname = "";
    let attributePhonenumber = "";

    userDataItems.attributes.forEach(function(value) {
      if (value.name == "name") {
        attributeName = value.value;
      } else if (value.name == "family_name") {
        attributeLastname = value.value;
      } else if (value.name == "custom:companyName") {
        attributeCompanyName = value.value;
      } else if (value.name == "phone_number") {
        attributePhonenumber = value.value;
      }
    });
    let params = new HttpParams()
      .append("JWT", this.tokenparam)
      .append("userId", userDataItems.username)
      .append("name", attributeName)
      .append("familyname", attributeLastname)
      .append("companyname", attributeCompanyName)
      .append("phone", attributePhonenumber); //Create new HttpParams
    this.http
      .get(this._resetpasswordService._baseUrl + "/updateuser?", { params })
      // .map((response:Response) => { response.json(); })
      .map((response: Response) => new IUserDatamodel())
      .subscribe();

    this.updatenote = "User has been updated";
  }

  deleteUser(userDataItems: IUserDatamodel): void {
    let username = {
      Name: "name",
      Value: userDataItems.username
    };

    if (
      confirm(
        "Are you sure you want to delete this user " + username.Value + "?"
      )
    ) {
      let params = new HttpParams()
        .append("JWT", this.tokenparam)
        .append("userId", username.Value); //Create new HttpParams
      this.http
        .get(this._resetpasswordService._baseUrl + "/deleteuser?", { params })
        .map((response: Response) => {
          console.log("response from deleteuser" + response.json());
        })
        .subscribe();

      this.deletenote = "User has been deleted";
    }
  }

  //  resendCode(username: string, callback: CognitoCallback): void {
  //   let userDataItems = {
  //       Username: username,
  //       Pool: this.cognitoUtil.getUserPool()
  //   };

  //   let cognitoUser = new CognitoUser(userDataItems);
  //   cognitoUser.resendConfirmationCode(function (err, result) {
  //       if (err) {
  //           callback.cognitoCallback(err.message, null);
  //       } else {
  //           callback.cognitoCallback(null, result);
  //       }
  //   });
  // }

  resetUser(userDataItems: IUserDatamodel): void {
    let username = {
      Name: "name",
      Value: userDataItems.username
    };

    let params = new HttpParams()
      .append("JWT", this.tokenparam)
      .append("userId", username.Value); //Create new HttpParams
    this.http
      .get(this._resetpasswordService._baseUrl + "/resetuserpassword?", {
        params
      })
      .map((response: Response) => {
        console.log("response from resetuser" + response.json());
      })
      .subscribe();

    this.resetnote = "User has been reset";
  }

  callbackname(userDataItems: IUserDatamodel) {
    if (userDataItems.username) {
      return true;
    } else {
      this.errorMessage;
    }
  }

  ngOnDestroy() {}
}
