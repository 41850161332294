import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirmation-dialog.html',
})
export class ConfirmationDialog3 {
  constructor(public dialogRef: MatDialogRef<ConfirmationDialog3>) {}

  public confirmMessage:string;
  public confirmMessage2:string;
  public confirmMessage3:string;
}