import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth-user/auth.guard";

/* Routes */
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NewsComponent } from "./otherMenuItems/news/news.component";
import { AboutComponent } from "./otherMenuItems/about/about.component";
import { ContactusComponent } from "./otherMenuItems/contactus/contactus.component";
import { DashsearchComponent } from "./dashsearch/dashsearch.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { HomeComponent } from "./home/home.component";
import { ResetComponent } from "./auth-reset/reset.component";
import { ForgotComponent } from "./auth-forgot/forgot.component";
import { Forgot2Component } from "./auth-forgot2/forgot2.component";
import { NewPasswordComponent } from "./auth-newpassword/newpassword.component";
import { RegisterComponent } from "./auth-register/registration.component";
import { RegistrationConfirmationComponent } from "./auth-confirm/confirmRegistration.component";
import { MyHsgServicesComponent } from "./otherMenuItems/myhsgservices/myhsgservices.component";
import { MyservicesComponent } from "./myservices/myservices.component";
import { ResendCodeComponent } from "./auth-resend/resendCode.component";
import { ContentpublisherComponent } from "./contentpublisher/contentpublisher.component";
import { DatapublisherComponent } from "./datapublisher/datapublisher.component";
import { MydashboardComponent } from "./mydashboard/mydashboard.component";
import { DataviewsComponent } from "./dataviews/dataviews.component";
import { DatapublishertableComponent } from "./datapublishertable/datapublishertable.component";
import { MyfavoritesComponent } from "./myfavorites/myfavorites.component";
import { FaqComponent } from "./faq/faq.component";
import { UserbatchComponent } from "./userbatch/userbatch.component";
import { LoginAdsComponent } from "./login-ads/login-ads.component";
import { OncologyComponent } from "./oncology/oncology.component";
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NewFeaturesComponent } from './new-features/new-features.component';
import { FileDeleteComponent } from './file-delete/file-delete.component';

const appRoutes: Routes = [
  {
    path: "search",
    component: DashsearchComponent,
    data: { breadcrumb: "Search" },
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "mydashboard",
    component: MydashboardComponent,
    data: { breadcrumb: "My Dashboard" },
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "dataviews",
    component: DataviewsComponent,
    data: { breadcrumb: "Data View" },
    canActivate: [AuthGuard],
  },
  {
    path: "datapublish",
    component: DatapublishertableComponent,
    data: { breadcrumb: "Data Publisher Table" },
    canActivate: [AuthGuard],
  },

  {
    path: "myfavorites",
    component: MyfavoritesComponent,
    data: { breadcrumb: "My Favorites" },
    canActivate: [AuthGuard],
  },
  {
    path: "products",
    component: MyHsgServicesComponent,
    data: { breadcrumb: "Products" },
    canActivate: [AuthGuard],
  },
  {
    path: "myProducts",
    component: MyservicesComponent,
    data: { breadcrumb: "My Products" },
    canActivate: [AuthGuard],
  },
  {
    path: "contactus",
    component: ContactusComponent,
    data: { breadcrumb: "Ask The Expert" },
    canActivate: [AuthGuard],
  },
  {
    path: "resetPassword",
    component: ResetComponent,
    data: { breadcrumb: "Reset password" },
    canActivate: [AuthGuard],
  },
  {
    path: "contentpublisher",
    component: ContentpublisherComponent,
    data: { breadcrumb: "Content Publisher" },
    canActivate: [AuthGuard],
  },
  {
    path: "fileDelete",
    component: FileDeleteComponent,
    data: { breadcrumb: "File Delete" },
    canActivate: [AuthGuard],
  },
  {
    path: "datapublisher",
    component: DatapublisherComponent,
    data: { breadcrumb: "Data Publisher" },
    canActivate: [AuthGuard],
  },
  {
    path: "forgotPassword",
    component: ForgotComponent,
    data: { breadcrumb: "Forgot Password" },
  },
  {
    path: "forgotPassword/:email",
    component: Forgot2Component,
    data: { breadcrumb: "Password Verification" },
  },
  {
    path: "confirmRegistration/:username",
    component: RegistrationConfirmationComponent,
    data: { breadcrumb: "Registration Confirmation" },
  },
  {
    path: "register",
    component: RegisterComponent,
    data: { breadcrumb: "Register" },
    canActivate: [AuthGuard],
  },
  {
    path: "resendCode",
    component: ResendCodeComponent,
    data: { breadcrumb: "Re-Send Code" },
  },
  {
    path: "newPassword",
    component: NewPasswordComponent,
    data: { breadcrumb: "New Password" },
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "faq",
    component: FaqComponent,
    data: { breadcrumb: "FAQ" },
    canActivate: [AuthGuard],
  },
  {
    path: "userBatch",
    component: UserbatchComponent,
    data: { breadcrumb: "UserBatch" },
    canActivate: [AuthGuard],
  },
  {
    path: "loginAds",
    component: LoginAdsComponent,
    data: { breadcrumb: "LoginAds" }
  },
  {
    path: "oncology",
    component: OncologyComponent,
    data: { breadcrumb: "oncology" }
  },

  {
    path: "justReleased",
    component: WhatsNewComponent,
    data: { breadcrumb: "justReleased" },
    canActivate: [AuthGuard],
  },

  {
    path: "comingSoon",
    component: ComingSoonComponent,
    data: { breadcrumb: "comingSoon" },
    canActivate: [AuthGuard],
  },
  {
    path: "menu",
    component: LandingPageComponent,
    data: { breadcrumb: "landingPage" },
    canActivate: [AuthGuard],
  },
  { path: "**", component: PageNotFoundComponent },
];
//ResendCodeComponent
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
