import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import { CognitoIdentityServiceProvider } from "aws-sdk";


@Injectable()
export class AllgroupsserviceService {
  

  public cognitoIdentityServiceProvider: CognitoIdentityServiceProvider;
  
  
  constructor() { }

  getGroups(){
  var params = {
    UserPoolId: environment.poolData.UserPoolId, /* required */
    Limit: 10,
    NextToken: ''
  };
  
  this.cognitoIdentityServiceProvider.listGroups(params, function(err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else     console.log(data);           // successful response
  });
  }
  
}
