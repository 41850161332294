declare var require: any;
import * as Highcharts from "highcharts";
import ExportingModule from "highcharts/modules/exporting";
import SunsetTheme from "highcharts/themes/sunset";
import * as HC_customEvents from "highcharts-custom-events";
HC_customEvents(Highcharts);
ExportingModule(Highcharts);
SunsetTheme(Highcharts);
import { DataSource } from "@angular/cdk/collections";
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  Input
} from "@angular/core";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  SortDirection,
  MAT_BUTTON_TOGGLE_GROUP_VALUE_ACCESSOR,
  MatSortable
} from "@angular/material";
import {
  MatDialogModule,
  MatDialog,
  MatDialogRef
} from "@angular/material/dialog";
import { Observable } from "rxjs";
import "rxjs/add/observable/of";
import { DataviewsService, Everything, Everything2 } from "./dataviews.service";
import { Element } from "./element.model";
import { environment } from "../../environments/environment";
// import { Angular5Csv } from "angular5-csv/Angular5-csv";
import { AngularCsv } from "angular7-csv/dist/Angular-csv";
import { SpinnerService } from "../spinner.service";
import { isNgTemplate } from "@angular/compiler";
import { headersToString } from "selenium-webdriver/http";
import { DEFAULT_RESIZE_TIME } from "@angular/cdk/scrolling";
import { stringify } from "@angular/core/src/render3/util";
import {
  CdkDropList,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from "@angular/cdk/drag-drop";
import { MatTable } from "@angular/material/table";
import { JsonPipe } from "@angular/common";
import { SavefilterDialog } from "../savefilter-dialog/savefilter-dialog";
import { SaveFiltersModel, filter } from "./savefiltersmodel";
import { LoadFiltersDialog } from "../loadfilters-dialog/loadfilters-dialog";
import { LimitModel } from "./limitmodel";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import "rxjs/add/operator/map";
import { exists } from "fs";
import { purecss } from "purecss-sass";
import { CookieService } from 'ngx-cookie-service';

export const CONDITIONS_LIST = [
  { value: "is-like", label: "Is like" },
  { value: "is-empty", label: "Is empty" },
  { value: "is-not-empty", label: "Is not empty" },
  { value: "is-equal", label: "Is equal" },
  { value: "is-not-equal", label: "Is not equal" }
];

@Component({
  selector: "app-dataviews",
  templateUrl: "./dataviews.component.html",
  styleUrls: ["./dataviews.component.scss"],
  providers: [DataviewsService]
})
export class DataviewsComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts; // Highcharts, it's Highcharts
  public visibility: boolean = false;
  color = "accent";
  mode = "determinate";
  value = 30;

  tokenparam = "JWT=Bearer " + (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem(("token"))) ;
  public baseUrl: string = environment.product_service_baseUrl;
  public downloadCSVURL: string =
    this.baseUrl + "/download/convertJson?" + this.tokenparam;
  public dataAsString: string;
  public filename: string;
  displayedColumns = ["title", "service", "year", "reportType"];
  listFilter;
  selectedValue: string;
  selectedValue4: string;
  smartViews = null;
  smartViews2 = null;
  dataTables2;
  dataTables3;
  dataTables4;
  dataSource = new MatTableDataSource<Element>(this.dataTables2);
  menu;
  smartViewHeaders = [];
  selectedRow: string;
  dataTables = new MatTableDataSource<[Everything]>(this.dataTables3);
  // datassource = new MatTableDataSource<[Everything2]>(this.datas);
  datas = [];
  targetdatas = [];
  datassourcer = new MatTableDataSource<[Everything2]>(this.datas);
  tablearray;
  tablearraystr;
  tablearraystr1st;
  dialogRef2: MatDialogRef<SavefilterDialog>;
  dialogRef3: MatDialogRef<LoadFiltersDialog>;
  filterName;
  filtertree;
  filter: [];

  headarray = [];

  sortValue;

  tablearray1st;

  headarray1st = [];

  //  headarray: String[];
  displayedColumns2;
  displayedColumns3;
  // displayedColumns = [];
  columnsToDisplay;

  updateDemo2: boolean = false;
  usedIndex: number = 0;
  chartTitle: string = "asdfasdfasd"; // for init - change through titleChange
  data1 = [11, 2, 3];
  data2 = [5, 6, 7];

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild("table") table: MatTable<Element>;
  @Input("matSortDirection") direction: SortDirection;
  @Input("Matsortables") sortables: Map<string, MatSortable>;

  public CONDITIONS_FUNCTIONS = {
    "is-like": function(value, filterdValue) {
      value = value.toLowerCase().trim();
      filterdValue = filterdValue.toLowerCase().trim();
      return typeof value == "string" && value.indexOf(filterdValue) > -1;
    },
    "is-empty": function(value, filterdValue) {
      return value === "";
    },
    "is-not-empty": function(value, filterdValue) {
      return value !== "";
    },
    "is-equal": function(value, filterdValue) {
      return value == filterdValue;
    },
    "is-not-equal": function(value, filterdValue) {
      return value != filterdValue;
    }
  };

  public conditionsList = CONDITIONS_LIST;
  public searchValue: any = {};
  public searchCondition: any = {};
  public _filterMethods = this.CONDITIONS_FUNCTIONS;

  constructor(
    public _dataviewsService: DataviewsService,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    private cookie: CookieService
  ) {
    this._dataviewsService.getSmartViews().subscribe(data => {
      this.smartViews = data;
      this.dataSource.data = this.smartViews;
      for (let datax of this.dataSource.data) {
        this.headarray1st = Object.keys(datax);
        this.tablearray1st = JSON.stringify(datax);
        this.tablearraystr1st = JSON.stringify(datax);
      }
    });
    this.dataTables2 = this._dataviewsService.getSmartViews2;
  }
  public baseUrl1: string = this.baseUrl + "/data";
  public tokenstring: string = this._dataviewsService.tokenparam;

  selected(row): void {
    this.selectedRow = row;
  }

  selectItem(smartObject) {
    sessionStorage.setItem("smartobject", smartObject);
    localStorage.setItem("smartobject", smartObject);
    this._dataviewsService.accessdatatable(smartObject).subscribe(data => {
      this.smartViews2 = data;
      this.dataTables.data = this.smartViews2;
      for (let datax of this.dataTables.data) {
        this.headarray1st = Object.keys(datax);
        this.tablearray1st = JSON.stringify(datax);
        this.tablearraystr1st = JSON.stringify(datax);
      }
    });

    this.displayedColumns3 = this.header(smartObject);

    this.datasf();
    this.dataAsString = JSON.stringify(this.datas);
    this.dataAsString = encodeURIComponent(
      this.dataAsString
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/\=+$/, "")
    );
    this.filename = this._dataviewsService.viewName;
  }

  getCol(matrix, col) {
    var column = [];
    for (var i = 0; i < matrix.length; i++) {
      column.push(matrix[i][col]);
    }
    return column;
  }

  header(smartObject) {
    this._dataviewsService
      .accessdatatable(smartObject)
      .map(res => res)
      .subscribe(data => {
        this.displayedColumns2 = data;
        for (let datax of this.displayedColumns2) {
          this.headarray = Object.keys(datax);
          this.tablearray = datax;

          for (let col of this.headarray) {
            let array = this.getCol(this.displayedColumns2, col);
          }

          for (let col of this.headarray) {
            sessionStorage.removeItem("sort-" + col);
            localStorage.removeItem("sort-" + col);
            sessionStorage.removeItem("currentfilter");
            localStorage.removeItem("currentfilter");
            this.clearColumn(col);
          }
          sessionStorage.removeItem("currentdisplayedColumns");
          localStorage.removeItem("currentdisplayedColumns");
          sessionStorage.removeItem("currentfilter");
          localStorage.removeItem("currentfilter");
          sessionStorage.removeItem("currenttopten");
          localStorage.removeItem("currenttopten");
          localStorage.removeItem("currentbottomten");
          sessionStorage.removeItem("currentbottomten");

          this.tablearraystr = JSON.stringify(datax);
        }
      });
  }

  removedcolumns = [];

  arrayRemove(fromarray, col) {
    return fromarray.filter(function(ele) {
      return ele.title != col;
    });
  }

  removeElement(fromarray, name: string) {
    return fromarray.filter(item => item != name);
  }

  removeEl(array, remIdx) {
    return array.map(function(arr) {
      return arr.filter(function(el, idx) {
        return idx !== remIdx;
      });
    });
  }

  removeColumn(col?) {
    if (this.headarray.length) {
      this.removedcolumns.push(col);
      sessionStorage.setItem(
        "removedcolumns",
        JSON.stringify(this.removedcolumns)
      );
      localStorage.setItem("removedcolumns",
      JSON.stringify(this.removedcolumns));
      let index = this.headarray.indexOf(col, 0);

      let a = this.headarray.slice(0, index);
      let b = this.headarray.slice(index + 1);
      this.headarray = a.concat(b);
      console.log("data[0]:" + JSON.stringify(this.datas));
      this.datas = this.arrayRemove(this.datas, col);
      console.log("data[1]:" + JSON.stringify(this.datas));

      this.dataAsString = JSON.stringify(this.datas);
      this.dataAsString = encodeURIComponent(
        this.dataAsString
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/\=+$/, "")
      );
      this.filename = this._dataviewsService.viewName;

      sessionStorage.removeItem("currentdisplayedColumns");
      localStorage.removeItem("currentdisplayedColumns");
      sessionStorage.removeItem("col");
      localStorage.removeItem("col");
      sessionStorage.setItem(
        "currentdisplayedColumns",
        JSON.stringify(this.datas)
      );
      localStorage.setItem("currentdisplayedColumns",
      JSON.stringify(this.datas));
      sessionStorage.setItem("col", col);
      localStorage.setItem("col", col);
      return this.displayedColumns2;
    }
  }

  getObjectKeyIndex(obj, keyToFind) {
    var i = 0,
      key;

    for (key in obj) {
      if (key == keyToFind) {
        return i;
      }

      i++;
    }

    return -1;
  }

  removeColumn2(col, dataTablesy) {
    if (dataTablesy.length) {
      //let index = dataTablesy[0].indexOf(col, 0);
      // let index = this.getObjectKeyIndex(dataTablesy[0], col);
      // let a = dataTablesy.slice(0, index);
      // let b = dataTablesy.slice(index + 1);
      // dataTablesy = a.concat(b)
      this.datas = dataTablesy;
      this.datas = this.arrayRemove(this.datas, col);
      this.dataAsString = JSON.stringify(this.datas);
      this.filename = this._dataviewsService.viewName;
      //this.datas = this.removeElement(this.datas, col);
      sessionStorage.removeItem("currentdisplayedColumns");
      sessionStorage.removeItem("col");
      sessionStorage.setItem(
        "currentdisplayedColumns",
        JSON.stringify(this.displayedColumns2)
      );
      localStorage.setItem("currentdisplayedColumns",
      JSON.stringify(this.displayedColumns2));
      sessionStorage.setItem("col", col);
      localStorage.setItem("col", col);
      return this.displayedColumns2;
    }
  }

  csvdownload(header, dataTablesx) {
    console.log("data[2]:" + JSON.stringify(this.datas));
    // let data = this.dataTables.data;
    // for (let col of this.removedcolumns) {
    //   this.removeColumn2(col, data);
    // }

    sessionStorage.removeItem("removedcolumns");

    // const headerarray = []
    // alert(header)
    function headerx(dataFromServer) {}
    var options = {
      showLabels: true,
      headers: header
    };

    this.dataAsString = JSON.stringify(this.datas);
    this.dataAsString = encodeURIComponent(
      this.dataAsString
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/\=+$/, "")
    );
    this.filename = this._dataviewsService.viewName;

    // let converteddataAsString = this._dataviewsService.getCSVFile(
    //   this._dataviewsService.viewName,
    //   dataAsString
    // );
    // console.log("converteddata:" + converteddataAsString);
    new AngularCsv(
      this._dataviewsService.getCSVFile(
        this._dataviewsService.viewName,
        this.dataAsString
      ),
      this._dataviewsService.viewName,
      options
    );
    //new AngularCsv(this.datas, this._dataviewsService.viewName, options);
  }

  titleFilter(filterValue: string) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.title.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  serviceFilter(filterValue: string) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.service.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  yearFilter(filterValue: string) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.year.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  reportFilter(filterValue) {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.reportType.toLowerCase().includes(filter);
    };
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  resetFilter(filterValue, keyType) {
    if (keyType == "title") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.title.toLowerCase().includes(filter);
      };
    } else if (keyType == "service") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.service.toLowerCase().includes(filter);
      };
    } else if (keyType == "year") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.year.toLowerCase().includes(filter);
      };
    } else if (keyType == "reportType") {
      this.dataSource.filterPredicate = function(
        data,
        filter: string
      ): boolean {
        return data.reportType.toLowerCase().includes(filter);
      };
    }
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort.toArray()[0];
    this.dataSource.paginator = this.paginator.toArray()[0];

    this.dataTables.sort = this.sort.toArray()[1];
    this.dataTables.paginator = this.paginator.toArray()[1];

    //   this.datas.sort = this.sort.toArray()[2];
    //  this.datas.paginator = this.paginator.toArray()[2];
  }

  ngOnInit() {
    this.dataTables.filterPredicate = (p: Everything, filtre: any) => {
      let result = true;
      let keys = Object.keys(p); // keys of the object data

      for (const key of keys) {
        let searchCondition = filtre.conditions[key]; // get search filter method
        if (searchCondition && searchCondition !== "none") {
          if (
            filtre.methods[searchCondition](p[key], filtre.values[key]) ===
            false
          ) {
            // invoke search filter
            result = false; // if one of the filters method not succeed the row will be remove from the filter result
            break;
          }
        }
      }

      return result;
    };

    this.spinnerService.visibility.subscribe(state => {
      this.visibility = state;
      // console.log('visibility', state);
    });

    this.spinnerService.notVisibility.subscribe(state => {
      this.visibility = state;
      // console.log('notVisibility', state);
    });
    // this.dataTables.sort = this.sort.toArray()[1];
  }
  asc;
  datatosort = [];
  savesort(col) {
    let elem = document.getElementById(col).getAttribute("aria-sort");
    const getCellValue = (tr, idx) =>
      tr.children[idx].innerText || tr.children[idx].textContent;
    const comparer = (idx, asc) => (a, b) =>
      ((v1, v2) =>
        v1 !== "" && v2 !== "" && !isNaN(v1) && !isNaN(v2)
          ? v1 - v2
          : v1.toString().localeCompare(v2))(
        getCellValue(asc ? a : b, idx),
        getCellValue(asc ? b : a, idx)
      );

    // grabs the original tbody
    document.querySelectorAll("th").forEach(th =>
      th.addEventListener("click", () => {
        const tbody = th.closest("tbody");
        Array.from(tbody.querySelectorAll("tr:nth-child(n+2)"))
          .sort(
            comparer(
              Array.from(th.parentNode.children).indexOf(th),
              (this.asc = !this.asc)
            )
          )
          .forEach(tr => tbody.appendChild(tr));
      })
    );
    sessionStorage.removeItem("sort-" + col);
    localStorage.removeItem("sort-" + col);
    if (elem == null) {
      sessionStorage.setItem("sort-" + col, "");
      localStorage.setItem("sort-" + col, "");
    } else {
      sessionStorage.setItem("sort-" + col, elem);
      localStorage.setItem("sort-" + col, elem);
    }
  }

  applyFilter(col?) {
    let searchFilter: any = {
      values: this.searchValue,
      conditions: this.searchCondition,
      methods: this._filterMethods
    };
    // console.log(this.datas.data)
    // console.log(this.datas.data[0])
    // console.log(this.datas.data[0][0]['data'])
    // console.log(this.dataSource)
    // console.log(this.dataTables)
    this.dataTables.filter = searchFilter;

    sessionStorage.removeItem("currentfilter");
    localStorage.removeItem("currentfilter");
    sessionStorage.setItem("currentfilter", JSON.stringify(searchFilter));
    localStorage.setItem("currentfilter", JSON.stringify(searchFilter));
  }

  loadapplyFilter(values, conditions) {
    let searchFilter: any = {
      values: values,
      conditions: conditions,
      methods: this._filterMethods
    };

    this.dataTables.filter = searchFilter;
    sessionStorage.removeItem("currentfilter");
    localStorage.removeItem("currentfilter");
    sessionStorage.setItem("currentfilter", JSON.stringify(searchFilter));
    localStorage.setItem("currentfilter", JSON.stringify(searchFilter));
  }

  clearColumn(columnKey: string): void {
    this.searchValue[columnKey] = null;
    this.searchCondition[columnKey] = "none";
    localStorage.removeItem("currentfilter");
    this.displayedColumns2;
    this.applyFilter();
  }

  clearAllColumns(): void {
    for (let col of this.headarray) {
      sessionStorage.removeItem("sort-" + col);
      localStorage.removeItem("sort-" + col);
      sessionStorage.removeItem("currentfilter");
      localStorage.removeItem("currentfilter");
      sessionStorage.removeItem("istopten");
      localStorage.removeItem("istopten");

      sessionStorage.removeItem("currentdisplayedColumns");
      localStorage.removeItem("currentdisplayedColumns");
      sessionStorage.removeItem("removedcolumns");
      localStorage.removeItem("removedcolumns");
      this.removedcolumns = [];
      this.searchValue[col] = null;
      this.searchCondition[col] = "none";
      sessionStorage.setItem("toptensent", "false");
      localStorage.setItem("toptensent", "false");
      this.toptenactive = sessionStorage.getItem("toptensent") ? sessionStorage.getItem("toptensent") : localStorage.getItem("toptensent");
      // localStorage.removeItem('currentfilter'+ JSON.stringify(this.searchValue))
      this.displayedColumns2;
      this.datasf();
      this.applyFilter();
      this.unsubscribeheader();
      this.unsubscribeheadernew();
      this.topten2();
      this.numbervalue = "";
      this.directionbut = "";
    }
  }

  selectnumberofrows() {
    this.dialogRef3 = this.dialog.open(LoadFiltersDialog, {
      disableClose: false,
      data: { topvalue: this.searchValue, selected: true }
    });
    this.dialogRef3.componentInstance.confirmMessage =
      "Please select number of rows:";
    this.dialogRef3.componentInstance.confirmMessage2 = this.id;

    this.dialogRef3.afterClosed().subscribe(result => {
      this.searchValue = result;
      if (result) {
        this._dataviewsService
          .savefilters({
            searchValue: result,
            filter: { currentfilter: this.currentfilter }
          })
          .subscribe(res => {}, err => {});
        if (this.filterName == "") {
        }
      }
      // this.loadSaveSearches()
      this.dialogRef3 = null;
    });
  }

  tasks = [{ toggle: false, status: "Top" }];
  enableDisableRule(button) {
    button.toggle = !button.toggle;
    button.status = button.toggle ? "Bottom" : "Top";
  }

  directionbut;
  numbervalue;
  toptenactive;
  datacolumn;
  dataobj = [];

  topten(numbervalue?, directionbut?) {
    // this.datas = []
    if (numbervalue == undefined) {
      return "";
    } else {
      let valuedata: any = {
        direction: directionbut,
        value: numbervalue,
        selected: true
      };
      let data = this.datas;
      console.log("data:" + JSON.stringify(data));
      if (valuedata.direction == "Top") {
        this.dataobj = [];
        for (let part of data) {
          this.datacolumn = part.data;
          this.datacolumn = this.datacolumn.slice(0, valuedata.value);
          this.datas = [];
          this.dataobj.push({ title: part.title, data: this.datacolumn });
          this.displayedColumns2 = this.displayedColumns2.slice(
            0,
            valuedata.value
          );
        }
        this.datas = this.dataobj;
        //this.datas.push(this.dataobj);
        console.log("dataobj:" + JSON.stringify(this.dataobj));
        console.log("datas dataobj:" + JSON.stringify(this.datas));
        sessionStorage.setItem("istopten", JSON.stringify(valuedata));
        localStorage.setItem("istopten", JSON.stringify(valuedata));
        sessionStorage.setItem("toptensent", "true");
        localStorage.setItem("toptensent", "true");
        this.toptenactive = sessionStorage.getItem("toptensent") ? sessionStorage.getItem("toptensent") : localStorage.getItem("toptensent");
        sessionStorage.removeItem("currenttopten");
        localStorage.removeItem("currenttopten");
        sessionStorage.setItem("currenttopten", JSON.stringify(this.datas));
        localStorage.setItem("currenttopten", JSON.stringify(this.datas));

        this.dataAsString = JSON.stringify(this.datas);
        this.dataAsString = encodeURIComponent(
          this.dataAsString
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/\=+$/, "")
        );
        this.filename = this._dataviewsService.viewName;
        // alert(JSON.stringify(this.datas))
        // return this.displayedColumns2;
      } else if (valuedata.direction == "Bottom") {
        this.dataobj = [];
        for (let part of data) {
          let datacolumnx = part.data;
          this.datacolumn = datacolumnx.slice(0, valuedata.value);

          // this.datas = []
          this.dataobj.push({ title: part.title, data: this.datacolumn });
          this.displayedColumns2 = this.displayedColumns2.slice(
            this.displayedColumns2.length - valuedata.value,
            this.displayedColumns2.length
          );
        }
        this.datas = this.dataobj;
        sessionStorage.setItem("istopten", JSON.stringify(valuedata));
        localStorage.setItem("istopten", JSON.stringify(valuedata));
        sessionStorage.setItem("toptensent", "true");
        localStorage.setItem("toptensent", "true");
        this.toptenactive = sessionStorage.getItem("toptensent") ? sessionStorage.getItem("toptensent") : localStorage.getItem("toptensent");
        localStorage.removeItem("currentbottomten");
        sessionStorage.removeItem("currentbottomten");
        sessionStorage.setItem("currentbottomten", JSON.stringify(this.datas));
        localStorage.setItem("currentbottomten", JSON.stringify(this.datas))
        // alert(JSON.stringify(this.datas))
        // return this.displayedColumns2;

        this.dataAsString = JSON.stringify(this.datas);
        this.dataAsString = encodeURIComponent(
          this.dataAsString
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/\=+$/, "")
        );
        this.filename = this._dataviewsService.viewName;
      }
    }
  }
  smartobject;
  unsubscribeheader() {
    this.smartobject = sessionStorage.getItem("smartobject") ? sessionStorage.getItem("smartobject") : localStorage.getItem("smartobject") ;
    //alert(this.smartobject);
    this._dataviewsService
      .accessdatatable(this.smartobject)
      .map(res => res)
      .subscribe(data => {
        this.displayedColumns2 = data;
        for (let datax of this.displayedColumns2) {
          this.headarray = Object.keys(datax);
        }

        for (let datax of this.displayedColumns2) {
          this.tablearray = datax;
          //  alert(JSON.stringify(datax))
        }
      });
  }

  unsubscribeheadernew() {
    this.datas = [];

    this.smartobject = sessionStorage.getItem("smartobject") ? sessionStorage.getItem("smartobject") : localStorage.getItem("smartobject") ;
    console.log("the object:" + this.smartobject);
    this._dataviewsService
      .accessdatatable(this.smartobject)
      .map(res => res)
      .subscribe(data => {
        this.displayedColumns2 = data;
        for (let datax of this.displayedColumns2) {
          this.headarray = Object.keys(datax);
        }

        for (let datax of this.displayedColumns2) {
          this.tablearray = datax;
          //  alert(JSON.stringify(datax))
        }

        for (let col of this.headarray) {
          let array = this.getCol(this.displayedColumns2, col);
          this.datas.push({ title: col, data: array });
        }
      });
  }

  filterpaintheader() {
    let smartobject = sessionStorage.getItem("smartobject") ? sessionStorage.getItem("smartobject") : localStorage.getItem("smartobject") ;
    this._dataviewsService
      .accessdatatable(smartobject)
      .map(res => res)
      .subscribe(data => {
        for (let columnsToDisplay of this.filtersloaded) {
          this.headarray = columnsToDisplay.columnList;
        }
        this.displayedColumns2 = data;
        for (let col of this.headarray) {
          for (let datax of this.displayedColumns2) {
            let a = datax.hasOwnProperty(col);
          }
        }
      });
  }

  topten2() {
    let smartobject = sessionStorage.getItem("smartobject") ? sessionStorage.getItem("smartobject") : localStorage.getItem("smartobject") ;;
    this._dataviewsService.accessdatatable(smartobject).subscribe(data => {
      this.smartViews2 = data;
      this.dataTables.data = this.smartViews2;
    });
    return this.dataTables;
  }

  public sorts = [];
  public filteredarra;
  public filteredarrax;
  public currentdisplayedColumns;

  savefilters() {
    this.currentdisplayedColumns = sessionStorage.getItem(
      "currentdisplayedColumns"
    ) ? sessionStorage.getItem("currentdisplayedColumns") : localStorage.getItem("currentdisplayedColumns");
    // let currentbottomten = sessionStorage.getItem('currentbottomten')
    // let currenttopten = sessionStorage.getItem('currenttopten')
    let istopten = sessionStorage.getItem("istopten") ? sessionStorage.getItem("istopten") : localStorage.getItem("istopten") ;
    let isbottomten = sessionStorage.getItem("isbottomten") ? sessionStorage.getItem("isbottomten") : localStorage.getItem("isbottomten");
    let currentfilter = sessionStorage.getItem("currentfilter") ? sessionStorage.getItem("currentfilter") : localStorage.getItem("currentfilter");
    let col = sessionStorage.getItem("col") ? sessionStorage.getItem("col") : localStorage.getItem("col");

    // console.log("this.displayedColumns2 stringify:"+JSON.stringify(this.displayedColumns2))

    if (istopten == "true") {
      if (this.headarray.length) {
        let data = this.dataTables.data;
        this.dataTables.data = data.slice(0, 5);

        for (var i = 0; i < this.dataTables.data.length; i++) {
          delete this.dataTables.data[i][col];
        }

        return this.dataTables.data;
      }
    }

    if (isbottomten == "true") {
    }

    if (sessionStorage.length != 0) {
      for (var keyxx in sessionStorage) {
        var j = 0;
        if (keyxx.includes("sort-")) {
          for (var keyxx in sessionStorage) {
            if (keyxx.includes("sort-")) {
              this.sorts.push(
                '"' + keyxx + '":"' + sessionStorage.getItem(keyxx) + '"'
              );
            }
          }
          break;
        }
      }
    }
  }

  conditionName;
  conditionValue;
  columnList;
  condition;
  currentfilter;
  id;
  istopten;
  isbottomten;

  saveFilterDialogOpen() {
    let removedcolumns1 = sessionStorage.getItem("removedcolumns") ? sessionStorage.getItem("removedcolumns") : localStorage.getItem("removedcolumns") ;
    this.removedcolumns = JSON.parse(removedcolumns1);
    let top = sessionStorage.getItem("istopten") ? sessionStorage.getItem("istopten") : localStorage.getItem("istopten") ;
    let istopten = JSON.parse(top);
    let a = sessionStorage.getItem("currentfilter") ? sessionStorage.getItem("currentfilter") : localStorage.getItem("currentfilter");
    this.currentfilter = JSON.parse(a);
    let col = sessionStorage.getItem("col") ? sessionStorage.getItem("col") : localStorage.getItem("col") ;
    this.id = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username");
    if (sessionStorage.length != 0) {
      for (var keyxx in sessionStorage) {
        var j = 0;
        if (keyxx.includes("sort-")) {
          for (var keyxx in sessionStorage) {
            if (keyxx.includes("sort-")) {
              this.sorts.push(keyxx + ":" + sessionStorage.getItem(keyxx));
            }
          }
          break;
          //  return this.sorts
        }
      }
    }

    this.dialogRef2 = this.dialog.open(SavefilterDialog, {
      disableClose: false,
      data: {
        userId: this.id,
        currentfilter: this.currentfilter,
        columnList: this.headarray,
        removedcolumns: this.removedcolumns,
        sorts: this.sorts,
        topten: istopten
      }
    });
    this.dialogRef2.componentInstance.confirmMessage =
      "Please name your filter settings:";
    this.dialogRef2.componentInstance.confirmMessage2 = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username");

    this.dialogRef2.afterClosed().subscribe(result => {
      this.filterName = result;
      if (result) {
        this._dataviewsService
          .savefilters({
            filterName: result,
            filter: {
              currentfilter: this.currentfilter,
              columnList: this.headarray,
              removedcolumns: this.removedcolumns,
              sorts: this.sorts,
              topvalues: istopten
            }
          })
          .subscribe(res => {}, err => {});
        if (this.filterName == "") {
        }
      }
      // this.loadSaveSearches()
      this.dialogRef2 = null;
    });
  }

  public loadFilter: Observable<SaveFiltersModel[]>;
  loadFilter2;

  filtersloaded: Array<filter> = [];
  theviewName;

  loadsettings(result) {
    this._dataviewsService.retrievefilters().subscribe(data => {
      this.loadFilter2 = data;
      for (let loaded of this.loadFilter2) {
        this.theviewName = sessionStorage.getItem("viewName");
        if (loaded.viewName == this.theviewName) {
          let a = loaded.filters.find(i => i.filterName === result).filter;
          this.filtersloaded.push(a);
          for (let key of this.filtersloaded) {
            let cfilter = key.currentfilter;
            this.loadapplyFilter(cfilter["values"], cfilter["conditions"]);

            this.removedcolumns = key.removedcolumns;
            sessionStorage.setItem(
              "removedcolumns",
              JSON.stringify(this.removedcolumns)
            );
            localStorage.setItem(  "removedcolumns",
            JSON.stringify(this.removedcolumns));
            let a = key.topvalues;
            if (a != undefined) {
              this.topten(a["value"], a["direction"]);
            }

            let b = key.sorts;
            for (let c of b) {
              let removesort = String(c).split("sort-")[1];
              let id = String(removesort).split(":")[0];
              let direction1 = String(removesort).split(":")[1];
              if (direction1 == "null") {
                let id = String(removesort).split(":")[0];
                let direction = "";
                this.dataTables.sort.sort(<MatSortable>{
                  id: id,
                  start: direction
                });
                this.dataTables.sort = this.sort.toArray()[1];
              } else if (direction1 != "null") {
                let id = String(removesort).split(":")[0];
                let direction = String(removesort).split(":")[1];
                if (direction == "ascending") {
                  direction = "asc";
                } else if (direction == "descending") {
                  direction = "desc";
                }
                this.dataTables.sort.sort(<MatSortable>{
                  id: id,
                  start: direction
                });
                this.dataTables.sort = this.sort.toArray()[1];
                // alert(id +":"+direction)
              }
            }
          }
        }
      }
    });
  }

  loadFilterDialogOpen() {
    this.id = sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username");
    this.dialogRef3 = this.dialog.open(LoadFiltersDialog, {
      disableClose: false,
      data: {
        userId: this.id,
        currentfilter: this.currentfilter,
        removedcolumns: this.removedcolumns,
        columnList: this.headarray,
        sorts: this.sorts
      }
    });
    this.dialogRef3.componentInstance.confirmMessage =
      "Please name your filter settings:";
    this.dialogRef3.componentInstance.confirmMessage2 = this.id;

    this.dialogRef3.afterClosed().subscribe(result => {
      this.filterName = result;
      if (result) {
        this.loadsettings(result);
        this.loadfilters();

        // for(let value of this.filtersloaded) {
        //   alert(value)
        // }

        // for (let loaded of this.loadFilter2) {
        //   alert(loaded[0])
        // }

        if (this.filterName == "") {
        }
      }
      // this.loadSaveSearches()
      this.dialogRef2 = null;
    });
  }

  loadfilters() {
    this.filterpaintheader();
  }

  previousIndex = 0;
  currentIndex = 0;

  drop(event: CdkDragDrop<string[]>) {
    //moveItemInArray(this.datas, event.previousIndex, event.currentIndex);
    transferArrayItem(
      this.datas,
      this.datas,
      event.previousIndex,
      event.currentIndex
    );
    console.log("In drop this.datas--->" + JSON.stringify(this.datas));
    this.previousIndex = event.previousIndex;
    this.currentIndex = event.currentIndex;
    this.dataAsString = JSON.stringify(this.datas);
    this.dataAsString = encodeURIComponent(
      this.dataAsString
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/\=+$/, "")
    );
  }

  datasx;
  datasf() {
    this.datas = [];
    for (let col of this.headarray) {
      let array = this.getCol(this.displayedColumns2, col);

      this.datas.push({ title: col, data: array });
    }
    var i = 0;
    for (let col of this.datas) {
      console.log(
        "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx "
      );

      console.log(
        "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx "
      );
    }
    // return this.datas
  }

  look(para) {
    //alert(para);
  }

  // dropTable(event: CdkDragDrop<Element[]>) {
  //   const prevIndex = this.headarray.findIndex((d) => d === event.item.data);
  //   moveItemInArray(this.headarray, prevIndex, event.currentIndex);
  //   this.table.renderRows();
  // }

  charts = [
    {
      hcOptions: {
        title: {
          text: "Solar Employment Growth by Sector, 2010-2016"
        },

        subtitle: {
          text: "Source: thesolarfoundation.com"
        },

        yAxis: {
          title: {
            text: "Number of Employees"
          }
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle"
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false
            },
            pointStart: 2010
          }
        },

        series: [
          {
            name: "AbbVie",
            data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
          },
          {
            name: "Boehringer Ingelheim",
            data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
          },
          {
            name: "GlaxoSmithKline",
            data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
          },
          {
            name: "Janssen (J&J HCS)",
            data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
          },
          {
            name: "Merck",
            data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
          },
          {
            name: "Novartis",
            data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
          },
          {
            name: "Industry average",
            data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
          }
        ],

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom"
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      } as Highcharts.Options
    }
  ];
}
